import { useState } from "react";
import { tickets_white_icon } from "../../assets/icons/icons";
import {
  Container,
  ThemeDropdown,
  ThemeTable,
} from "../../components/components";
import options from "../../lib/data/options.json";
import { useGetTicketsQuery } from "../../services/tickets";

const columns = [
  {
    title: "No",
    dataIndex: "no",
    key: "no",
    render: (item, record, index) => (
      <p className="text-center font-medium">{index + 1}</p>
    ),
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
    render: (text) => <p className="text-center font-medium">{text}</p>,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text) => <p className="text-center font-medium">{text}</p>,
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
    render: (text) => <p className="text-center font-medium">{text}</p>,
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   key: "status",
  //   width: "10%",
  //   render: (text) => <ThemeDropdown text={text} items={options.status} />,
  // },
];

const Tickets = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const { data, error, isLoading, refetch } = useGetTicketsQuery({
    currentPage,
    resultPerPage,
    status: "opened",
  });
  return (
    <Container
      selected={"6"}
      header={
        <div className="flex items-center">
          <div className="bg-primary h-9 w-9 rounded-full flex justify-center items-center">
            <img src={tickets_white_icon} width={17} height={17} alt="" />
          </div>
          <p className="ml-2 font-semibold text-2xl">Tickets</p>
        </div>
      }
    >
      <ThemeTable
        pagination={{ total, setCurrentPage, setResultPerPage }}
        columns={columns}
        data={data?.tickets?.data || []}
      />
    </Container>
  );
};

export default Tickets;
