import { useEffect, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { EventFeedbackModal, ThemeTable } from "../../../components/components";
import {
  useLazyGetEmptySlotsQuery,
  useLazyGetInvitationQuery,
  usePutInvitationMutation,
} from "../../../services/invitation";
import { Collapse } from "antd";
import AddSlotModal from "../../../components/addSlotModal";
import { useGetFeedbackQuery } from "../../../services/feedback";
import { user, userFemale } from "../../../assets/images/images";
import { EyeOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const EventFeedback = ({ eventID }) => {
  const [sponsors, setSponsors] = useState({});
  const [showAddSlotModal, setShowAddSlotModal] = useState(false);
  const [inviteId, setInviteId] = useState(null);
  const [isLoadingNeed, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewData, setPreviewData] = useState();

  const { data: eventFeedback, isLoading } = useGetFeedbackQuery({
    eventId: eventID,
  });

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "ID",
      dataIndex: "user.picture",
      key: "picture",
      render: (_, data) => (
        <div className="flex justify-center">
          <img
            src={
              data?.user?.picture ||
              (data?.user?.gender === "male" ? user : userFemale)
            }
            alt=""
            width={45}
            height={45}
            className="w-[45px] h-[45px] rounded-full object-cover"
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "name",
      render: (_, data) => (
        <p className="text-center font-medium">{`${data?.user?.first_name} ${data?.user?.last_name}`}</p>
      ),
    },
    {
      title: "Intrested In",
      dataIndex: "instrestedIn",
      key: "instrestedIn",
      render: (text) => (
        <p className="text-center font-medium">
          {text?.length &&
            text?.map((intrested, index) => {
              return (
                <span key={index}>
                  {intrested}
                  {text.length - 1 !== index && ", "}
                </span>
              );
            })}
        </p>
      ),
    },
    {
      title: "Additional Comments",
      dataIndex: "additionalComments",
      key: "additionalComments",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Ratings",
      dataIndex: "ratings",
      key: "ratings",
      render: (text) => {
        const totalRating = Object.values(text).map((val) => val);
        let rating = 0;
        totalRating?.map((e) => (rating += e));
        return (
          <p className="text-center font-medium">{(rating / 9).toFixed(1)}</p>
        );
      },
    },
    {
      title: "",
      dataIndex: "inaction",
      key: "inaction",
      width: "8%",
      render: (_, data) => (
        <span className="flex justify-center">
          <EyeOutlined
            onClick={() => {
              setIsModalOpen(true);
              setPreviewData(data);
            }}
          />
        </span>
      ),
    },
  ];

  return (
    <div>
      <EventFeedbackModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={previewData}
      />
      <ThemeTable
        columns={columns}
        data={eventFeedback?.feedbacks?.data || []}
        pagination={{ total, setCurrentPage, setResultPerPage }}
        loader={isLoading}
      />
    </div>
  );
};

export default EventFeedback;
