import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import {
  AuthApi,
  EventsApi,
  AttendeesApi,
  SchedulesApi,
  InvitationApi,
  MeetingsApi,
  ChatApi,
  MessageApi,
  AgendaApi,
  LogApi,
  BookApi,
  TicketsApi,
  AnnouncementsApi,
  WishlistApi,
  FeedbackApi,
} from "../services/services";

export const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [EventsApi.reducerPath]: EventsApi.reducer,
    [AttendeesApi.reducerPath]: AttendeesApi.reducer,
    [InvitationApi.reducerPath]: InvitationApi.reducer,
    [SchedulesApi.reducerPath]: SchedulesApi.reducer,
    [MeetingsApi.reducerPath]: MeetingsApi.reducer,
    [ChatApi.reducerPath]: ChatApi.reducer,
    [MessageApi.reducerPath]: MessageApi.reducer,
    [AgendaApi.reducerPath]: AgendaApi.reducer,
    [LogApi.reducerPath]: LogApi.reducer,
    [TicketsApi.reducerPath]: TicketsApi.reducer,
    [BookApi.reducerPath]: BookApi.reducer,
    [AnnouncementsApi.reducerPath]: AnnouncementsApi.reducer,
    [WishlistApi.reducerPath]: WishlistApi.reducer,
    [FeedbackApi.reducerPath]: FeedbackApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      AuthApi.middleware,
      EventsApi.middleware,
      AttendeesApi.middleware,
      SchedulesApi.middleware,
      InvitationApi.middleware,
      MeetingsApi.middleware,
      ChatApi.middleware,
      MessageApi.middleware,
      AgendaApi.middleware,
      LogApi.middleware,
      BookApi.middleware,
      TicketsApi.middleware,
      AnnouncementsApi.middleware,
      WishlistApi.middleware,
      FeedbackApi.middleware
    ),
});

setupListeners(store.dispatch);
