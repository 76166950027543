import {
  delegates_icon,
  events_icon,
  hall_icon,
  sponsors_icon,
} from "../../assets/icons/icons";

const Stats = [
  {
    icon: events_icon,
    title: "Ongoing Events",
    count: "10",
  },
  {
    icon: events_icon,
    title: "Upcoming Events",
    count: "15",
  },
  {
    icon: events_icon,
    title: "Past Events",
    count: "17",
  },
  {
    icon: sponsors_icon,
    title: "Sponsors",
    count: "100",
  },
  {
    icon: delegates_icon,
    title: "Delegates",
    count: "18",
  },
  {
    icon: hall_icon,
    title: "Staff",
    count: "17",
  },
];

export default Stats;
