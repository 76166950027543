import React, { useState, useEffect } from "react";
import {
  Container,
  ThemeButton,
  ThemeTable,
  SupportStaffDrawer,
} from "../../components/components";
import { message, Spin } from "antd";
import {
  add_icon,
  edit_icon,
  support_white_icon,
  delete_icon,
} from "../../assets/icons/icons";
import { user, userFemale } from "../../assets/images/images";
import {
  useGetUsersQuery,
  useRegisterUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from "../../services/auth";

const SupportStaff = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [mode, setMode] = useState("create");
  const [editID, setEditID] = useState("");
  const [open, setOpen] = useState(false);
  const [registerUser, { isLoading: regestiring }] = useRegisterUserMutation();
  const [updateUser, { isLoading: updating }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: deleting }] = useDeleteUserMutation();
  const { data, error, isLoading, refetch } = useGetUsersQuery({
    currentPage,
    resultPerPage,
    userType: "staff",
  });
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    picture: "",
    user_type: "staff",
    metadata: {},
  });
  const [image, setImage] = useState("");

  useEffect(() => {
    setTotal(data?.users?.total || 0);
  }, [isLoading]);

  const onClose = () => {
    setOpen(false);
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      picture: "",
      user_type: "staff",
      metadata: {},
    });
    setImage("");
    setMode("create");
  };
  const onSubmit = async () => {
    let response;
    if (mode === "create") {
      response = await registerUser(formData);
    } else {
      response = await updateUser({ id: editID, body: formData });
    }
    if (response?.data?.status == 200) {
      message.success(
        `Support Staff ${
          mode === "create" ? "registered" : "updated"
        } successfully.`
      );
      onClose();
      refetch();
    } else {
      message.error("Please fill form correctly.");
    }
  };
  const onEdit = (data) => {
    setEditID(data?._id);
    setFormData(data);
    setImage(data?.picture);
    setMode("edit");
    setOpen(true);
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteUser({ id: data._id });
      if (res.data.status === 200) {
        message.success(res.data.message);
        refetch();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Picture",
      dataIndex: "picture",
      key: "picture",
      render: (picture, data) => (
        <div className="flex justify-center">
          <img
            className="rounded-full object-cover"
            src={picture || (data.gender === "male" ? user : userFemale)}
            alt=""
            style={{ height: "45px", width: "45px" }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, data) => (
        <p className="text-center font-medium">{`${data.first_name} ${data.last_name}`}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => <p className="text-center font-medium">{email}</p>,
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (password) => (
        <p className="text-center font-medium">{password}</p>
      ),
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center">
          <span className="mx-2 cursor-pointer" onClick={() => onEdit(data)}>
            <img src={edit_icon} alt="" width={15} />
          </span>
          <span className="mx-2 cursor-pointer" onClick={() => onDelete(data)}>
            <img src={delete_icon} alt="" width={15} />
          </span>
        </span>
      ),
    },
  ];

  return (
    <Container
      selected={"5"}
      header={
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-primary h-9 w-9 rounded-full flex justify-center items-center">
              <img src={support_white_icon} width={17} height={17} alt="" />
            </div>
            <p className="ml-2 font-semibold text-2xl">Support Staff</p>
          </div>
          <div className="flex">
            <ThemeButton
              onClick={() => setOpen(true)}
              className={"ml-3"}
              content={
                <div className="flex items-center">
                  <img src={add_icon} alt="" width={18} height={18} />
                  <p className="ml-2">Add Supoort Staff</p>
                </div>
              }
            />
          </div>
        </div>
      }
    >
      <SupportStaffDrawer
        formData={formData}
        image={image}
        setImage={(e) => setImage(e)}
        setFormData={(e) => setFormData(e)}
        isLoading={mode === "create" ? regestiring : updating}
        onSubmit={onSubmit}
        open={open}
        onClose={onClose}
        btnTxt={
          mode === "create" ? "Add Support Staff" : "Update Support Staff"
        }
        addIcon={mode === "create"}
        mode={mode}
      />
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "75vh" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <ThemeTable
          pagination={{ total, setCurrentPage, setResultPerPage }}
          columns={columns}
          data={data?.users?.data || []}
        />
      )}
    </Container>
  );
};

export default SupportStaff;
