import {
  break_icon,
  delete_white_icon,
  edit_white_icon,
} from "../../assets/icons/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { FileDoneOutlined } from "@ant-design/icons";

const BreakCard = ({ breakDetail, onDelete, onEdit, isAdmin, isUser }) => {
  return (
    <div className="relative bg-white">
      <div className="bg-primary flex items-center justify-center py-2 static">
        <div className="flex text-white flex-col items-center">
          <p className="text-xl md:text-3xl font-bold text-white text-center">
            {breakDetail?.title}{" "}
          </p>
          <p className="text-white text-center md:text-base font-semibold mt-1">
            {breakDetail?.start_time} - {breakDetail?.end_time}
          </p>
        </div>
        {isAdmin && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer w-8"
              onClick={() => onEdit(breakDetail)}
            >
              <img src={edit_white_icon} alt="" width={18} height={18} />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => showDeletePopup(() => onDelete(breakDetail))}
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
        {/* {isUser && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer"
              onClick={() =>
                showDeletePopup(() => onDelete(breakDetail.agenda_id))
              }
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )} */}
      </div>
      <div
        style={{ height: 218 }}
        className="bg-white p-4 shadow-md shadow-gray-300 flex items-center justify-center"
      >
        {breakDetail.type == "registration" ? (
          <FileDoneOutlined style={{ fontSize: 180, color: "#e2b025" }} />
        ) : (
          <img src={break_icon} alt="" width={190} height={190} />
        )}
      </div>
    </div>
  );
};

export default BreakCard;
