import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { CLOUDINARY_URL } from "../constants/constants";

const uploadImage = async (image, fileName) => {
  let url;
  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", fileName ? fileName : "ztqqlkiz");
  data.append("cloud_name", "dxsowbvlv");
  await fetch(CLOUDINARY_URL, {
    method: "POST",
    body: data,
  })
    .then((res) => res.json())
    .then((data) => {
      url = data.secure_url;
    })
    .catch((err) => {
      err = err;
    });
  return url;
};

const addKeyToStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const deleteKeyFromStorage = (key) => {
  localStorage.removeItem(key);
};

const updateKey = (key, value) => {
  localStorage.setItem(key, value);
};

const getValueFromStorage = (key) => {
  const value = localStorage.getItem(key);
  return value;
};

const getUserInfo = () => {
  try {
    let user = jwt_decode(getValueFromStorage("token"));
    return user;
  } catch (err) {
    return err;
  }
};

const showDeletePopup = (onConfirmed) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't to delete it!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#FF0000",
    cancelButtonColor: "#DBDADA",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirmed();
    }
  });
};

export {
  addKeyToStorage,
  deleteKeyFromStorage,
  updateKey,
  getValueFromStorage,
  getUserInfo,
  uploadImage,
  showDeletePopup,
};
