import { useEffect, useState } from 'react'
import { Modal, Input, Row, Col, Spin, message, Select, Empty } from 'antd'
import { ThemeButton, ThemeInput, ThemeSelect } from '../components'
import { check_icon } from '../../assets/icons/icons'
import { CheckOutlined } from '@ant-design/icons'

const AddAttendeeModal = ({
  isModalOpen,
  onClose,
  extraMeetings,
  onClickSlot
}) => {
  return (
    <Modal
      title='Add Extra Meetinngs Attendees to these slots.'
      footer={false}
      open={isModalOpen}
      onCancel={onClose}
    >
      <div
        className='bg-themeGray-light p-3 rounded-lg overflow-auto'
        style={{ height: 400 }}
      >
        {extraMeetings?.map(data => {
          return (
            <div
              onClick={() => onClickSlot(data)}
              className='p-4 bg-white flex rounded-sm my-2 justify-between  hover:bg-primary cursor-pointer'
            >
              <span className='font-medium'>
                {data?.receiver?.first_name + ' ' + data?.receiver?.last_name}
              </span>

              <CheckOutlined />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default AddAttendeeModal
