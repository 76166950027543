import { useEffect, useState } from 'react'
import { Card } from 'antd'

import {
  useDeleteInvitationMutation,
  useGetInvitationQuery,
  useLazyGetEmptySlotsQuery,
  usePutInvitationMutation
} from '../../../services/invitation'
import { Collapse } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import AddSlotModal from '../../../components/addSlotModal'
import { showDeletePopup } from '../../../helpers/helpers'
import dayjs from 'dayjs'

const EventMeetings = ({ eventID }) => {
  const [meetingInvitation, setMeetingInvitation] = useState([])
  const [meetingWithSlots, setMeetingWithSlots] = useState({})
  const [showAddSlotModal, setShowAddSlotModal] = useState(false)
  const [inviteId, setInviteId] = useState(null)

  const [
    getSlots,
    { data, isLoading: slotsLoading }
  ] = useLazyGetEmptySlotsQuery()

  const [
    putInvitation,
    { isLoading: updatingInvitation, error: updateInvitationError }
  ] = usePutInvitationMutation()

  const [
    deletInvitation,
    { isLoading: deleteInvitationLoading, error: deleteInvitationLoadingError }
  ] = useDeleteInvitationMutation()

  const { data: allInvitations, isLoading } = useGetInvitationQuery({
    eventId: eventID,
    status: 'accepted'
  })

  useEffect(() => {
    getMeetingSlots()
  }, [isLoading, updatingInvitation])

  function sortStringsByTimeAndDay (strings) {
    // Define a custom sorting function
    function compareDayAndTime (a, b) {
      const dayA = a.includes('Day 1') ? 'Day 1' : 'Day 2'
      const dayB = b.includes('Day 1') ? 'Day 1' : 'Day 2'

      if (dayA === dayB) {
        const timeFormat = 'hh:mm A' // Specify the time format
        const timeA = a.match(/\d+:\d+ [APM]+/)
        const timeB = b.match(/\d+:\d+ [APM]+/)

        if (timeA && timeB) {
          const timeObjA = dayjs(timeA[0], timeFormat)
          const timeObjB = dayjs(timeB[0], timeFormat)

          return timeObjA - timeObjB
        }
      }

      return dayA.localeCompare(dayB)
    }

    // Sort the strings using the custom sorting function
    const sortedStrings = strings.slice().sort(compareDayAndTime)

    return sortedStrings
  }

  const getMeetingSlots = () => {
    const acceptedInvitations = allInvitations?.invitations?.data.filter(
      data => data.status === 'accepted'
    )

    const meetingInvitation = allInvitations?.invitations?.data.filter(
      data => data.meeting
    )

    const meetingWithSlots = {}

    if (meetingInvitation) {
      meetingInvitation.map(data => {
        const meetingTime = data.meeting.start_time + data.meeting.day
        if (meetingWithSlots[meetingTime]) {
          let isDuplicate = checkIfAnyMeetingsDuplicate(
            meetingWithSlots[meetingTime],
            data
          )

          meetingWithSlots[meetingTime].push({ ...data, isDuplicate })
        } else {
          meetingWithSlots[meetingTime] = [data]
        }
      })
    }
    if (acceptedInvitations) {
      setMeetingInvitation([...meetingInvitation])
      setMeetingWithSlots({ ...meetingWithSlots })
    }
  }

  const getMeetingObject = id => {
    const meeting = meetingInvitation.find(
      data => data?.meeting?.start_time + data?.meeting?.day === id
    ).meeting
    return `${meeting.start_time} - ${meeting.end_time} (${meeting.day})`
  }

  const handleOnClickEdit = async (sender, receiver, inviteId) => {
    const slots = await getSlots({
      eventId: eventID,
      sender: sender,
      receiver: receiver
    })
    setInviteId(inviteId)
    if (!slots.isError) {
      setShowAddSlotModal(true)
    }
  }

  const checkIfAnyMeetingsDuplicate = (meetings, newMeeting) => {
    let isDuplicate = false
    for (var i = 0; i < meetings.length; i++) {
      const receiver = meetings[i].receiver?._id
      const sender = meetings[i].sender?._id
      const newSender = newMeeting?.sender?._id
      const newReceiver = newMeeting?.receiver?._id

      if (
        receiver === newSender ||
        receiver === newReceiver ||
        sender === newSender ||
        sender === newReceiver
      ) {
        isDuplicate = true
        break
      }
    }
    return isDuplicate
  }

  const handleOnClickSlot = async slot => {
    const inviteData = {
      status: 'accepted',
      meetingId: slot._id
    }
    const invite = await putInvitation({ id: inviteId, body: inviteData })
    if (!invite.error) {
      setShowAddSlotModal(false)
      setInviteId(null)
      getMeetingSlots()
      window.location.reload(false)
    }
  }

  const handleOnClickDelete = async inviteId => {
    const deleted = await deletInvitation({ id: inviteId })
    if (!deleted.isError) {
      window.location.reload(false)
    }
  }

  console.log(Object.keys(meetingWithSlots))

  return (
    <div>
      <AddSlotModal
        slots={data?.emptySlots}
        isModalOpen={showAddSlotModal}
        onClickSlot={slot => handleOnClickSlot(slot)}
        onClose={() => setShowAddSlotModal(false)}
      />
      <div className='flex flex-wrap'>
        {Object.keys(meetingWithSlots)?.length
          ? sortStringsByTimeAndDay(Object.keys(meetingWithSlots)).map(key => {
              return (
                <Card
                  className='m-4'
                  title={getMeetingObject(key)}
                  bordered={false}
                  style={{
                    width: 520
                  }}
                >
                  <div className='flex justify-between mb-4'>
                    <span className='w-1/12 font-normal text-left'></span>
                    <span className='w-4/12 font-semibold text-left'>
                      Sender
                    </span>

                    <span className='w-4/12 font-semibold text-center'>
                      Receiver
                    </span>

                    {/* <span className='font-semibold text-right w-2/12'>
                      Priority
                    </span> */}
                    <span className='font-semibold text-right w-3/12'>
                      T.Number
                    </span>
                    <span className='w-1/12 font-normal text-center'></span>
                    <span className='w-1/12 font-normal text-center'></span>
                  </div>
                  {meetingWithSlots[key].map((slot, index) => {
                    const receiver = slot.receiver
                    const sender = slot.sender
                    const isDup = slot.isDuplicate
                    return (
                      <div
                        className={` ${
                          isDup ? 'bg-red-400' : 'bg-white'
                        } flex border-b min-h-9 justify-between my-1 py-1 px-1`}
                      >
                        <span className='w-1/12 font-normal text-left'>
                          {index + 1}
                        </span>
                        <span className='w-4/12 font-normal text-left'>
                          {sender.first_name + ' ' + sender.last_name}
                        </span>
                        <span className='w-4/12 font-normal text-center'>
                          {receiver.first_name + ' ' + receiver.last_name}
                        </span>
                        <span className='font-normal text-center  w-2/12'>
                          {receiver?.metadata?.atendee_details?.table_number ||
                            sender?.metadata?.atendee_details?.table_number}
                        </span>

                        <span className='w-1/12 font-normal text-center'>
                          <EditOutlined
                            onClick={() =>
                              handleOnClickEdit(
                                receiver?._id,
                                sender?._id,
                                slot?._id
                              )
                            }
                          />
                        </span>
                        <span className='w-1/12 font-normal text-center'>
                          <DeleteOutlined
                            onClick={() =>
                              showDeletePopup(() =>
                                handleOnClickDelete(slot?._id)
                              )
                            }
                          />
                        </span>
                      </div>
                    )
                  })}
                </Card>
              )
            })
          : null}
      </div>
    </div>
  )
}

export default EventMeetings
