import React from "react";
import { Modal, Row, Col, Spin } from "antd";
import {
  KeyNoteCard,
  BreakCard,
  NetworkingSessionCard,
  SponsorslotCard,
  SponsorsPresentationCard,
  PanelDiscutionCard,
  ThemeButton,
} from "../../components/components";
import { PlusCircleOutlined } from "@ant-design/icons";

const AddAgendaModal = ({
  isModalOpen,
  onClose,
  day,
  agendas,
  addAgenda,
  isLoading,
}) => {
  return (
    <Modal
      className="-mt-14"
      width={1200}
      footer={false}
      open={isModalOpen}
      onCancel={() => onClose()}
    >
      <div className="flex justify-between bg-primary py-4 px-10 mt-4">
        <p className="text-white font-extrabold text-xl md:text-5xl text-center">
          HALL A
        </p>
        <p className="text-white font-extrabold text-xl md:text-5xl text-center">
          {day}
        </p>
        <p className="text-white font-extrabold text-xl md:text-5xl text-center">
          HALL B
        </p>
      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }} className="mt-6">
        {agendas?.map((agenda, i) => {
          if (agenda?.day === day || day === "All") {
            if (agenda.type == "keynote") {
              return (
                <Col span={12} key={i} className="mt-7 lg:mt-0 mb-5">
                  <KeyNoteCard isUser={true} keynote={agenda} />
                  <ThemeButton
                    onClick={() => addAgenda(agenda._id)}
                    content={
                      <>
                        {isLoading ? (
                          <Spin className="mt-1" />
                        ) : (
                          <div>
                            <PlusCircleOutlined className="mr-4" />
                            <span>Add To Agenda</span>
                          </div>
                        )}
                      </>
                    }
                    className="w-full text-lg !h-12 mt-4"
                  />
                </Col>
              );
            } else if (agenda.type == "panel-discussion") {
              return (
                <Col span={12} key={i} className="mt-7 lg:mt-0 mb-5">
                  <PanelDiscutionCard isUser={true} panelDetails={agenda} />
                  <ThemeButton
                    onClick={() => addAgenda(agenda._id)}
                    content={
                      <>
                        {isLoading ? (
                          <Spin className="mt-1" />
                        ) : (
                          <div>
                            <PlusCircleOutlined className="mr-4" />
                            <span>Add To Agenda</span>
                          </div>
                        )}
                      </>
                    }
                    className="w-full text-lg !h-12 mt-4"
                  />
                </Col>
              );
            } else if (agenda.type == "sponsor-slot") {
              return (
                <Col span={12} key={i} className="mt-7 lg:mt-0 mb-5">
                  <SponsorslotCard isUser={true} sponsorDetail={agenda} />
                  <ThemeButton
                    onClick={() => addAgenda(agenda._id)}
                    content={
                      <>
                        {isLoading ? (
                          <Spin className="mt-1" />
                        ) : (
                          <div>
                            <PlusCircleOutlined className="mr-4" />
                            <span>Add To Agenda</span>
                          </div>
                        )}
                      </>
                    }
                    className="w-full text-lg !h-12 mt-4"
                  />
                </Col>
              );
            } else if (agenda.type == "sponsor-presentation") {
              return (
                <Col span={12} key={i} className="mt-7 lg:mt-0 mb-5">
                  <SponsorsPresentationCard
                    hall={agenda.hall}
                    isUser={true}
                    sponsorDetail={agenda}
                  />
                  <ThemeButton
                    onClick={() => addAgenda(agenda._id)}
                    content={
                      <>
                        {isLoading ? (
                          <Spin className="mt-1" />
                        ) : (
                          <div>
                            <PlusCircleOutlined className="mr-4" />
                            <span>Add To Agenda</span>
                          </div>
                        )}
                      </>
                    }
                    className="w-full text-lg !h-12 mt-4"
                  />
                </Col>
              );
            } else if (
              agenda.type == "coffe-break" ||
              agenda.type == "lunch-break" ||
              agenda.type == "cocktail-break" ||
              agenda.type == "breakfast" ||
              agenda.type == "registration"
            ) {
              return (
                <Col span={12} key={i} className="mt-7 lg:mt-0 mb-5">
                  <BreakCard isUser={true} breakDetail={agenda} />
                </Col>
              );
            } else if (agenda.type == "networking") {
              return (
                <Col span={12} key={i} className="mt-7 lg:mt-0 mb-5">
                  <NetworkingSessionCard
                    isUser={true}
                    networkingDetail={agenda}
                  />
                </Col>
              );
            }
          }
        })}
      </Row>
    </Modal>
  );
};

export default AddAgendaModal;
