import React from 'react'
import { user } from '../../assets/images/images'
import QRCode from 'qrcode.react'

const AttendeeCard = React.forwardRef(({ cards, eventData }, ref) => (
  <div ref={ref}>
    <div>
      {cards.map((data, index) => {
        return (
          <div className='flex relative justify-center' key={index}>
            <div
              ref={ref}
              className='my-2 w-96 relative  border border-themeGray-light'
            >
              <img
                className=' w-96 '
                src={require('../../assets/images/card-bg.jpg')}
              />

              {/* <div
                className='flex flex-col justify-center items-center  h-60 p-6 cardtop-container'
                style={{ position: 'absolute' , top : -46 }}
              >
                <p className='text-lg text-white font-bold text-center w-60'>
                  {eventData?.name}
                </p>
                <div className='flex justify-around text-sm text-white mt-2 w-full px-2 text-center'>
                  <p className='w-5/12'>
                    {new Date(eventData?.start_date).toDateString()}
                  </p>
                  <p className='mx-4'>|</p>
                  <p className='w-5/12'>{eventData?.location}</p>
                </div>
              </div> */}
              {/* <div
                className='!bg-white flex flex-col items-center h-72 p-2'
                style={{
                  position: 'absolute',
                  top: 0
                }}
              > */}
              <p
                className='font-bold tracking-wider  self-center absolute top-5   text-center text-white'
                style={{
                  width: 256,
                  fontSize: 18,
                  marginLeft: 63,
                  textAlign: 'center',
                  backgroundColor: 'transparent'
                }}
              >
                {eventData?.name}
              </p>
              <img
                src={data?.user?.picture || user}
                alt=''
                className='w-[150px] h-[150px] bg-white border border-themeGray-medium -mt-12 mb-3 rounded-full'
                style={{
                  position: 'absolute',
                  top: 0,
                  top: 158,
                  left: 116
                }}
              />
              <p
                className='text-3xl text-center font-bold'
                style={{
                  position: 'absolute',
                  top: 270,
                  width: '100%',
                  textAlign: 'center',
                  backgroundColor: 'rgb(255,255,255)'
                }}
              >{`${data?.user?.first_name}  ${data?.user?.last_name}`}</p>
              <p
                className='text-lg  font-semibold'
                style={{
                  position: 'absolute',
                  top: 310,
                  width: '100%',
                  textAlign: 'center',
                  backgroundColor: 'rgb(255,255,255)'
                }}
              >
                {data?.user?.metadata?.delegate_details?.company}
              </p>
              <p
                className='text-3xl text-center w-full pt-2 font-bold tracking-widest absolute text-white'
                style={{ bottom: 105 }}
              >
                {data?.user?.user_type === 'delegate' ? 'DELEGATE' : 'SPONSOR'}
              </p>
              <QRCode
                value={data?.user?._id}
                className='mt-5'
                size={51}
                style={{
                  position: 'absolute',
                  bottom: 24,
                  right: 13,
                  textAlign: 'center'
                }}
              />
              {/* </div> */}
            </div>
          </div>
        )
      })}
    </div>
  </div>
))

export default AttendeeCard
