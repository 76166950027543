import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { user, no_image } from "../../assets/images/images";

const SponsorsPresentationCardPDF = ({ sponsorDetail }) => {
  const styles = StyleSheet.create({
    cardContainer: {
      border: 0.5,
      borderColor: "#ccc",
    },
    cardHeader: {
      backgroundColor: "#E2B024",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 4,
      paddingHorizontal: 4,
      height: 36,
    },
    headerText: {
      display: "flex",
      flexDirection: "row",
      color: "#fff",
      alignItems: "center",
    },
    headerTitleContainer: {
      backgroundColor: "#fff",
      borderRadius: 12,
      paddingHorizontal: 6,
      paddingVertical: 2,
      marginRight: 16,
    },
    headerTitle: {
      fontSize: 10,
      color: "#e2b025",
      fontWeight: "bold",
    },
    headerTimeStamp: {
      color: "#fff",
      fontWeight: "800",
      marginTop: 2,
      fontSize: 12,
    },
    cardBody: {
      height: 220,
      backgroundColor: "#fff",
      padding: 6,
    },
    sponsorContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sponsorTitle: {
      color: "#E2B024",
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: "800",
      paddingBottom: 2,
      width: "68%",
    },
    seprator: {
      borderRight: 1,
      height: 80,
      width: 1,
      borderColor: "#ccc",
      marginHorizontal: 4,
    },
    sponsorImage: {
      width: "30%",
      height: 100,
    },
    speakerContainer: {
      marginTop: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    speakerImage: {
      width: 30,
      height: 30,
      borderRadius: 15,
    },
    speakerTextContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 6,
    },
    speakerName: {
      fontSize: 10,
      fontWeight: "extrabold",
      Color: "#E2B024",
      marginBottom: 2,
    },
    speakerPosition: {
      fontSize: 8,
      marginBottom: 2,
    },
    speakerCompany: {
      fontSize: 8,
      fontWeight: "extrabold",
      Color: "#E2B024",
    },
  });
  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardHeader}>
        <View style={styles.headerText}>
          <View style={styles.headerTitleContainer}>
            <Text style={styles.headerTitle}>Sponsor Presentation</Text>
          </View>

          <Text style={styles.headerTimeStamp}>
            {sponsorDetail?.start_time} - {sponsorDetail?.end_time}
          </Text>
        </View>
      </View>

      <View style={styles.cardBody}>
        <View style={styles.sponsorContainer}>
          <Text style={styles.sponsorTitle}>{sponsorDetail?.title}</Text>
          <View style={styles.seprator}></View>
          <Image
            source={sponsorDetail?.image || no_image}
            style={styles.sponsorImage}
          />
        </View>
        <View style={styles.speakerContainer}>
          <Image
            src={sponsorDetail?.speaker?.picture || user}
            alt=""
            style={styles.speakerImage}
          />
          <View style={styles.speakerTextContainer}>
            <Text style={styles.speakerName}>
              {sponsorDetail?.speaker?.first_name}
              {sponsorDetail?.speaker?.last_name}
            </Text>
            <Text style={styles.speakerPosition}>
              {sponsorDetail?.speaker?.metadata?.delegate_details.position ||
                "----"}
            </Text>
            <Text style={styles.speakerCompany}>
              {sponsorDetail?.speaker?.metadata?.delegate_details?.company ||
                "----"}
            </Text>
          </View>
        </View>
      </View>
      {/* {sponsorDetail?.hall === "Hall B" && (
          <View  className="font-bold text-center text-[#e2b025] mt-4">
            <hr className="mb-2" />
            All sponsor presentations will be in Hall A
          </View>
        )} */}
    </View>
  );
};

export default SponsorsPresentationCardPDF;
