import { useEffect, useState, useCallback } from 'react'

import {
  useLazyGetEmptySlotsQuery,
  useLazyGetInvitationQuery,
  usePutInvitationMutation
} from '../../../services/invitation'
import { Collapse } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import AddSlotModal from '../../../components/addSlotModal'
import { useGetMeetingsQuery } from '../../../services/meetings'
import dayjs from 'dayjs'
import AddAttendeeModal from '../../../components/addAttendeeModal/addAttendeeModal'

const { Panel } = Collapse

const SponsorMeetings = ({ eventID, attendance }) => {
  const [sponsors, setSponsors] = useState({})
  const [timeWiseMeetings, setTimeWiseMeetings] = useState({})
  const [showAddSlotModal, setShowAddSlotModal] = useState(false)
  const [inviteId, setInviteId] = useState(null)
  const [meetingId, setMeetingId] = useState(null)
  const [extraMeetings, setExtraMeetings] = useState([])
  const [showExtraMeetingModal, setShowExtraMeetingModal] = useState(false)
  const [isLoadingNeed, setIsLoading] = useState(false)

  const [getInvitations, { data: allInvitations, isLoading }] =
    useLazyGetInvitationQuery()

  const { data: meetings } = useGetMeetingsQuery({ eventID })

  const [
    putInvitation,
    { isLoading: updatingInvitation, error: updateInvitationError }
  ] = usePutInvitationMutation()

  const [getSlots, { data, isLoading: slotsLoading }] =
    useLazyGetEmptySlotsQuery()

  useEffect(() => {
    getAllInvitations()
  }, [isLoading, isLoadingNeed])

  const getAllInvitations = async () => {
    const getInv = await getInvitations({
      eventId: eventID,
      status: 'accepted'
    })

    if (allInvitations) {
      const acceptedInvitations = allInvitations?.invitations?.data
      let sponsors = {}
      let timeKeys = {}

      if (acceptedInvitations?.length) {
        acceptedInvitations.map(data => {
          if (timeKeys[data?.meeting?.start_time + data?.meeting?.day]) {
            timeKeys[data?.meeting?.start_time + data?.meeting?.day].push(data)
          } else {
            timeKeys[data?.meeting?.start_time + data?.meeting?.day] = [data]
          }
          let sponsor =
            data?.sender?.user_type === 'sponsor' ? data?.sender : data?.sender
          if (sponsors[sponsor._id]) {
            sponsors[sponsor._id]?.meetings?.push(data)
          } else {
            if (sponsor?.user_type === 'sponsor') {
              sponsors[sponsor._id] = {
                info: sponsor,
                meetings: [data]
              }
            }
          }
          console.log(
            'user type-->',
            sponsor?.user_type,
            sponsor?.first_name + ' ' + sponsor?.last_name
          )
        })

        setSponsors({ ...sponsors })
        setTimeWiseMeetings({ ...timeKeys })
      }
    }
  }

  const handleOnClickEdit = async (sender, receiver, inviteId) => {
    const slots = await getSlots({
      eventId: eventID,
      sender: sender,
      receiver: receiver
    })

    setInviteId(inviteId)

    if (!slots.isError) {
      setShowAddSlotModal(true)
    }
  }

  const handleOnClickSlot = async slot => {
    const inviteData = {
      status: 'accepted',
      meetingId: slot._id
    }
    const invite = await putInvitation({ id: inviteId, body: inviteData })
    if (!invite.error) {
      setShowAddSlotModal(false)
      setIsLoading(!isLoadingNeed)
      getAllInvitations()
      setInviteId(null)
    }
  }

  function sortObjectsByDayAndTime (objects) {
    // Define a custom sorting function
    function compareDayAndTime (a, b) {
      const dayA = a.day
      const dayB = b.day

      if (dayA === dayB) {
        const timeFormat = 'hh:mm A' // Specify the time format
        const timeObjA = dayjs(a.start_time, timeFormat)
        const timeObjB = dayjs(b.start_time, timeFormat)

        return timeObjA - timeObjB
      }

      return dayA.localeCompare(dayB)
    }

    // Sort the objects using the custom sorting function
    const sortedObjects = objects.slice().sort(compareDayAndTime)

    return sortedObjects
  }

  const showExtraMeetings = (meetings, meetingId, inviteId, time, userInfo) => {
    for (var i = 0; i < meetings.length; i++) {
      let receiver =
        meetings[i].receiver?._id === userInfo._id
          ? meetings[i].sender
          : meetings[i].receiver

      for (var j = 0; j < timeWiseMeetings[time].length; j++) {
        if (
          receiver._id === timeWiseMeetings[time][j]?.receiver._id ||
          receiver._id === timeWiseMeetings[time][j]?.sender._id
        ) {
          meetings.splice(i, 1)
        }
      }
    }
    if (meetings && meetings.length) {
      setExtraMeetings(meetings)
      setMeetingId(meetingId)
      setInviteId(inviteId)
      setShowExtraMeetingModal(true)
    } else {
      alert('No Extra Meetings for this User')
    }
  }

  const handleOnClickExtraMeeting = async extMtg => {
    console.log('extMtg--->', extMtg)
    const inviteData = {
      status: 'accepted',
      meetingId: meetingId,
      receiver: extMtg.receiver?._id,
      sender: extMtg.sender?._id,
      extraMeeting: true
    }
    console.log({ id: extMtg._id, body: inviteData })
    const invite = await putInvitation({ id: inviteId ||extMtg._id , body: inviteData })
    if (!invite.error) {
      setShowExtraMeetingModal(false)
      setMeetingId(null)
      setInviteId(null)
      getAllInvitations()
      // window.location.reload(false)
    }
  }

  // console.log('timeWiseMeetings-->', timeWiseMeetings)
  return (
    <div>
      <AddSlotModal
        slots={data?.emptySlots}
        isModalOpen={showAddSlotModal}
        onClickSlot={slot => handleOnClickSlot(slot)}
        onClose={() => setExtraMeetings(false)}
      />

      <AddAttendeeModal
        extraMeetings={extraMeetings}
        isModalOpen={showExtraMeetingModal}
        onClickSlot={mt => handleOnClickExtraMeeting(mt)}
        onClose={() => setShowExtraMeetingModal(false)}
      />

      <div>
        <table className='bg-white'>
          <tr className='p-3 my-4 flex bg-primary'>
            <th className='p-3  text-white w-[181px]'></th>
            {sortObjectsByDayAndTime(meetings?.meetings?.data || []).map(
              (data, indexOfTime) => (
                <th
                  key={indexOfTime}
                  className='p-3 text-white flex  w-[161px]'
                >
                  {data.start_time} - {data.end_time}
                  {`(${data.day})`}{' '}
                </th>
              )
            )}
          </tr>
          {Object.keys(sponsors)?.map(key => {
            return (
              <tr className='p-3 flex'>
                <th className='p-2 block w-[181px]'>
                  <h2 style={{ width: 150 }}>
                    {`${
                      sponsors[key].info?.first_name +
                      ' ' +
                      sponsors[key].info?.last_name
                    } ${
                      sponsors[key]?.meetings[0]?.attendee[0]?.allowed_meetings
                        ? `(${sponsors[key]?.meetings[0]?.attendee[0]?.allowed_meetings})`
                        : ''
                    }`}
                  </h2>
                </th>

                {sortObjectsByDayAndTime(meetings?.meetings?.data || []).map(
                  (data, ind) => {
                    const obj = sponsors[key].meetings.filter(
                      mt => mt?.meeting?._id === data._id
                    )
                    const extraMeetings = sponsors[key].meetings.filter(
                      mt => !mt?.meeting
                    )

                    const userToShow =
                      sponsors[key].info?.first_name ===
                      obj[0]?.receiver?.first_name
                        ? obj[0]?.sender
                        : obj[0]?.receiver

                    const isAttended = attendance
                      .find(
                        data => userToShow && data?.user?._id === userToShow._id
                      )
                      ?.attendance?.includes(data.day)

                    return obj[0] ? (
                      <th
                        onClick={() => {
                          console.log('obj[0]--->', obj[0])
                          showExtraMeetings(
                            extraMeetings,
                            data._id,
                            obj[0]._id,
                            obj[0]?.meeting?.start_time + obj[0]?.meeting?.day,
                            sponsors[key].info
                          )
                        }}
                        className='cursor-pointer p-3 w-[161px]'
                      >
                        <span
                          style={{
                            color: isAttended ? 'green' : 'red'
                          }}
                          className='font-normal text-left'
                        >
                          {sponsors[key].info?.first_name ===
                          obj[0]?.receiver?.first_name
                            ? `${obj[0]?.sender?.first_name} ${obj[0]?.sender?.last_name}`
                            : `${obj[0]?.receiver?.first_name} ${obj[0]?.receiver?.last_name}`}
                        </span>
                      </th>
                    ) : (
                      <th
                        onClick={() => {
                          console.log('obj[0]--->', obj[0])
                          showExtraMeetings(
                            extraMeetings,
                            data._id,
                            sponsors[key]._id,
                            sortObjectsByDayAndTime(
                              meetings?.meetings?.data || []
                            )[ind].start_time +
                              sortObjectsByDayAndTime(
                                meetings?.meetings?.data || []
                              )[ind].day,
                            sponsors[key].info
                          )
                        }}
                        className='cursor-pointer p-3 w-[161px]'
                      >
                        -{' '}
                      </th>
                    )
                  }
                )}
              </tr>
            )
          })}
        </table>
      </div>
    </div>
  )
}

export default SponsorMeetings
