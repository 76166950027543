import { useState } from "react";
import { Upload, DatePicker, Col, Drawer, Row, Input, Spin } from "antd";
import {
  left_arrow_icon,
  add_icon,
  upload_icon,
  calender_colored_icon,
} from "../../assets/icons/icons";
import { uploadImage } from "../../helpers/helpers";
import { ThemeButton, ThemeInput } from "../components";
const { Dragger } = Upload;

const EventDrawer = ({
  open,
  btnTxt,
  addIcon,
  onSubmit,
  isLoading,
  formData,
  setFormData,
  image,
  setImage,
  onClose,
}) => {
  // const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const handleImage = async (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      setUploading(true);
      const res = await uploadImage(info.file.originFileObj);
      setImage(res);
      setFormData({
        ...formData,
        banner: res,
      });
    }
  };

  const onChangeStartDate = (date) => {
    setFormData({
      ...formData,
      start_date: date,
    });
  };
  const onChangeEndDate = (date) => {
    setFormData({
      ...formData,
      end_date: date,
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Drawer
      width={450}
      title={btnTxt}
      placement="right"
      onClose={onClose}
      open={open}
      closeIcon={<img src={left_arrow_icon} alt="" width={18} height={18} />}
      extra={
        <ThemeButton
          onClick={onSubmit}
          className={"ml-4 w-32"}
          content={
            <>
              {isLoading ? (
                <Spin className="mt-1" />
              ) : (
                <div className="flex items-center">
                  {addIcon && (
                    <img
                      src={add_icon}
                      alt=""
                      width={18}
                      height={18}
                      className="mr-2"
                    />
                  )}
                  <p>{btnTxt}</p>
                </div>
              )}
            </>
          }
        />
      }
    >
      <div>
        <p className="font-semibold mb-2">Event Banner</p>
        {image ? (
          <img src={image} alt="" />
        ) : (
          <>
            {uploading ? (
              <div className="flex justify-center">
                <Spin />
              </div>
            ) : (
              <Dragger
                name="file"
                multiple={false}
                showUploadList={false}
                onChange={handleImage}
              >
                <div className="flex justify-center mb-1">
                  <img src={upload_icon} alt="" width={40} />
                </div>
                <p className="">Drag and drop your file</p>
                <p className="">OR</p>
                <div className="flex justify-center mt-1">
                  <ThemeButton content={"Browse File"} />
                </div>
              </Dragger>
            )}
          </>
        )}
      </div>
      <Row className="mt-6" gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col span={24} className="mb-4">
          <ThemeInput
            label={"Event Name"}
            placeholder={"Event Name"}
            onChange={handleChange}
            value={formData.name}
            name="name"
          />
        </Col>
        <Col span={12} className="mb-4">
          <p className="font-semibold mb-1">Start Date</p>
          <DatePicker
            value={formData.start_date}
            onChange={onChangeStartDate}
            className="w-full h-10 font-medium"
            suffixIcon={<img src={calender_colored_icon} alt="" width={20} />}
          />
        </Col>
        <Col span={12} className="mb-4">
          <p className="font-semibold mb-1">End Date</p>
          <DatePicker
            value={formData.end_date}
            onChange={onChangeEndDate}
            className="w-full h-10 font-medium"
            suffixIcon={<img src={calender_colored_icon} alt="" width={20} />}
          />
        </Col>
        {/* <Col span={12} className="mb-4">
          <p className="font-semibold mb-1">Start Time</p>
          <TimePicker onChange={onChangeTime} className="w-full" />
        </Col>
        <Col span={12} className="mb-4">
          <p className="font-semibold mb-1">End Time</p>
          <TimePicker onChange={onChangeTime} className="w-full" />
        </Col> */}
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Event Location"}
            placeholder={"Event location"}
            onChange={handleChange}
            value={formData.location}
            name="location"
          />
        </Col>
        {/* <Col span={12} className="mb-4">
          <ThemeInput label={"Event Hall"} placeholder={"Event Hall"} />
        </Col> */}
        {/* <Col span={12} className="mb-4">
          <ThemeInput
            label={"Event Attendees"}
            placeholder={"Event Attendees"}
          />
        </Col> */}
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Panel Discussons"}
            placeholder={"Panel Discussons"}
            name="discussion"
            onChange={handleChange}
            value={formData.discussion}
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Speakers"}
            placeholder={"Speakers"}
            onChange={handleChange}
            name="speakers"
            value={formData.speakers}
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Meetings"}
            placeholder={"Meetings"}
            onChange={handleChange}
            name="meetings"
            value={formData.meetings}
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Networking Hours"}
            name="networking_hours"
            placeholder={"Networking Hours"}
            onChange={handleChange}
            value={formData.networking_hours}
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Exhibition Booths"}
            placeholder={"Exhibition Booths"}
            name="booths"
            onChange={handleChange}
            value={formData.booths}
          />
        </Col>
      </Row>
      <div className="mb-3">
        <p className="font-semibold mb-1">Event Description</p>
        <Input.TextArea
          name="description"
          placeholder="Event Description"
          rows={5}
          className="font-medium pt-2"
          onChange={handleChange}
          value={formData.description}
        />
      </div>
    </Drawer>
  );
};

export default EventDrawer;
