import { useState, useEffect } from "react";
import { Spin, Tabs } from "antd";
import {
  add_icon,
  edit_icon,
  calender_icon,
  calender_outlined_icon,
  events_white_icon,
  right_arrow_icon,
} from "../../assets/icons/icons";
import {
  Container,
  EventDrawer,
  ThemeButton,
  ThemeTable,
} from "../../components/components";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import {
  useAddEventsMutation,
  usePutEventsMutation,
  useLazyGetEventsQuery,
} from "../../services/events";
import dayjs from "dayjs";
import { BASE_URL } from "../../constants/constants";
import { getValueFromStorage } from "../../helpers/helpers";

const Event = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(
    searchParams.get("tab") || "1"
  );
  const [open, setOpen] = useState(false);
  const [editID, setEditID] = useState("");
  const [mode, setMode] = useState("create");
  const [ongoingEvents, setOngoingEvents] = useState([]);
  const [upcomingEvents, setupcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [getEvents, { isLoading }] = useLazyGetEventsQuery();
  const [addEvent, { isLoading: addingEvent }] = useAddEventsMutation();
  const [putEvents, { isLoading: updatingEvent }] = usePutEventsMutation();
  useEffect(() => {
    fetchEvents();
  }, [selectedKey]);

  const [formData, setFormData] = useState({
    name: "",
    banner: "",
    start_date: "",
    end_date: "",
    location: "",
    discussion: "",
    speakers: "",
    meetings: "",
    networking_hours: "",
    booths: "",
    description: "",
  });
  const [image, setImage] = useState("");

  const fetchEvents = async () => {
    let ongoing = selectedKey == "1" || "";
    let upcoming = selectedKey == "2" || "";
    let past = selectedKey == "3" || "";
    const response = await getEvents({ ongoing, upcoming, past });
    console.log("res", response);
    selectedKey == "1"
      ? setOngoingEvents(response?.data?.events?.data)
      : selectedKey == "2"
      ? setupcomingEvents(response?.data?.events?.data)
      : selectedKey == "3" && setPastEvents(response?.data?.events?.data);
  };

  const onClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      banner: "",
      start_date: "",
      end_date: "",
      location: "",
      discussion: "",
      speakers: "",
      meetings: "",
      networking_hours: "",
      booths: "",
      description: "",
    });
    setImage("");
    setMode("create");
  };

  const handleEvent = async () => {
    let data = {
      ...formData,
      start_date: formData?.start_date?.$d
        ? new Date(formData.start_date.$d)
        : null,
      end_date: formData?.end_date?.$d ? new Date(formData.end_date.$d) : null,
    };

    if (data.start_date) {
      data.start_date.setHours(0, 0, 0, 0);
      data.start_date = data.start_date.toISOString();
    }
    if (data.end_date) {
      data.end_date.setHours(23, 59, 59, 59);
      data.end_date = data.end_date.toISOString();
    }

    console.log("data", data);

    let response;
    if (mode == "create") {
      response = await addEvent(data);
    } else if (mode == "edit") {
      response = await putEvents({ id: editID, body: data });
    }
    fetchEvents();
    onClose();
  };

  const onEdit = (data) => {
    setEditID(data._id);
    setFormData({
      name: data?.name,
      banner: data?.banner,
      start_date: dayjs(data?.start_date),
      end_date: dayjs(data?.end_date),
      location: data?.location,
      discussion: data?.discussion,
      speakers: data?.speakers,
      meetings: data?.meetings,
      networking_hours: data?.networking_hours,
      booths: data?.booths,
      description: data?.description,
    });
    setImage(data?.banner);
    setMode("edit");
    setOpen(true);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (item, record, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Banner",
      dataIndex: "banner",
      key: "banner",
      widthL: "20%",
      render: (text) => (
        <div className="flex justify-center">
          <img src={text} alt="" width={45} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => (
        <NavLink to={`/event/${text}`} className="flex justify-center">
          <p className="text-center font-medium">
            {new Date(text).toDateString()}
          </p>
        </NavLink>
      ),
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center z-10">
          <span className="mx-2 cursor-pointer" onClick={() => onEdit(data)}>
            <img src={edit_icon} alt="" width={15} />
          </span>
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      width: "8%",
      render: (text) => (
        <NavLink to={`/event/${text}`} className="flex justify-center">
          <img src={right_arrow_icon} alt="" width={15} />
        </NavLink>
      ),
    },
  ];

  const onChange = (key) => {
    setSearchParams({ tab: key });
    setSelectedKey(key);
  };

  const items = [
    {
      key: "1",
      label: (
        <p className={`${selectedKey === "1" && "font-semibold"}`}>
          On Going Events
        </p>
      ),
      children: (
        <ThemeTable columns={columns} data={ongoingEvents} loader={isLoading} />
      ),
    },
    {
      key: "2",
      label: (
        <p className={`${selectedKey === "2" && "font-semibold"}`}>
          Upcoming Events
        </p>
      ),
      children: (
        <ThemeTable
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                navigate(`/event/${record._id}`);
              },
            };
          }}
          columns={columns}
          data={upcomingEvents}
          loader={isLoading}
        />
      ),
    },
    {
      key: "3",
      label: (
        <p className={`${selectedKey === "3" && "font-semibold"}`}>
          Past Events
        </p>
      ),
      children: <ThemeTable columns={columns} data={pastEvents} />,
    },
  ];

  return (
    <Container
      selected={"2"}
      header={
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-primary h-9 w-9 rounded-full flex justify-center items-center">
              <img src={events_white_icon} width={17} height={17} alt="" />
            </div>
            <p className="ml-2 font-semibold text-2xl">Events</p>
          </div>
          <div className="flex">
            <ThemeButton
              onClick={() => setOpen(true)}
              className={"ml-3"}
              content={
                <div className="flex items-center">
                  <img src={add_icon} alt="" width={18} height={18} />
                  <p className="ml-2">Add Event</p>
                </div>
              }
            />
          </div>
        </div>
      }
    >
      <EventDrawer
        formData={formData}
        image={image}
        setImage={(e) => setImage(e)}
        setFormData={(e) => setFormData(e)}
        isLoading={addingEvent}
        onSubmit={handleEvent}
        open={open}
        onClose={onClose}
        btnTxt={mode == "create" ? "Add Event" : "Edit Event"}
        addIcon
      />
      {/* {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "75vh" }}
        >
          <Spin size="large" />
        </div>
      ) : ( */}
      <Tabs
        defaultActiveKey={searchParams.get("tab") || "1"}
        items={items}
        onChange={onChange}
      />
      {/* )} */}
    </Container>
  );
};

export default Event;
