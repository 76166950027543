import { ThemeButton } from "../components";
import { edit_white_icon, delete_white_icon } from "../../assets/icons/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { user } from "../../assets/images/images";

const SponsorsPresentationCard = ({
  sponsorDetail,
  onEdit,
  onDelete,
  isAdmin,
  isUser,
  isAdded,
  hall,
  onAddAgenda,
}) => {
  return (
    <div className="relative">
      <div className="bg-primary flex items-center justify-between py-4 px-4 static">
        <div className="flex text-white items-center">
          <p className="bg-[#fff] text-[12px] text-[#e2b025] rounded-full  py-1 px-4 font-bold mr-10">
            Sponsor Presentation
          </p>

          <p className="text-[14px] font-semibold mt-1">
            {sponsorDetail?.start_time} - {sponsorDetail?.end_time}
          </p>
        </div>
        {isAdmin && !isAdded && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer w-8"
              onClick={() => onEdit(sponsorDetail)}
            >
              <img src={edit_white_icon} alt="" width={18} height={18} />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => showDeletePopup(() => onDelete(sponsorDetail))}
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
        {!isAdmin && isAdded !== -1 && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer"
              onClick={() =>
                showDeletePopup(() => onDelete(sponsorDetail.agenda_id))
              }
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
      </div>
      {(isAdmin || isAdded !== -1) && (
        <div
          className="h-[350px] bg-white p-4 shadow-md shadow-gray-300"
          style={{ minHeight: 200 }}
        >
          <div className="flex items-center justify-between">
            <p className="text-primary text-[22px] font-semibold pb-1">
              {sponsorDetail?.title}
            </p>
            <div className="border-r-2 h-[120px] w-[4px]"></div>
            <p>
              <img
                className={"w-[350px] h-[150px] object-contain"}
                src={sponsorDetail?.image}
                alt=""
              />
            </p>
          </div>
          <div className="mt-5 flex items-center">
            <img
              width="50"
              height="50"
              src={sponsorDetail?.speaker?.picture || user}
              alt=""
              className="w-[50px] h-[50px] rounded-full object-cover"
            />
            <div className="ml-3">
              <p className="text-primary font-extrabold text-base md:text-lg">
                {sponsorDetail?.speaker?.first_name}{" "}
                {sponsorDetail?.speaker?.last_name}
              </p>
              <p className="text-xs">
                {sponsorDetail?.speaker?.metadata?.delegate_details?.position ||
                  "----"}
              </p>
              <p className="text-primary text-xs font-semibold">
                {sponsorDetail?.speaker?.metadata?.delegate_details?.company ||
                  "----"}
              </p>
            </div>
          </div>
          {hall === "Hall B" && (
            <div className="absolute bottom-0 left-0 right-0 bg-gray-50 font-bold text-center text-[#e2b025] mt-4">
              <hr className="mb-2" />
              All sponsor presentations will be in Hall A
              <hr className="mb-1" />
            </div>
          )}
        </div>
      )}
      {isUser && isAdded === -1 && (
        <div
          className="h-[350px] bg-white p-4 shadow-md shadow-gray-300 flex justify-center items-center"
          style={{ minHeight: 200 }}
        >
          <ThemeButton
            onClick={() => onAddAgenda(sponsorDetail)}
            content={
              <div>
                <PlusCircleOutlined className="mr-4" />
                <span>Add agenda</span>
              </div>
            }
            className="w-46 text-lg !h-12 mt-4"
          />
        </div>
      )}
    </div>
  );
};

export default SponsorsPresentationCard;
