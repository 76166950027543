import { ThemeButton } from '../../../../components/components'
import { second_cover_image } from '../../../../assets/images/images'
import { Row, Col, Skeleton } from 'antd'

const EventInfo = ({ event }) => {
  return event ? (
    <div className='bg-white rounded-xl p-5 mb-8'>
      <div className='relative'>
        <img
          className='w-full h-[300px] object-cover rounded-xl'
          src={event?.banner}
          alt=''
        />
        <div className='md:block hidden w-full absolute bg-[rgba(0,0,0,0.8)] h-[300px] top-0 rounded-xl' />
        <div className='mt-3 md:mt-0 static md:absolute top-0 md:left-12 flex flex-col w-full md:w-auto md:items-center justify-between md:justify-center h-full'>
          <div>
            <p className='md:text-white font-bold text-lg md:text-4xl'>
              {event?.name}
            </p>
            <p className='md:text-white font-medium mt-2'>
              {new Date(event?.start_date).toDateString()} TO{' '}
              {new Date(event?.end_date).toDateString()}
            </p>
            <div className='flex justify-start md:mt-3'>
              <p className='md:text-primary font-medium'>{event?.location}</p>
            </div>
          </div>
        </div>
      </div>
      <Row className='mt-4 md:mt-8'>
        <Col lg={8} xs={24}>
          <p className='text-primary font-medium text-lg lg:text-2xl'>
            Event Attendence:
          </p>
          <p className='font-semibold text-base lg:text-xl'>
            {event?.attendees?.length}
          </p>
          <p className='text-primary font-medium text-lg lg:text-2xl mt-3 lg:mt-6'>
            Meetings:
          </p>
          <p className='font-semibold text-base lg:text-xl'>
            {event?.meetings}
          </p>
        </Col>
        <Col lg={8} xs={24} className='mt-3 lg:mt-0'>
          <p className='text-primary font-medium text-lg lg:text-2xl'>
            Panel Discussion:
          </p>
          <p className='font-semibold text-base lg:text-xl'>
            {event?.discussion}
          </p>
          <p className='text-primary font-medium text-lg lg:text-2xl mt-3 lg:mt-6'>
            Exhibition Booths:
          </p>
          <p className='font-semibold text-base lg:text-xl'>{event?.booths}</p>
        
        </Col>
        <Col lg={8} xs={24} className='mt-3 lg:mt-0'>
          <p className='text-primary font-medium text-lg lg:text-2xl'>
            Speaker:
          </p>
          <p className='font-semibold text-base lg:text-xl'>
            {event?.speakers}
          </p>
          <p className='text-primary font-medium text-lg lg:text-2xl mt-3 lg:mt-6'>
            Networking Hours:
          </p>
          <p className='font-semibold text-base lg:text-xl'>
            {event?.networking_hours}
          </p>
        </Col>
        

        <Col span={24} className='mt-6'>
          <p className='text-primary font-medium text-lg lg:text-2xl'>
            Event Description:
          </p>
          <p className='font-medium text-base lg:text-lg'>
            {event?.description}
          </p>
        </Col>
      </Row>
    </div>
  ) : (
    <div className='bg-white rounded-xl p-5 mb-8'>
      <Skeleton />
    </div>
  )
}

export default EventInfo
