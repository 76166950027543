import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { user } from "../../assets/images/images";

const PanelDiscutionCardPDF = ({ panelDetails }) => {
  const styles = StyleSheet.create({
    cardContainer: {
      border: 0.5,
      borderColor: "#ccc",
    },
    cardHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      backgroundColor: "#E2B024",
      padding: 10,
      height: 36,
    },
    cardHeaderTextContainer: {
      display: "flex",
      flexDirection: "row",
      color: "#fff",
      textAlign: "center",
    },
    cardHeaderTitle: {
      backgroundColor: "#fff",
      fontSize: 10,
      color: "#e2b025",
      fontWeight: "bold",
      paddingHorizontal: 10,
      paddingVertical: 4,
      marginRight: 16,
      borderRadius: 12,
    },
    cardHeaderTime: {
      fontSize: 12,
      fontWeight: "semibold",
      marginTop: 2,
    },
    cardBody: {
      marginTop: 8,
      minHeight: 220,
      overflow: "scroll",
      backgroundColor: "#fff",
      padding: 10,
      marginBottom: 10,
    },
    cardTitle: {
      color: "#E2B024",
      fontSize: 12,
      fontWeight: "semibold",
      paddingBottom: 4,
    },
    descriptionContainer: {
      display: "flex",
      marginTop: 4,
    },
    pointContaienr: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 6,
    },
    point: {
      height: 6,
      width: 6,
      borderRadius: 3,
      backgroundColor: "#E2B024",
      marginTop: 4,
    },
    descriptionText: {
      fontSize: 10,
      marginLeft: 4,
    },
    speakerContainer: {
      marginTop: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    speakerImage: {
      width: 30,
      height: 30,
      borderRadius: 15,
    },
    speakerTextContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 6,
    },
    speakerName: {
      fontSize: 10,
      fontWeight: "extrabold",
      Color: "#E2B024",
      marginBottom: 2,
    },
    speakerPosition: {
      fontSize: 8,
      marginBottom: 2,
    },
    speakerCompany: {
      fontSize: 8,
      fontWeight: "extrabold",
      Color: "#E2B024",
    },
  });
  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardHeader}>
        <View style={styles.cardHeaderTextContainer}>
          <Text style={styles.cardHeaderTitle}>
            {panelDetails?.short_title}
          </Text>

          <Text
            style={styles.cardHeaderTime}
          >{`${panelDetails.start_time} - ${panelDetails.end_time}`}</Text>
        </View>
      </View>
      <View style={styles.cardBody}>
        <Text style={styles.cardTitle}>{panelDetails?.title}</Text>
        <View style={styles.speakerContainer}>
          <Image
            source={panelDetails?.moderator?.picture || user}
            alt=""
            style={styles.speakerImage}
          />
          <View style={styles.speakerTextContainer}>
            <Text style={styles.speakerName}>
              {panelDetails?.moderator?.first_name}{" "}
              {panelDetails?.moderator?.last_name} (Moderator)
            </Text>
            <Text style={styles.speakerPosition}>
              {panelDetails?.moderator?.metadata?.delegate_details?.position ||
                "----"}
            </Text>
            <Text style={styles.speakerCompany}>
              {panelDetails?.moderator?.metadata?.delegate_details?.company ||
                "----"}
            </Text>
          </View>
        </View>
        <br />
        <hr />
        {panelDetails.panellist.map((v, i) => {
          return (
            <View key={i} className="mt-5 flex items-center">
              <img
                width="50"
                height="50"
                src={v.picture || user}
                alt=""
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <View className="ml-3">
                <Text className="text-primary font-extrabold text-base md:text-lg">
                  {v.first_name} {v.last_name} (Panellist)
                </Text>
                <Text className="text-xs">
                  {v.metadata?.delegate_details?.position || "----"}
                </Text>
                <Text className="text-primary text-xs font-semibold">
                  {v.metadata?.delegate_details?.company || "----"}
                </Text>
              </View>
            </View>
          );
        })}
        {/* {hall === "Hall B" && (
          <View className="absolute bottom-0 left-0 right-0 bg-gray-50 font-bold text-center text-[#e2b025] mt-4">
            <hr className="mb-2" />
            All Panel Discussions will be in Hall A
            <hr className="mb-1" />
          </View>
        )} */}
      </View>
    </View>
  );
};

export default PanelDiscutionCardPDF;
