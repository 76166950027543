import React from "react";
import { Select } from "antd";
import "./index.css";

const ThemeSelect = ({
  value,
  onChange,
  placeholder,
  options,
  className,
  ...props
}) => {
  return (
    <Select
      {...props}
      style={{ height: 32 }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`${className} selectBox`}
      options={options}
    />
  );
};

export default ThemeSelect;
