import { useState, useEffect } from "react";
import { no_agenda } from "../../../../assets/images/images";
import {
  ThemeButton,
  ThemeDropdown,
  AddAgendaModal,
  PersonalAgendaPDF,
} from "../../../../components/components";
import {
  useLazyGetAgendaQuery,
  useAddAgendaMutation,
  useDeleteAgendaMutation,
} from "../../../../services/agenda";
import { getUserInfo } from "../../../../helpers/helpers";
import { useParams } from "react-router-dom";
import UserAgenda from "../eventAgenda/eventAgenda";
import options from "../../../../lib/data/options.json";
import { Spin, message } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useGetInvitationQuery } from "../../../../services/invitation";

const PersonalAgenda = ({ eventId, eventName, schedules }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getAgenda, { isLoading }] = useLazyGetAgendaQuery();
  const [deleteUserAgenda, { isLoading: deleteLoading }] =
    useDeleteAgendaMutation();
  const [addUserAgenda, { isLoading: addLoading }] = useAddAgendaMutation();

  const { data: allInvitations, isLoading: invitationLoading } =
    useGetInvitationQuery({
      eventId: eventId,
      sender: getUserInfo().id,
      receiver: getUserInfo().id,
      status: "accepted",
    });

  const [agenda, setAgenda] = useState([]);
  const [personalSchedules, setPersonalSchedules] = useState([]);
  const [day, setDay] = useState("All");
  const [formData, setFormData] = useState([]);
  const { id } = useParams();

  const getUserAgenda = async () => {
    const response = await getAgenda({
      event_id: id,
      user_id: getUserInfo().id,
    });

    console.log("id->", id, getUserInfo().id);
    const arr = response?.data?.agendas.data || [];
    console.log("Agendas->", response?.data?.agendas.data);
    setAgenda(arr.map((v) => ({ ...v.schedule, agenda_id: v._id })));
  };

  const addAgenda = async (scheduleID) => {
    console.log("scheduleID-->", scheduleID);
    const response = await addUserAgenda({
      event: eventId,
      schedule: scheduleID,
      user: getUserInfo().id,
    });

    if (response?.data?.status === 200) {
      message.success("Schedule added successfully.");
      setIsModalOpen(false);
      getUserAgenda();
    } else {
      message.error("Something went wrong");
    }
  };

  const deleteAgenda = async (sch) => {
    console.log("sch->", sch);
    const deletedAgenda = await deleteUserAgenda(sch.agenda_id);
    console.log("deletedAgenda->", deletedAgenda);
    if (deletedAgenda.data.status === 200) {
      message.success("Delete agenda successfully");
      getUserAgenda();
    } else {
      message.error("Something went wrong");
    }
    console.log("deletedAgenda resp", deletedAgenda);
  };

  const onAddAgenda = (data) => {
    setIsModalOpen(true);
    let filterAgendas = schedules.filter(
      (item) =>
        (item.start_time === data.start_time ||
          item.end_time === data.end_time) &&
        item.day === data.day
    );
    setFormData(filterAgendas || []);
  };

  useEffect(() => {
    getUserAgenda();
  }, [day]);
  useEffect(() => {
    let personalSchedule = [];
    // const data = schedules.filter((data) => data.day === day);
    const data = schedules;
    const sortedAgenda = agenda;
    // const sortedAgenda = agenda.filter((data) => data.day === day);
    for (var i = 0; i < data?.length; i++) {
      let isStartTimeThere = personalSchedule.findIndex(
        (item) =>
          item.day === data[i].day && item.start_time === data[i].start_time
      );
      if (isStartTimeThere === -1) {
        let inAgenda = sortedAgenda.find(
          (item) =>
            item.type === data[i].type && item.start_time === data[i].start_time
        );
        personalSchedule.push(inAgenda ? { ...inAgenda } : { ...data[i] });
      }
    }
    setPersonalSchedules(personalSchedule);
  }, [schedules, agenda]);

  return (
    <div>
      <AddAgendaModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        day={day}
        agendas={formData}
        addAgenda={(e) => addAgenda(e)}
        isLoading={false}
      />
      <div className="flex items-center justify-between mb-4">
        <div>
          <ThemeDropdown
            setValue={(e) => setDay(e)}
            text={day}
            items={[
              {
                label: "All",
                key: "All",
              },
              ...options.days,
            ]}
          />
        </div>
        <div className="flex justify-end w-full">
          <PDFDownloadLink
            document={
              <PersonalAgendaPDF
                agenda={agenda || []}
                schedules={personalSchedules}
                invitations={allInvitations?.invitations?.data}
              />
            }
            fileName={"AgendaPDF"}
          >
            <ThemeButton content={<p>Download</p>} />
          </PDFDownloadLink>
        </div>
      </div>
      {schedules.length ? (
        day === "All" ? (
          options?.days?.map((d) => {
            return (
              <UserAgenda
                day={d?.value}
                deleteAgenda={(sch) => deleteAgenda(sch)}
                agenda={agenda || []}
                schedules={personalSchedules}
                isPersonalize={true}
                onAddAgenda={(e) => onAddAgenda(e)}
                onDelete={deleteAgenda}
                invitations={allInvitations?.invitations?.data}
              />
            );
          })
        ) : (
          <UserAgenda
            day={day}
            deleteAgenda={(sch) => deleteAgenda(sch)}
            agenda={agenda || []}
            schedules={personalSchedules}
            isPersonalize={true}
            onAddAgenda={(e) => onAddAgenda(e)}
            onDelete={deleteAgenda}
            invitations={allInvitations?.invitations?.data}
          />
        )
      ) : (
        <div
          className="bg-white rounded-lg mt-4 flex justify-center items-center flex-col"
          style={{ height: 450 }}
        >
          <img src={no_agenda} alt="" width={450} />
          <p className="font-bold text-4xl mt-3">
            Create Your <span className="text-primary">Agenda</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default PersonalAgenda;
