const StatsCard = ({ icon, title, count, className }) => {
  return (
    <div
      className={`${className} bg-white p-7 rounded-3xl h-40 flex flex-col justify-between`}
    >
      <div>
        <img src={icon} alt="" width={25} />
      </div>
      <div className="flex justify-between items-center">
        <p className="text-base font-semibold w-32">{title}</p>
        <p className="text-primary text-4xl font-semibold">{count}</p>
      </div>
    </div>
  );
};

export default StatsCard;
