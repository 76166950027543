import { Col, Drawer, Row, Spin, Input, DatePicker } from "antd";
import dayjs from "dayjs";
import {
  left_arrow_icon,
  add_icon,
  calender_colored_icon,
} from "../../assets/icons/icons";
import { uploadImage } from "../../helpers/helpers";
import {
  ThemeButton,
  ThemeInput,
  UploadUserProfile,
  ThemeSelect,
} from "../components";
import options from "../../lib/data/options.json";

const SupportStaffDrawer = ({
  open,
  btnTxt,
  addIcon,
  onSubmit,
  isLoading,
  formData,
  setFormData,
  image,
  setImage,
  onClose,
  mode,
}) => {
  const handleImage = async (e) => {
    const res = await uploadImage(e.target.files[0]);
    setImage(res);
    setFormData({
      ...formData,
      picture: res,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Drawer
      width={600}
      title={btnTxt}
      placement="right"
      onClose={onClose}
      open={open}
      closeIcon={<img src={left_arrow_icon} alt="" width={18} height={18} />}
      extra={
        <ThemeButton
          onClick={onSubmit}
          className={"ml-4 w-48"}
          content={
            <>
              {isLoading ? (
                <Spin className="mt-1" />
              ) : (
                <div className="flex items-center">
                  {addIcon && (
                    <img
                      src={add_icon}
                      alt=""
                      width={18}
                      height={18}
                      className="mr-2"
                    />
                  )}
                  <p>{btnTxt}</p>
                </div>
              )}
            </>
          }
        />
      }
    >
      <div className="flex justify-center">
        <UploadUserProfile image={image} onChange={handleImage} />
      </div>
      <Row className="mt-6" gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"First Name"}
            placeholder={"First name"}
            onChange={handleChange}
            value={formData.first_name}
            name="first_name"
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Last Name"}
            placeholder={"Last name"}
            onChange={handleChange}
            value={formData.last_name}
            name="last_name"
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Company"}
            placeholder={"companay"}
            onChange={(e) =>
              setFormData({
                ...formData,
                metadata: {
                  ...formData.metadata,
                  company: e.target.value,
                },
              })
            }
            value={formData?.metadata?.company}
            name="text"
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Position"}
            placeholder={"position"}
            onChange={(e) =>
              setFormData({
                ...formData,
                metadata: {
                  ...formData.metadata,
                  position: e.target.value,
                },
              })
            }
            value={formData?.metadata?.position}
            name="text"
            labelPrimary
          />
        </Col>
        <Col span={24} className="mb-4">
          <ThemeInput
            type="email"
            label={"Email"}
            name="email"
            placeholder={"email"}
            onChange={handleChange}
            value={formData.email}
            labelPrimary
            // disabled={mode === "edit"}
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            type="number"
            label={"Phone"}
            placeholder={"phone"}
            onChange={(e) =>
              setFormData({
                ...formData,
                metadata: {
                  ...formData.metadata,
                  phone: e.target.value,
                },
              })
            }
            value={formData?.metadata?.phone}
            name="text"
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            type="number"
            label={"Mobile"}
            placeholder={"mobile"}
            onChange={(e) =>
              setFormData({
                ...formData,
                metadata: {
                  ...formData.metadata,
                  mobile: e.target.value,
                },
              })
            }
            value={formData?.metadata?.mobile}
            name="text"
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Password"}
            placeholder={"password"}
            name={"password"}
            value={formData.password}
            onChange={handleChange}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <p className="text-base text-primary font-semibold">Gender</p>
          <ThemeSelect
            placeholder={"Gender"}
            value={formData.gender}
            onChange={(value) => setFormData({ ...formData, gender: value })}
            options={options?.genders}
            className={"w-full"}
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1 text-primary">Address</p>
          <Input.TextArea
            placeholder="write your address here"
            name={"address"}
            value={formData?.metadata?.address}
            onChange={(e) =>
              setFormData({
                ...formData,
                metadata: {
                  ...formData.metadata,
                  address: e.target.value,
                },
              })
            }
            rows={3}
            className="font-medium pt-2"
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default SupportStaffDrawer;
