import { Menu } from "antd";
import {
  dashboard_icon,
  dashboard_white_icon,
  delegates_icon,
  delegates_white_icon,
  events_icon,
  events_white_icon,
  logout_icon,
  sponsors_icon,
  sponsors_white_icon,
  support_icon,
  support_white_icon,
  tickets_icon,
  tickets_white_icon,
} from "../../assets/icons/icons";
import { NotificationOutlined } from "@ant-design/icons";
import { logo } from "../../assets/images/images";
import { useNavigate } from "react-router-dom";
import { deleteKeyFromStorage } from "../../helpers/helpers";

const SideBar = ({ selected }) => {
  const navigate = useNavigate();
  const onClick = (e) => {
    e.key === "1" && navigate("/dashboard");
    e.key === "2" && navigate("/event");
    e.key === "3" && navigate("/sponsors");
    e.key === "4" && navigate("/delegates");
    e.key === "5" && navigate("/announcements");
    e.key === "6" && navigate("/tickets");
    if (e.key === "7") {
      navigate("/");
      deleteKeyFromStorage("token");
    }
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem(
      <p
        className={`${
          selected === "1" ? "text-themeBlack" : "text-themeGray-dark"
        } font-semibold text-base`}
      >
        Dashboard
      </p>,
      "1",
      <div
        className={`${
          selected === "1" ? "bg-primary" : "bg-themeGray-light"
        } h-8 w-8 rounded-full flex justify-center items-center`}
      >
        <img
          src={selected === "1" ? dashboard_white_icon : dashboard_icon}
          width={15}
          height={15}
          alt=""
        />
      </div>
    ),
    getItem(
      <p
        className={`${
          selected === "2" ? "text-themeBlack" : "text-themeGray-dark"
        } font-semibold text-base`}
      >
        Events
      </p>,
      "2",
      <div
        className={`${
          selected === "2" ? "bg-primary" : "bg-themeGray-light"
        } h-8 w-8 rounded-full flex justify-center items-center`}
      >
        <img
          src={selected === "2" ? events_white_icon : events_icon}
          width={15}
          height={15}
          alt=""
        />
      </div>
    ),
    getItem(
      <p
        className={`${
          selected === "3" ? "text-themeBlack" : "text-themeGray-dark"
        } font-semibold text-base`}
      >
        Sponsors
      </p>,
      "3",
      <div
        className={`${
          selected === "3" ? "bg-primary" : "bg-themeGray-light"
        } h-8 w-8 rounded-full flex justify-center items-center`}
      >
        <img
          src={selected === "3" ? sponsors_white_icon : sponsors_icon}
          width={15}
          height={15}
          alt=""
        />
      </div>
    ),
    getItem(
      <p
        className={`${
          selected === "4" ? "text-themeBlack" : "text-themeGray-dark"
        } font-semibold text-base`}
      >
        Delegates
      </p>,
      "4",
      <div
        className={`${
          selected === "4" ? "bg-primary" : "bg-themeGray-light"
        } h-8 w-8 rounded-full flex justify-center items-center`}
      >
        <img
          src={selected === "4" ? delegates_white_icon : delegates_icon}
          width={15}
          height={15}
          alt=""
        />
      </div>
    ),
    getItem(
      <p
        className={`${
          selected === "5" ? "text-themeBlack" : "text-themeGray-dark"
        } font-semibold text-base`}
      >
        Announcements
      </p>,
      "5",
      <div
        className={`${
          selected === "5" ? "bg-primary" : "bg-themeGray-light"
        } h-8 w-8 rounded-full flex justify-center items-center`}
      >
        <NotificationOutlined
          className={`${selected === "5" ? "text-white" : "text-primary"}`}
        />
      </div>
    ),
    getItem(
      <p
        className={`${
          selected === "6" ? "text-themeBlack" : "text-themeGray-dark"
        } font-semibold text-base`}
      >
        Tickets
      </p>,
      "6",
      <div
        className={`${
          selected === "6" ? "bg-primary" : "bg-themeGray-light"
        } h-8 w-8 rounded-full flex justify-center items-center`}
      >
        <img
          src={selected === "6" ? tickets_white_icon : tickets_icon}
          width={15}
          height={15}
          alt=""
        />
      </div>
    ),

    getItem(
      <p
        className={`${
          selected === "7" ? "text-themeBlack" : "text-themeGray-dark"
        } font-semibold text-base`}
      >
        Log Out
      </p>,
      "7",
      <div
        className={`${
          selected === "7" ? "bg-primary" : "bg-themeGray-light"
        } h-8 w-8 rounded-full flex justify-center items-center`}
      >
        <img src={logout_icon} width={15} height={15} alt="" />
      </div>
    ),
  ];
  return (
    <div>
      <div className="flex justify-center">
        <img src={logo} width={190} height={190} alt="" />
      </div>
      <Menu
        onClick={onClick}
        style={{
          minHeight: "68vh",
        }}
        defaultSelectedKeys={[selected]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
      />
    </div>
  );
};

export default SideBar;
