import { getUserInfo } from "../../helpers/helpers";
import { useGetUsersQuery } from "../../services/auth";
import NavBar from "../navBar/navBar";
import { Alert, Space } from "antd";
import { left_arrow_icon, right_arrow_icon } from "../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import { useRef, forwardRef, useImperativeHandle } from "react";

const SecondContainer = forwardRef(({ children, msg, eventId }, ref) => {
  const { data } = useGetUsersQuery({});
  const navigate = useNavigate();
  const childRef = useRef(null);

  useImperativeHandle(ref, () => ({
    fetchUserData() {
      childRef.current.getUserData();
    },
  }));

  return (
    <div>
      <NavBar ref={childRef} />
      {msg && (
        <Alert
          message={
            <div className="flex justify-between">
              <p className="font-medium">
                Please accept the invites sent by the Sponsors
              </p>
              <img
                src={right_arrow_icon}
                alt=""
                width={15}
                className="cursor-pointer"
                onClick={() =>
                  navigate(`/upcoming-events/details/${eventId}?tab=4`)
                }
              />
            </div>
          }
          type="success"
        />
      )}
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-6">{children}</div>
    </div>
  );
});

export default SecondContainer;
