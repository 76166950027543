import { camera_icon } from "../../assets/icons/icons";

const ImageUpload = ({ image, onChange }) => {
  return (
    <>
      {image ? (
        <span className="relative">
          <div className="text-center pb-10">
            <img
              style={{
                width: "50%",
                height: "auto",
                objectFit: "contain",
                margin: "0 auto",
              }}
              src={image}
              alt={"user image"}
              className="w-full h-32"
            />
          </div>
          <div className="bg-themeGray-light h-9 w-full  flex justify-center items-center absolute bottom-0 right-0 cursor-pointer">
            <label htmlFor="uplode">
              <input
                type="file"
                onChange={(e) => onChange(e)}
                // onDrag={onChange}
                className="hidden"
                name="uplode"
                id="uplode"
                multiple={false}
              />
              <img src={camera_icon} alt="" width={18} />
            </label>
          </div>
        </span>
      ) : (
        <div className="bg-primary h-32 w-full relative">
          <div className="bg-themeGray-light h-9 w-full flex justify-center items-center absolute bottom-0 right-0 cursor-pointer">
            <label htmlFor="upload">
              <input
                type="file"
                onChange={onChange}
                // onDrag={onChange}
                className="hidden"
                name="upload"
                id="upload"
                multiple={false}
              />
              <img src={camera_icon} alt="" width={18} />
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageUpload;
