import { Modal, Input, Spin, message, Col, DatePicker, Row, Empty } from "antd";
import { ThemeButton, ThemeInput, ThemeSelect } from "../components";
import { check_icon, calender_colored_icon } from "../../assets/icons/icons";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const { Search } = Input;

const AddSponserModal = ({
  isModalOpen,
  onCloseSponsorModal,
  addSponserModal,
  onClose,
  sponsors,
  onOpenSponserModal,
  formData,
  setFormData,
  onSubmit,
  isLoading,
  options,
}) => {
  const [filterSponsors, setFilterSponsors] = useState([]);

  useEffect(() => {
    setFilterSponsors([...sponsors]);
  }, [isModalOpen, sponsors]);

  const onSearch = (value) => {
    let filterData = sponsors.filter((sponsor) => {
      return (
        sponsor.first_name.toLowerCase().startsWith(value.toLowerCase()) ||
        sponsor.last_name.toLowerCase().startsWith(value.toLowerCase()) ||
        sponsor.email.toLowerCase().startsWith(value.toLowerCase())
      );
    });
    setFilterSponsors(filterData);
  };

  return (
    <>
      <Modal
        title="Add Sponser"
        footer={false}
        open={isModalOpen}
        onCancel={onCloseSponsorModal}
      >
        <div className="mb-4 mt-3">
          <Search
            size="large"
            placeholder="search delegate"
            onSearch={onSearch}
            enterButton
          />
        </div>
        <div
          className="bg-themeGray-light p-3 rounded-lg overflow-auto"
          style={{ height: 400 }}
        >
          {filterSponsors.length ? (
            filterSponsors.map((data, index) => {
              return (
                <div
                  className="flex justify-between  bg-white px-3 py-2 rounded-lg my-2 cursor-pointer hover:bg-primary"
                  key={index}
                  onClick={() =>
                    data?.added
                      ? message.info("Already added")
                      : onOpenSponserModal(data)
                  }
                >
                  <div>
                    <p className="font-semibold text-base">{`${data?.first_name} ${data?.last_name}`}</p>
                    <p className="text-sm font-medium">{data?.email}</p>
                  </div>
                  {data?.added && (
                    <div
                      className="flex justify-center items-cente bg-white rounded-md px-2"
                      // style={{ height: 60 }}
                    >
                      <img src={check_icon} width={15} alt="" />
                      <p className="flex items-center text-primary ml-1 font-medium">
                        Added
                      </p>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <Empty />
          )}
        </div>
      </Modal>
      <Modal
        footer={false}
        open={addSponserModal}
        onCancel={onClose}
        width={800}
      >
        <Row className="mt-6" gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
          <Col span={24} className="mb-4">
            <ThemeInput
              label={"Email"}
              placeholder={"email"}
              labelPrimary
              name={"email"}
              value={formData?.email}
              disabled={true}
            />
          </Col>
          <Col span={24} className="mb-4">
            <p className="font-semibold mb-1 text-primary">Package Details</p>
            <ThemeSelect
              placeholder={"Sponsorship"}
              value={formData?.metadata?.package_details?.sponsorship}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    package_details: {
                      ...formData.metadata.package_details,
                      sponsorship: value,
                    },
                  },
                })
              }
              options={options?.packageDetails}
              className={"w-full"}
            />
          </Col>
          <Col span={24} className="mb-4">
            <p className="font-semibold mb-1 text-primary">Customize Details</p>
            <Input.TextArea
              placeholder="write your cutomize details here"
              name={"customize_details"}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    package_details: {
                      ...formData.metadata.package_details,
                      customize_details: e.target.value,
                    },
                  },
                })
              }
              value={formData?.metadata?.package_details?.customize_details}
              rows={5}
              className="font-medium pt-2"
            />
          </Col>
          <Col span={12} className="mb-4">
            <ThemeInput
              label={"Total Investment"}
              placeholder={"0"}
              value={formData?.metadata?.package_details?.investment}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    package_details: {
                      ...formData.metadata.package_details,
                      investment: e.target.value,
                    },
                  },
                });
              }}
              name="investment"
              labelPrimary
            />
          </Col>
          <Col span={12} className="mb-4">
            <p className="font-semibold mb-1 text-primary">Due Date</p>
            <DatePicker
              value={
                formData?.metadata?.package_details?.due_date
                  ? dayjs(formData?.metadata?.package_details?.due_date)
                  : ""
              }
              onChange={(e, date) => {
                setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    package_details: {
                      ...formData.metadata.package_details,
                      due_date: date,
                    },
                  },
                });
              }}
              className="w-full h-10 font-medium"
              suffixIcon={<img src={calender_colored_icon} alt="" width={20} />}
            />
          </Col>
          <Col span={12} className="mb-4">
            <ThemeInput
              label={"Website"}
              placeholder={"website"}
              labelPrimary
              name={"website"}
              value={formData?.metadata?.delegate_details?.website}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    delegate_details: {
                      ...formData.metadata.delegate_details,
                      website: e.target.value,
                    },
                  },
                })
              }
            />
          </Col>
          <Col span={12} className="mb-4">
            <ThemeInput
              label={"LinkedIn"}
              placeholder={"linkedIn"}
              name={"linkedIn"}
              value={formData?.metadata?.delegate_details?.linkedIn}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    delegate_details: {
                      ...formData.metadata.delegate_details,
                      linkedIn: e.target.value,
                    },
                  },
                })
              }
              labelPrimary
            />
          </Col>
          <Col span={12} className="mb-4">
            <ThemeInput
              label={"Table Number"}
              placeholder={"table number"}
              labelPrimary
              name={"table_number"}
              value={formData.metadata?.atendee_details?.table_number}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    atendee_details: {
                      ...formData.metadata.atendee_details,
                      table_number: e.target.value,
                    },
                  },
                })
              }
            />
          </Col>
          <Col span={12} className="mb-4">
            <ThemeInput
              type="number"
              label={"No of Allowed Meetings"}
              placeholder={"allowed meetings"}
              name={"allowed_meetings"}
              value={formData.allowed_meetings}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  allowed_meetings: e.target.value,
                })
              }
              labelPrimary
            />
          </Col>
        </Row>
        <div className="flex items-center w-full">
          <div className="flex items-center mx-auto">
            <ThemeButton
              onClick={onClose}
              className={"!border-primary w-48 !h-10 mx-4"}
              content={<p className="text-primary">Close</p>}
              labelPrimary
              bgColor={"!bg-white"}
            />
            <ThemeButton
              content={
                <>
                  {isLoading ? (
                    <Spin className="mt-1" />
                  ) : (
                    <div className="flex items-center">
                      <p>Save</p>
                    </div>
                  )}
                </>
              }
              className={"w-48 !h-10 mx-4"}
              onClick={onSubmit}
              disabled={!formData.allowed_meetings}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddSponserModal;
