import { useEffect, useState } from "react";
import { Modal, Input, Row, Col, Spin, message, Select, Empty } from "antd";
import { ThemeButton, ThemeInput, ThemeSelect } from "../components";
import { check_icon } from "../../assets/icons/icons";

const { Search } = Input;

const AddDelegateModal = ({
  isModalOpen,
  onCloseDelegateModal,
  addDelegateModal,
  onClose,
  delegates,
  onOpenAddDelegateModal,
  formData,
  setFormData,
  onSubmit,
  isLoading,
  options,
}) => {
  const [filterDelegate, setFilterDelegate] = useState([]);

  useEffect(() => {
    setFilterDelegate([...delegates]);
  }, [isModalOpen, delegates]);

  const onSearch = (value) => {
    let filterData = delegates.filter((delegate) => {
      return (
        delegate.first_name.toLowerCase().startsWith(value.toLowerCase()) ||
        delegate.last_name.toLowerCase().startsWith(value.toLowerCase()) ||
        delegate.email.toLowerCase().startsWith(value.toLowerCase())
      );
    });
    setFilterDelegate(filterData);
  };

  const handleExtraDetails = (e) => {
    let value = e.target.value;
    setFormData({
      ...formData,
      metadata: {
        ...formData.metadata,
        extra_details: {
          ...formData?.metadata?.extra_details,
          [e.target.name]: value,
        },
      },
    });
  };

  return (
    <>
      <Modal
        title="Add Delegate"
        footer={false}
        open={isModalOpen}
        onCancel={onCloseDelegateModal}
      >
        <div className="mb-4 mt-3">
          <Search
            size="large"
            placeholder="search delegate"
            onSearch={onSearch}
            enterButton
          />
        </div>
        <div
          className="bg-themeGray-light p-3 rounded-lg overflow-auto"
          style={{ height: 400 }}
        >
          {filterDelegate.length ? (
            filterDelegate.map((data, index) => {
              return (
                <div
                  className="flex justify-between  bg-white px-3 py-2 rounded-lg my-2 cursor-pointer hover:bg-primary"
                  key={index}
                  onClick={() =>
                    data?.added
                      ? message.info("Already added")
                      : onOpenAddDelegateModal(data)
                  }
                >
                  <div>
                    <p className="font-semibold text-base">{`${data?.first_name} ${data?.last_name}`}</p>
                    <p className="text-sm font-medium">{data?.email}</p>
                  </div>
                  {data?.added && (
                    <div
                      className="flex justify-center items-cente bg-white rounded-md px-2"
                      // style={{ height: 60 }}
                    >
                      <img src={check_icon} width={15} alt="" />
                      <p className="flex items-center text-primary ml-1 font-medium">
                        Added
                      </p>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <Empty />
          )}
        </div>
      </Modal>
      <Modal
        footer={false}
        open={addDelegateModal}
        onCancel={onClose}
        width={800}
      >
        <div className="p-7">
          <Row className="w-full" gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
            <Col span={24} className="mb-4">
              <ThemeInput
                label={"Email"}
                placeholder={"email"}
                name={"email"}
                value={formData?.email}
                labelPrimary
                disabled={true}
              />
            </Col>
            <Col span={24} className="mb-4">
              <p className="font-semibold mb-1 text-primary">
                What are your key priorities for the next 6 to 18 months?
              </p>
              <Input.TextArea
                placeholder="write your next priorities"
                name={"priorities"}
                value={formData?.metadata?.extra_details?.priorities}
                onChange={handleExtraDetails}
                rows={5}
                className="font-medium pt-2"
              />
            </Col>
            <Col span={24} className="mb-4">
              <p className="font-semibold mb-1 text-primary">
                Please list any subjects or areas of interest that you would
                like to see on the summit agenda:
              </p>
              <Input.TextArea
                placeholder="write what you want to see in the summit agenda"
                name={"intrest_list"}
                value={formData?.metadata?.extra_details?.intrest_list}
                onChange={handleExtraDetails}
                rows={5}
                className="font-medium pt-2"
              />
            </Col>
            <Col span={24} className="mb-4">
              <p className="font-semibold mb-1 text-primary">
                Industrial Sector
              </p>
              <ThemeSelect
                placeholder={"Industrial Sector"}
                value={formData?.metadata?.extra_details?.indutrial_sector}
                onChange={(value) =>
                  handleExtraDetails({
                    target: { value, name: "indutrial_sector" },
                  })
                }
                options={options.industrialSectors}
                className={"w-full"}
              />
            </Col>
            <Col span={24} className="mb-4">
              <p className="font-semibold mb-1 text-primary">
                Annual Budget
              </p>
              <ThemeSelect
                placeholder={"Personal Budget"}
                value={formData?.metadata?.extra_details?.ap_budget}
                onChange={(value) =>
                  handleExtraDetails({
                    target: { value, name: "ap_budget" },
                  })
                }
                options={options.annualPersonalBudgets}
                className={"w-full"}
              />
            </Col>
            <Col span={24} className="mb-4">
              <p className="font-semibold mb-1 text-primary">
                Scope for responsibility
              </p>
              <Select
                mode="tags"
                style={{ width: "100%", minHeight: 32 }}
                placeholder={"Scope for responsibility"}
                value={
                  formData?.metadata?.extra_details?.scope_for_resposibility ||
                  []
                }
                onChange={(value) => {
                  handleExtraDetails({
                    target: {
                      value: value[value.length - 1],
                      name: "scope_for_resposibility",
                    },
                  });
                }}
                options={options?.scopeOfResponsibilites}
                className={"w-full"}
              />
            </Col>
            <Col span={24} className="mb-4">
              <p className="font-semibold mb-1 text-primary">
                Products & Services you want to purchase in 6-18 months
              </p>
              <Select
                mode="tags"
                allowClear
                style={{ width: "100%", minHeight: 32 }}
                placeholder={"What services you want"}
                value={
                  formData?.metadata?.extra_details?.products_and_services || []
                }
                onChange={(value) =>
                  handleExtraDetails({
                    target: { value, name: "products_and_services" },
                  })
                }
                options={options.productsAndServices}
                className={"w-full"}
              />
            </Col>
          </Row>
          <div className="flex items-center w-full mt-4">
            <div className="flex items-center mx-auto">
              <ThemeButton
                onClick={onClose}
                className={"!border-primary w-48 !h-10 mx-4"}
                content={<p className="text-primary">Close</p>}
                labelPrimary
                bgColor={"!bg-white"}
              />
              <ThemeButton
                content={
                  <>
                    {isLoading ? (
                      <Spin className="mt-1" />
                    ) : (
                      <div className="flex items-center">
                        <p>Save</p>
                      </div>
                    )}
                  </>
                }
                className={"w-48 !h-10 mx-4"}
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddDelegateModal;
