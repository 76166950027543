import { Modal, Row, Col, Card, Statistic, Space } from 'antd'

const ShowStatsModal = ({ isModalOpen, onClose, stats, user }) => {
  const { eventNames, invitations, totalEventsRegistered } = stats || {}
  return (
    <Modal
      width={800}
      title={`${user?.first_name} ${user?.last_name} Stats`}
      footer={null}
      visible={isModalOpen}
      onCancel={onClose}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Card
            title={`Participated in ${totalEventsRegistered} ${
              totalEventsRegistered > 1 ? 'events' : 'event'
            }`}
          >
            <ul>
              {eventNames?.map((eventName, index) => (
                <li className='font-regular my-2' key={index}>{`${index +
                  1}) ${eventName}`}</li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col span={12}>
          {invitations ? (
            <Card title='Invitations'>
              <Statistic title='Total Invitations' value={invitations.length} />
              <Space direction='vertical' style={{ width: '100%' }}>
                <Statistic
                  title='Accepted Invitations'
                  value={
                    invitations?.filter(invite => invite.status === 'accepted')
                      .length
                  }
                />
                <Statistic
                  title='Pending Invitations'
                  value={
                    invitations?.filter(invite => invite.status === 'invited')
                      .length
                  }
                />
              </Space>
            </Card>
          ) : null}
        </Col>
      </Row>
    </Modal>
  )
}

export default ShowStatsModal
