import { useEffect, useState, useRef } from 'react'
import { Row, Col, message, Spin } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { no_agenda } from '../../../assets/images/images'
import options from '../../../lib/data/options.json'
import {
  AgendaModal,
  ThemeButton,
  ThemeDropdown,
  KeyNoteCard,
  NetworkingSessionCard,
  SponsorslotCard,
  BreakCard,
  SponsorsPresentationCard,
  PanelDiscutionCard
} from '../../../components/components'
import { useLazyGetUsersQuery } from '../../../services/auth'
import { getUserInfo, showDeletePopup } from '../../../helpers/helpers'
import {
  useAddScheduleMutation,
  usePutScheduleMutation,
  useDeleteScheduleMutation,
  useLazyGetSchedulesQuery
} from '../../../services/schedule'
import {
  useAddMeetingsMutation,
  usePutMultipleMeetingsMutation,
  useDeleteMultipleMeetingsMutation
} from '../../../services/meetings'
import { useAddAgendaMutation } from '../../../services/agenda'
import { savePDF, PDFExport } from '@progress/kendo-react-pdf'
import { useLazyGetAttendeesQuery } from '../../../services/attendees'

const EventAgenda = ({
  eventID,
  isAdmin,
  isAddAgenda,
  getUserAgenda,
  eventName,
  currentPage,
  resultPerPage,
  type
}) => {
  const pdfExportComponent = useRef(null)
  const [getSchedules, { isLoading }] = useLazyGetSchedulesQuery()
  const [getUsers, { isLoading: fetchUsers }] = useLazyGetAttendeesQuery()
  const [addSchedule, { isLoading: addingSchedule }] = useAddScheduleMutation()
  const [updateSchedule, { isLoading: updating }] = usePutScheduleMutation()
  const [deleteSchedule, { isLoading: deleting }] = useDeleteScheduleMutation()
  const [addMeetings, { isLoading: addingMeetings }] = useAddMeetingsMutation()
  const [updateMeetings, { isLoading: updatingMeetings }] =
    usePutMultipleMeetingsMutation()
  const [deleteMultipleMeetings, { isLoading: deletingMeetings }] =
    useDeleteMultipleMeetingsMutation()
  const [addUserAgenda, { isLoading: addingAgendas }] = useAddAgendaMutation()
  const [mode, setMode] = useState('create')
  const [loading, setLoading] = useState(false)
  const [editID, setEditID] = useState('')
  const [agendas, setAgendas] = useState([])
  const [agendaModal, setAgendaModal] = useState(false)
  const [speakers, setSpeakers] = useState([])
  const [day, setDay] = useState('Day 1')
  const [formData, setFormData] = useState({
    title: '',
    short_title: '',
    description: [],
    start_time: '',
    end_time: '',
    event: eventID,
    hall: null,
    day: null,
    type: null,
    speaker: null,
    image: ''
  })
  const [meetings, setMeetings] = useState([])
  const [deletedMeetings, setDeletedMeetings] = useState([])
  const [hidePDFData, setHidePDFData] = useState(false)

  useEffect(() => {
    getAgendas()
  }, [day, currentPage])

  useEffect(() => {
    getSpeakers()
  }, [])

  // const getAgendas = async () => {
  //   let hallA = [];
  //   let hallB = [];
  //   const response = await getSchedules({
  //     currentPage: currentPage || 1,
  //     resultPerPage: resultPerPage || 1000,
  //     eventID: eventID,
  //     day,
  //     type: type || "",
  //   });
  //   const data = response?.data?.schedules?.data || [];
  //   for (var i = 0; i < data.length; i++) {
  //     if (data[i].hall === "Hall A") {
  //       hallA.push(data[i]);
  //     } else if (data[i].hall === "Hall B") {
  //       hallB.push(data[i]);
  //     } else {
  //       hallA.push(data[i]);
  //       hallB.push(data[i]);
  //     }
  //   }
  //   setAgendas([
  //     hallA.sort(
  //       (a, b) =>
  //         new Date("1970/01/01 " + a.start_time) -
  //         new Date("1970/01/01 " + b.start_time)
  //     ),
  //     hallB.sort(
  //       (a, b) =>
  //         new Date("1970/01/01 " + a.start_time) -
  //         new Date("1970/01/01 " + b.start_time)
  //     ),
  //   ]);
  // };

  const getAgendas = async () => {
    let hallA = []
    let hallB = []
    const response = await getSchedules({
      currentPage: currentPage || 1,
      resultPerPage: resultPerPage || 1000,
      eventID: eventID,
      day,
      type: type || ''
    })
    const data = response?.data?.schedules?.data || []

    for (var i = 0; i < data.length; i++) {
      if (data[i].hall === 'Hall A') {
        hallA.push(data[i])
      } else if (data[i].hall === 'Hall B') {
        hallB.push(data[i])
      } else {
        hallA.push(data[i])
        hallB.push(data[i])
      }
    }

    // Add missing types to the opposite hall except for specific types
    const specificTypes = [
      'coffee-break',
      'lunch-break',
      'cocktail-break',
      'breakfast',
      'registration'
    ]

    data.forEach(item => {
      if (!specificTypes.includes(item.type)) {
        if (item.hall === 'Hall A' && !hallB.some(i => i.type === item.type)) {
          hallB.push({ ...item, hall: 'Hall B' })
        } else if (
          item.hall === 'Hall B' &&
          !hallA.some(i => i.type === item.type)
        ) {
          hallA.push({ ...item, hall: 'Hall A' })
        }
      }
    })

    // Sort agendas by start time
    hallA.sort(
      (a, b) =>
        new Date('1970/01/01 ' + a.start_time) -
        new Date('1970/01/01 ' + b.start_time)
    )

    hallB.sort(
      (a, b) =>
        new Date('1970/01/01 ' + a.start_time) -
        new Date('1970/01/01 ' + b.start_time)
    )

    hallA.forEach((halAData, ind) => {
      // console.log('Hall A Start Time-->', halAData.start_time)
      // console.log('Hall B Start Time-->', hallB[ind]?.start_time)
      if (hallB[ind] && halAData.start_time !== hallB[ind]?.start_time) {
        hallB.splice(ind, 0, {
          type: 'empty',
          start_time: halAData.start_time,
          end_time: halAData.end_time,
          title: 'Need to add Data'
        })
      }
    })

    console.log('hallA-->', hallA)
    console.log('hallB-->', hallB)

    setAgendas([hallA, hallB])
  }

  const getSpeakers = async () => {
    let speakersData = []
    const response = await getUsers({
      eventID: eventID
    })
    response?.data?.attendees?.data?.map(speaker => {
      speakersData.push({
        value: speaker?.user?._id,
        label: `${speaker?.user?.first_name} ${speaker?.user?.last_name}`
      })
    })

    setSpeakers(speakersData)
  }

  const onClose = () => {
    setMode('create')
    setAgendaModal(false)
    setFormData({
      title: '',
      short_title: null,
      description: '',
      start_time: null,
      end_time: null,
      event: eventID,
      hall: null,
      day: null,
      type: null,
      speaker: null,
      image: ''
    })
    setMeetings([])
    setDeletedMeetings([])
  }

  const onSubmit = async () => {
    setLoading(true)
    let data = {
      ...formData
    }
    let meetingData = { schedule: '', meetings: [] }
    let response
    if (mode === 'create') {
      response = await addSchedule(data)
    } else {
      response = await updateSchedule({ id: editID, body: data })
    }
    if (response?.data?.status === 200) {
      if (formData.type === 'networking') {
        let meetingResponse
        if (mode === 'create') {
          meetings.map(meeting => {
            meetingData.meetings.push({
              ...meeting,
              day: formData.day,
              schedule: response?.data?.schedule?._id
            })
          })
          meetingData.schedule = response?.data?.schedule?._id
          meetingResponse = await addMeetings(meetingData)
        } else {
          meetings.map(meeting => {
            if (!meeting?._id) {
              meetingData.meetings.push({
                ...meeting,
                schedule: editID
              })
            }
          })
          meetingData.schedule = editID
          meetingResponse = await updateMeetings({ meetings: meetings })
          let addMeetingResponse
          addMeetingResponse =
            meetingData.meetings.length > 0 && (await addMeetings(meetingData))
          let deleteMeetingResponse
          deleteMeetingResponse =
            deletedMeetings.length > 0 &&
            (await deleteMultipleMeetings({ meetings: deletedMeetings }))
        }
        if (meetingResponse?.data?.status === 200) {
          setLoading(false)
          message.success(
            `${mode === 'create' ? 'Added' : 'Updated'} Successfully.`
          )
          onClose()
        } else {
          setLoading(false)
          message.error('Please fill form correctlly.')
        }
      } else {
        setLoading(false)
        message.success(
          `${mode === 'create' ? 'Added' : 'Updated'} Successfully.`
        )
        onClose()
      }
    } else {
      setLoading(false)
      message.error(
        response?.error?.data?.message || 'Please fill form correctlly.'
      )
    }
    getAgendas()
  }

  const addAgenda = async data => {
    try {
      const agenda = {
        event: data.event,
        user: getUserInfo().id,
        schedule: data._id
      }
      const response = await addUserAgenda(agenda)
      if (response?.data?.status === 200) {
        message.success('Agenda added successfully.')
      } else {
        message.error(response?.error?.data?.message || 'Something went wrong')
      }
      getUserAgenda()
    } catch (err) {
      console.log(err)
    }
  }

  const onEdit = data => {
    let panellist = []
    data?.panellist?.forEach(user => {
      panellist.push(user?._id)
    })
    setEditID(data?._id)
    setFormData({
      title: data?.title,
      short_title: data?.short_title,
      description: data?.description || [],
      start_time: data?.start_time,
      end_time: data?.end_time,
      event: data?.event,
      hall: data?.hall,
      day: data?.day,
      type: data?.type,
      image: data?.image,
      speaker: data?.speaker?._id,
      moderator: data?.moderator?._id,
      panellist: panellist
    })
    setMeetings(data?.meetings || [])
    setMode('edit')
    setAgendaModal(true)
  }

  const onDelete = async data => {
    if (data?.type === 'networking') {
      let meetingData = { meetings: [] }
      data?.meetings?.map(meeting => {
        meetingData.meetings.push(meeting._id)
      })
      const meetingResponse = await deleteMultipleMeetings(meetingData)
    }
    const response = await deleteSchedule(data?._id)
    if (response?.data?.status === 200) {
      message.success('Agenda Deleted Successfully.')
      getAgendas()
    } else {
      message.error('Something went wrong')
    }
  }

  const handleExportWithComponent = event => {
    setHidePDFData(true)
    pdfExportComponent.current.save()
    setTimeout(() => {
      setHidePDFData(false)
    }, 1000)
  }

  // useEffect(() => {
  //   console.log('agendas==>', agendas[0])
  // }, [agendas])

  
  console.log('agendas++++==>', agendas)

  return (
    <div>
      <AgendaModal
        isModalOpen={agendaModal}
        onClose={onClose}
        onSubmit={onSubmit}
        formData={formData}
        setFormData={e => setFormData(e)}
        meetings={meetings}
        setMeetings={e => setMeetings(e)}
        deletedMeetings={deletedMeetings}
        setDeletedMeetings={e => setDeletedMeetings(e)}
        isLoading={loading}
        eventID={eventID}
        speakers={speakers}
        headings={options.agendaHeadings}
        days={options.days}
        halls={options.halls}
        activityToBeDoneList={options.activityToBeDoneList}
        mode={mode}
      />
      <div className='flex items-center justify-between'>
        {/* {isAdmin && ( */}
        <ThemeDropdown
          setValue={e => setDay(e)}
          text={day}
          items={options.days}
        />
        {/* )} */}
        <div className='flex-1 py-1 px-4 mr-2 ml-2 rounded-full bg-[#fff] text-center'>
          <h1 className='text-primary text-[22px] font-bold'>{eventName}</h1>
        </div>
        <div className='flex'>
          <ThemeButton
            className='hidden'
            content={'Download'}
            onClick={handleExportWithComponent}
          />
          {isAdmin && (
            <ThemeButton
              content={'Add Agenda'}
              className={'ml-3'}
              onClick={() => setAgendaModal(true)}
            />
          )}
        </div>
      </div>
      <div className='bg-white rounded-xl p-6 mt-6'>
        {isLoading ? (
          <div
            className='flex justify-center items-center'
            style={{ height: '75vh' }}
          >
            <Spin size='large' />
          </div>
        ) : (
          <>
            {agendas.length ? (
              <PDFExport ref={pdfExportComponent} paperSize='auto'>
                <div>
                  <div className='flex justify-between bg-primary py-4 px-10 mt-4'>
                    {!hidePDFData && (
                      <p className='text-white font-extrabold text-xl md:text-5xl text-center'>
                        HALL A
                      </p>
                    )}
                    <p className='text-white font-extrabold text-xl md:text-5xl text-center'>
                      {day}
                    </p>
                    <p className='text-white font-extrabold text-xl md:text-5xl text-center'>
                      HALL B
                    </p>
                  </div>
                  <div className='flex mt-6'>
                    <div className='flex-1 mt-7 lg:mt-0 mb-5 mr-4'>
                      {agendas[0].map((v, i) => {
                        if (v.type == 'keynote') {
                          return (
                            <div className='flex'>
                              <div key={i} className='flex-1 mt-7 lg:mt-0 mx-2 mb-5'>
                                <KeyNoteCard
                                  isAdmin={isAdmin}
                                  keynote={v}
                                  onEdit={onEdit}
                                  onDelete={onDelete}
                                />
                                {isAddAgenda && (
                                  <ThemeButton
                                    onClick={() => addAgenda(v)}
                                    content={
                                      <div>
                                        <PlusCircleOutlined className='mr-4' />
                                        <span>Add to agenda</span>
                                      </div>
                                    }
                                    className='w-full text-lg !h-12  mt-4'
                                  />
                                )}
                              </div>

                              {agendas[1][i] &&
                              agendas[1][i].type === 'keynote' ? (
                                <div key={i} className='flex-1 mt-7 mx-2 lg:mt-0 mb-5'>
                                  <KeyNoteCard
                                    isAdmin={isAdmin}
                                    keynote={agendas[1][i]}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                  />
                                  {isAddAgenda && (
                                    <ThemeButton
                                      onClick={() => addAgenda(v)}
                                      content={
                                        <div>
                                          <PlusCircleOutlined className='mr-4' />
                                          <span>Add to agenda</span>
                                        </div>
                                      }
                                      className='w-full text-lg !h-12 mt-4'
                                    />
                                  )}
                                </div>
                              ) : null}
                            </div>
                          )
                        } else if (v.type == 'panel-discussion') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <PanelDiscutionCard
                                isAdmin={isAdmin}
                                panelDetails={v}
                                onEdit={onEdit}
                                hall={'Hall A'}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className='mr-4' />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className='w-full text-lg !h-12 mt-4'
                                />
                              )}
                            </div>
                          )
                        } else if (v.type == 'sponsor-slot') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <SponsorslotCard
                                isAdmin={isAdmin}
                                sponsorDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className='mr-4' />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className='w-full text-lg !h-12 mt-4'
                                />
                              )}
                            </div>
                          )
                        } else if (v.type == 'sponsor-presentation') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <SponsorsPresentationCard
                                hall='Hall A'
                                isAdmin={isAdmin}
                                sponsorDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className='mr-4' />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className='w-full text-lg !h-12 mt-4'
                                />
                              )}
                            </div>
                          )
                        } else if (
                          v.type == 'coffe-break' ||
                          v.type == 'lunch-break' ||
                          v.type == 'cocktail-break' ||
                          v.type == 'breakfast' ||
                          v.type == 'registration'
                        ) {
                          return (
                            <div className='flex w-100'>
                              <div
                                key={i}
                                className='flex-1 mt-7 lg:mt-0 mx-2 mb-5'
                              >
                                <BreakCard
                                  isAdmin={isAdmin}
                                  breakDetail={v}
                                  onEdit={onEdit}
                                  onDelete={onDelete}
                                />
                              </div>

                              {(agendas[1][i] &&
                                agendas[1][i].type === 'coffe-break') ||
                              agendas[1][i].type == 'lunch-break' ||
                              agendas[1][i].type == 'cocktail-break' ||
                              agendas[1][i].type == 'breakfast' ||
                              agendas[1][i].type == 'registration' ? (
                                <div
                                  key={i}
                                  className='flex-1 mt-7 lg:mt-0 mx-2 mb-5'
                                >
                                  <BreakCard
                                    isAdmin={isAdmin}
                                    breakDetail={agendas[1][i]}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                  />
                                </div>
                              ) : null}
                            </div>
                          )
                        } else if (v.type == 'networking') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <NetworkingSessionCard
                                isAdmin={isAdmin}
                                networkingDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                            </div>
                          )
                        }
                      })}
                    </div>
                    {/* <div className='flex-1 mt-7 lg:mt-0 mb-5'>
                      {agendas[1].map((v, i) => {
                        if (v.type == 'keynote') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <KeyNoteCard
                                isAdmin={isAdmin}
                                keynote={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className='mr-4' />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className='w-full text-lg !h-12 mt-4'
                                />
                              )}
                            </div>
                          )
                        } else if (v.type == 'panel-discussion') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <PanelDiscutionCard
                                isAdmin={isAdmin}
                                panelDetails={v}
                                onEdit={onEdit}
                                hall={'Hall B'}
                                onDelete={onDelete}
                              />
                            </div>
                          )
                        } else if (v.type == 'empty') {
                          return (
                            <div className='relative'>
                              <div className='bg-white rounded-bl-lg rounded-br-lg p-4 shadow-md shadow-gray-300 flex justify-center items-center'>
                              </div>
                            </div>
                          )
                        } else if (v.type == 'sponsor-slot') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <SponsorslotCard
                                isAdmin={isAdmin}
                                sponsorDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className='mr-4' />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className='w-full text-lg !h-12 mt-4'
                                />
                              )}
                            </div>
                          )
                        } else if (v.type == 'sponsor-presentation') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <SponsorsPresentationCard
                                hall='Hall B'
                                isAdmin={isAdmin}
                                sponsorDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className='mr-4' />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className='w-full text-lg !h-12 mt-4'
                                />
                              )}
                            </div>
                          )
                        } else if (
                          v.type == 'coffe-break' ||
                          v.type == 'lunch-break' ||
                          v.type == 'cocktail-break' ||
                          v.type == 'breakfast' ||
                          v.type == 'registration'
                        ) {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <BreakCard
                                isAdmin={isAdmin}
                                breakDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                            </div>
                          )
                        } else if (v.type == 'networking') {
                          return (
                            <div key={i} className='mt-7 lg:mt-0 mb-5'>
                              <NetworkingSessionCard
                                isAdmin={isAdmin}
                                networkingDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                            </div>
                          )
                        }
                      })}
                    </div> */}
                  </div>
                </div>
              </PDFExport>
            ) : (
              <div
                className='bg-white rounded-lg mt-4 flex justify-center items-center flex-col'
                style={{ height: 450 }}
              >
                <img src={no_agenda} alt='' width={450} />
                <p className='font-bold text-4xl mt-3'>
                  No <span className='text-primary'>Event Agenda</span>
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default EventAgenda
