import { Row, Col } from "antd";
import { edit_white_icon, delete_white_icon } from "../../assets/icons/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { user } from "../../assets/images/images";

const NetworkingSessionCard = ({
  networkingDetail,
  onEdit,
  onDelete,
  isAdmin,
}) => {
  return (
    <div className="relative">
      <div className="bg-primary flex items-center justify-center py-2 static">
        <div className="flex text-white flex-col items-center">
          <p className="text-xl md:text-3xl font-bold">
            {networkingDetail?.title}
          </p>
          <p className="md:text-base font-semibold mt-1">
            {networkingDetail?.start_time} - {networkingDetail?.end_time}
          </p>
          <p className="md:text-base font-semibold mt-1">(Exibition Hall)</p>
        </div>
        {isAdmin && (
          <div className="flex absolute right-10">
            <span
              className="cursor-pointer w-8"
              onClick={() => onEdit(networkingDetail)}
            >
              <img src={edit_white_icon} alt="" width={18} height={18} />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => showDeletePopup(() => onDelete(networkingDetail))}
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
      </div>
      <div
        className="bg-white p-4 shadow-md shadow-gray-300 flex flex-col justify-around"
        style={{ minHeight: 234 }}
      >
        <p className="text-primary text-lg md:text-2xl font-bold">
          Networking Sessions
        </p>
        {networkingDetail.meetings.map((v, i) => {
          return (
            <Row key={i} className="flex items-center">
              <Col xl={5} lg={7} md={5} sm={7} xs={7}>
                <p className="md:text-lg font-semibold">{v.title} </p>
                <span className="text-[12px]">(Pre Arrange Meeting Area)</span>
              </Col>
              <Col xl={13} lg={9} md={13} sm={9} xs={6}>
                <div
                  style={{
                    border: "1px dashed rgba(0,0,0,.8)",
                    borderBottomWidth: 1,
                  }}
                ></div>
              </Col>
              <Col xl={6} lg={8} md={6} sm={8} xs={11}>
                <div className="flex flex-col items-center">
                  {v?.meeting_user && (
                    <div className="flex items-center mb-2">
                      <img
                        src={v?.meeting_user?.picture || user}
                        alt="user"
                        className="w-8 h-8 rounded-full object-contain"
                      />
                      <p className="text-xs font-medium ml-2">
                        {v?.meeting_user?.full_name}
                      </p>
                    </div>
                  )}
                  <p className="text-xs font-medium text-end">
                    {v.start_time} | {v.end_time}
                  </p>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

export default NetworkingSessionCard;
