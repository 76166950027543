import { left_arrow_icon } from "../../assets/icons/icons";
import {
  Container,
  ThemeButton,
  ThemeTable,
} from "../../components/components";
import { banner } from "../../assets/images/images";
import { NavLink, useNavigate, useParams } from "react-router-dom";

const SponsorsDetails = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Banner",
      dataIndex: "banner",
      key: "banner",
      render: () => (
        <div className="flex justify-center">
          <img src={banner} alt="" width={45} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Start Date",
      dataIndex: "startdate",
      key: "startdate",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "In Action",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <div className="flex justify-center">
          {text === "notfinished" ? (
            <>
              <ThemeButton
                content={"See Agenda"}
                onClick={() => navigate(`/${category}/details/agenda`)}
              />
              <ThemeButton
                content={"See Invites"}
                className={"ml-3"}
                onClick={() => navigate(`/${category}/details/agenda`)}
              />
            </>
          ) : (
            text === "finished" && (
              <div className="bg-primary h-9 rounded-md px-4 flex items-center">
                <p className="font-semibold text-white">Event Finished</p>
              </div>
            )
          )}
        </div>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      no: "1",
      name: "Strategic Summit 2023",
      startdate: "Thur, Dec 19",
      status: "notfinished",
    },
    {
      key: "2",
      no: "2",
      name: "Strategic Summit 2023",
      startdate: "Thur, Dec 19",
      status: "finished",
    },
  ];

  return (
    <Container
      selected={category === "sponsors" ? "3" : category === "delegates" && "4"}
      header={
        <div className="flex items-center">
          <NavLink to={`/${category}`}>
            <img src={left_arrow_icon} width={18} height={18} alt="" />
          </NavLink>
          <p className="ml-3 font-semibold text-2xl">
            {category.charAt(0).toUpperCase()}
            {category.slice(1)}
          </p>
        </div>
      }
    >
      <div className="mb-4">
        <p className="font-bold text-3xl">Hamza Raza</p>
        <p className="text-2xl font-medium mt-2">Event Participated</p>
      </div>
      <ThemeTable columns={columns} data={data} />
    </Container>
  );
};

export default SponsorsDetails;
