import React, { useEffect, useState, useRef } from "react";
import {
  Layout,
  Menu,
  theme,
  Avatar,
  List,
  Skeleton,
  Badge,
  Empty,
} from "antd";
import { user } from "../../assets/images/images";
import { useSearchParams, NavLink, useNavigate } from "react-router-dom";
import { useGetChatQuery } from "../../services/chat";
import { useGetMessageQuery } from "../../services/message";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const { Header, Content, Footer, Sider } = Layout;

const UserChats = ({ userID, eventID }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [messageLimit, setMessageLimit] = useState(10);
  const {
    data: chats,
    error,
    isLoading: chatsLoading,
    refetch: refetchChats,
  } = useGetChatQuery({
    user: userID,
    event: eventID,
  });
  const {
    data: messages,
    error: messagesError,
    isLoading: messagesLoading,
    refetch: refetchMessages,
  } = useGetMessageQuery({
    result_per_page: messageLimit,
    sender: userID,
    receiver: searchParams.get("user"),
  });

  const [chatUsers, setChatUsers] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const chatBox = useRef(null);

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  useEffect(() => {
    const messagesList = messages?.messages?.data || [];
    console.log("messagesList->", messagesList);
    if (searchParams.get("user")) {
      setChatMessages(messagesList);
    }
  }, [messagesLoading, messages]);

  useEffect(() => {
    const chatList = chats?.chats?.data || [];
    console.log("chatList", chatList);
    setChatUsers(
      chatList
        .filter(
          (v) =>
            v.last_message ||
            v.receiver._id === searchParams.get("user") ||
            v.sender._id === searchParams.get("user")
        )
        .map((v) => {
          const receiver = userID == v?.receiver?._id;
          return getItem(
            <div className="flex justify-between items-end">
              <div>
                <p className={`font-semibold text-base`}>
                  {v[receiver ? "sender" : "receiver"]?.first_name}{" "}
                  {v[receiver ? "sender" : "receiver"]?.last_name}
                </p>
                <p className={`font-semibold text-xs`}>{v.last_message}</p>
              </div>
            </div>,
            receiver ? v?.sender?._id : v?.receiver?._id,
            <img
              className="w-[50px] h-[50px] rounded-full object-cover"
              src={v[receiver ? "sender" : "receiver"]?.picture || user}
              width={50}
              height={50}
              alt=""
            />
          );
        })
    );
    // const chatId =
    //   userID < searchParams.get("user")
    //     ? `${userID}${searchParams.get("user")}`
    //     : `${searchParams.get("user")}${userID}`;
    // const index = chatList.findIndex((v) => v.chat_id === chatId);
    // if (index === -1 && !chatsLoading && searchParams.get("user")) {
    //   chatAdd();
    // }
    // console.log("chatId->", chatId);
    // console.log("searchParams.get user", searchParams.get("user"));
  }, [chats, chatsLoading]);

  const loadMoreMessages = () => {
    setMessageLimit(messageLimit + 10);
    // refetchMessages()
  };

  return (
    <div>
      {chatUsers.length ? (
        <Layout hasSider>
          <Sider
            width={300}
            style={{
              height: "60vh",
              overflow: "scroll",
              background: "#fff",
            }}
          >
            <Menu
              onClick={(e) => {
                setSearchParams({
                  user: e.key,
                });
                refetchMessages();
              }}
              mode="inline"
              defaultSelectedKeys={[searchParams.get("user")]}
              items={chatUsers}
            />
          </Sider>
          {searchParams.get("user") ? (
            <Layout
              className="site-layout"
              style={{
                background: "#fff",
                padding: "10px 30px 30px 30px",
              }}
            >
              <Content
                onLoad={() =>
                  chatBox.current.scroll({ top: chatBox.current.scrollHeight })
                }
                ref={chatBox}
                style={{
                  height: "50vh",
                  overflow: "scroll",
                  position: "relative",
                }}
              >
                {messages?.messages?.total !==
                  messages?.messages?.data?.length &&
                messages?.messages?.data?.length ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      textAlign: "center",
                      top: 10,
                      zIndex: 10,
                    }}
                  >
                    <span
                      onClick={loadMoreMessages}
                      style={{
                        background: "#e2b025",
                        color: "#fff",
                        borderRadius: 100,
                        padding: "5px 20px 5px 20px",
                        cursor: "pointer",
                      }}
                    >
                      Load more messages
                    </span>
                  </div>
                ) : null}
                <List
                  itemLayout="horizontal"
                  dataSource={[...chatMessages].reverse()}
                  renderItem={(item, index) =>
                    messagesLoading ? (
                      <List.Item>
                        <div className="bg-white rounded-xl p-5 mb-8">
                          <Skeleton />
                        </div>
                      </List.Item>
                    ) : (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar src={item?.sender?.picture || user} />
                          }
                          title={
                            <span className="font-bold">
                              {item?.sender?.first_name}{" "}
                              {item?.sender?.last_name}
                            </span>
                          }
                          description={item.message}
                        />
                        <div className="text-[12px]">
                          {dayjs(new Date(item.created_at)).fromNow()}
                        </div>
                      </List.Item>
                    )
                  }
                />
              </Content>
            </Layout>
          ) : null}
        </Layout>
      ) : (
        <div className="p-10">
          <Empty description="No chats available" />
        </div>
      )}
    </div>
  );
};

export default UserChats;
