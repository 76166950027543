import { Row, Col } from 'antd'
import { useEffect, useState } from 'react'
import {
  KeyNoteCard,
  BreakCard,
  NetworkingSessionCard,
  SponsorslotCard,
  SponsorsPresentationCard,
  PanelDiscutionCard
} from '../../../../components/components'
import { useSearchParams } from 'react-router-dom'
import { getUserInfo } from '../../../../helpers/helpers'

const EventAgenda = ({
  schedules,
  deleteAgenda,
  day,
  onAddAgenda,
  agenda,
  isAdmin,
  invitations
}) => {
  const [agendas, setAgendas] = useState([])
  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    let list = []
    const data = schedules
    console.log('data', data)
    for (var i = 0; i < data?.length; i++) {
      if (data[i]?.type === 'networking') {
        let meetingsData = data[i]
        meetingsData?.meetings?.map((meeting, index) => {
          let isInviteAvailable = invitations?.find(invitation => {
            if (
              meeting?.day == invitation?.meeting?.day &&
              meeting?.start_time == invitation?.meeting?.start_time &&
              meeting?.end_time == invitation?.meeting?.end_time
            ) {
              return invitation
            }
          })
          if (isInviteAvailable) {
            let meetingsList = [...meetingsData.meetings]

            isInviteAvailable?.receiver?._id !== getUserInfo().id
              ? (meetingsList[index] = {
                  ...meetingsList[index],
                  meeting_user: {
                    picture: isInviteAvailable?.receiver?.picture,
                    full_name:
                      isInviteAvailable?.receiver?.first_name +
                      ' ' +
                      isInviteAvailable?.receiver?.last_name
                  }
                })
              : (meetingsList[index] = {
                  ...meetingsList[index],
                  meeting_user: {
                    picture: isInviteAvailable?.sender?.picture,
                    full_name:
                      isInviteAvailable?.sender?.first_name +
                      ' ' +
                      isInviteAvailable?.sender?.last_name
                  }
                })
            meetingsData = {
              ...meetingsData,
              meetings: meetingsList
            }
          }
        })
        list.push(meetingsData)
      } else {
        list.push(data[i])
      }
    }

    list.sort(
      (a, b) =>
        new Date('1970/01/01 ' + a.start_time) -
        new Date('1970/01/01 ' + b.start_time)
    )

    setAgendas(list)
  }, [schedules])

  const Agenda = ({ list }) => {
    return (
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }} className='mt-6'>
        {list &&
          list.length &&
          list.map((v, i) => {
            if (v?.day == day) {
              if (v.type == 'keynote') {
                return (
                  <Col span={24} key={i} className='mt-7 lg:mt-0 mb-5'>
                    <KeyNoteCard
                      onDelete={() => deleteAgenda(v)}
                      isUser={!isAdmin && true}
                      isAdmin={isAdmin}
                      keynote={v}
                      isAdded={
                        isAdmin
                          ? 1
                          : agenda?.length
                          ? agenda.findIndex(item => v._id === item?._id)
                          : -1
                      }
                      onAddAgenda={e => onAddAgenda(e)}
                    />
                  </Col>
                )
              } else if (v.type == 'panel-discussion') {
                return (
                  <Col span={24} key={i} className='mt-7 lg:mt-0 mb-5'>
                    <PanelDiscutionCard
                      onDelete={() => deleteAgenda(v)}
                      isUser={!isAdmin && true}
                      isAdmin={isAdmin}
                      panelDetails={v}
                    />
                  </Col>
                )
              } else if (
                v.type == 'coffe-break' ||
                v.type == 'lunch-break' ||
                v.type == 'cocktail-break' ||
                v.type == 'breakfast' ||
                v.type == 'registration'
              ) {
                return (
                  <Col span={24} key={i} className='mt-7 lg:mt-0 mb-5'>
                    <BreakCard
                      isUser={!isAdmin && true}
                      isAdmin={isAdmin}
                      breakDetail={v}
                    />
                  </Col>
                )
              } else if (v.type == 'networking') {
                return (
                  <Col span={24} key={i} className='mt-7 lg:mt-0 mb-5'>
                    <NetworkingSessionCard
                      isUser={!isAdmin && true}
                      networkingDetail={v}
                    />
                  </Col>
                )
              } else if (v.type == 'sponsor-presentation') {
                return (
                  <Col span={24} key={i} className='mt-7 lg:mt-0 mb-5'>
                    <SponsorsPresentationCard
                      onDelete={() => deleteAgenda(v)}
                      isUser={!isAdmin && true}
                      isAdmin={isAdmin}
                      sponsorDetail={v}
                      isAdded={
                        isAdmin
                          ? 1
                          : agenda?.length
                          ? agenda.findIndex(item => v._id === item?._id)
                          : -1
                      }
                      onAddAgenda={e => onAddAgenda(e)}
                    />
                  </Col>
                )
              } else if (v.type == 'sponsor-slot') {
                return (
                  <Col span={24} key={i} className='mt-7 lg:mt-0 mb-5'>
                    <SponsorslotCard
                      onDelete={() => deleteAgenda(v)}
                      isUser={!isAdmin && true}
                      isAdmin={isAdmin}
                      sponsorDetail={v}
                      isAdded={
                        isAdmin
                          ? 1
                          : agenda.length > 0
                          ? agenda.findIndex(item => v._id === item?._id)
                          : -1
                      }
                      onAddAgenda={e => onAddAgenda(e)}
                    />
                  </Col>
                )
              }
            }
          })}
      </Row>
    )
  }

  return (
    <div className='bg-white p-6'>
      <div className='container mx-auto lg:px-32 md:px-16 px-0'>
        {agendas?.length && (
          <>
            <div className='flex justify-center bg-primary py-4 px-4'>
              <p className='text-white font-extrabold text-xl md:text-5xl text-center'>
                {day}
              </p>
            </div>
            <div className='flex'>
              <div className='flex-1'>
                <Agenda list={agendas} index={0} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default EventAgenda
