import { useEffect, useState } from 'react'
import { Tabs, Row, Col, Modal } from 'antd'
import {
  Container,
  ThemeButton,
  ThemeTable,
  ThemeDropdown,
  UserChats,
  ThemeInput
} from '../../components/components'
import {
  NavLink,
  useParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { left_arrow_icon, comment_icon } from '../../assets/icons/icons'
import { no_agenda } from '../../assets/images/images'
import EventAgenda from '../upcomingEvents/details/eventAgenda/eventAgenda'
import { useGetUsersQuery } from '../../services/auth'
import { useLazyGetAgendaQuery } from '../../services/agenda'
import {
  useGetInvitationQuery,
  usePutInvitationMutation
} from '../../services/invitation'
import { MessageOutlined, EditOutlined } from '@ant-design/icons'
import { getUserInfo } from '../../helpers/helpers'
import options from '../../lib/data/options.json'
import dayjs from 'dayjs'

const chatList = [
  {
    _id: '645e2be2ca1630e07bfeebe3',
    chat_id: '643e6ab7c0d2109acd3e81fa643e77a5c0d2109acd3e822e',
    sender: {
      _id: '643e6ab7c0d2109acd3e81fa',
      first_name: 'Aude ',
      last_name: 'Chevalier',
      email: 'aude.chevalier@ompharma.com',
      password: 'Aude022',
      gender: 'female',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681812130/jd8ipzgdh5mzzrd5xr3c.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'OM Pharma',
          position: 'Clinical Operations Head',
          country: 'Switzerland',
          post_code: '',
          fax: '',
          phone: '+41 22 783 15 25/+41 79 412 40 22',
          mobile: '',
          address: '',
          linkedIn: 'linkedin.com/in/aude-chevalier-9aa9803a',
          website: 'https://www.ompharma.com/home'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: 'Develop knowledge and expertise in ePRO management\n',
          intrest_list:
            'The summit agenda is looking very interesting already.\n',
          indutrial_sector: 'Biotechnology',
          ap_budget: null,
          scope_for_resposibility: 'International',
          products_and_services: null
        }
      },
      notifications: [],
      is_block: false,
      created_at: '2023-04-18T10:02:31.009Z',
      updated_at: '2023-05-16T20:03:01.195Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    receiver: {
      _id: '643e77a5c0d2109acd3e822e',
      first_name: 'Dr Theo',
      last_name: 'Empeslidis',
      email: 'theo.empeslidis@boehringer-ingelheim.com',
      password: 'Empeslidis1919',
      gender: 'male',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681815451/oxdgzzbxiplogcwiekoe.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'boehringer ingelheim',
          position: 'Senior Clinical Program Lead',
          country: '',
          post_code: '',
          fax: '',
          phone: '07792997234',
          mobile: '',
          address: '17 Audley Close Market Harborough ',
          website: 'https://www.boehringer-ingelheim.com/',
          linkedIn: 'https://www.linkedin.com/in/theo-empeslidis-b726078'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: '',
          intrest_list: '',
          indutrial_sector: null,
          ap_budget: null,
          scope_for_resposibility: null,
          products_and_services: null
        }
      },
      notifications: [
        '6446b635c7e4ae3706e011e3',
        '643f7e25a9882d48e3971eb7',
        '643f7d77c46f6d9a30537b16',
        '643e6ab7c0d2109acd3e81fa'
      ],
      is_block: false,
      created_at: '2023-04-18T10:57:41.724Z',
      updated_at: '2023-07-06T09:51:28.812Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    event: '643e77ee5fd4bfd83302d8f8',
    last_message:
      'Dear Dr Empeslidis,\nI am in charge of the Clinical Operations at OM Pharma, a mid-size company. I would be interested to discuss with you your view of the future of clinical trials and how you are moving ahead to this objective. I would like to build a roadmap on my end and would be happy to hear your vision. \nKind regards,\nAude ',
    created_at: '2023-05-12T12:06:58.901Z',
    updated_at: '2023-05-12T12:06:58.901Z',
    __v: 0
  },
  {
    _id: '645d545bfdb4eabdb8b06bab',
    chat_id: '643e77a5c0d2109acd3e822e643e7fa1c0d2109acd3e823f',
    sender: {
      _id: '643e7fa1c0d2109acd3e823f',
      first_name: 'Fariborz ',
      last_name: 'Karimi',
      email: 'fariborzkarimi@hotmail.co.uk',
      password: 'Karimi1919',
      gender: 'male',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681817405/vk9jawshpdtbpkgytrqp.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'Johnson & Johnson',
          position: 'Regulatory Affairs Specialist',
          country: '',
          post_code: '',
          fax: '',
          phone: '07818115103',
          mobile: '',
          address: '79 Humes Avenue',
          linkedIn: 'https://www.linkedin.com/in/fariborz-karimi-a75134164',
          website:
            'https://www.jnj.com/latest-news/johnson-johnson-named-a-2023-fortune-worlds-most-admired-company'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: 'Work Projects',
          intrest_list:
            'CMC RegulatoryUpdates in clinical trial regulations Improvements in clinical trial processesMedtech clinical trials',
          indutrial_sector: 'Pharmaceuticals',
          ap_budget: null,
          scope_for_resposibility: 'National',
          products_and_services: null
        }
      },
      notifications: [],
      is_block: false,
      created_at: '2023-04-18T11:31:45.592Z',
      updated_at: '2023-05-17T04:38:23.276Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    receiver: {
      _id: '643e77a5c0d2109acd3e822e',
      first_name: 'Dr Theo',
      last_name: 'Empeslidis',
      email: 'theo.empeslidis@boehringer-ingelheim.com',
      password: 'Empeslidis1919',
      gender: 'male',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681815451/oxdgzzbxiplogcwiekoe.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'boehringer ingelheim',
          position: 'Senior Clinical Program Lead',
          country: '',
          post_code: '',
          fax: '',
          phone: '07792997234',
          mobile: '',
          address: '17 Audley Close Market Harborough ',
          website: 'https://www.boehringer-ingelheim.com/',
          linkedIn: 'https://www.linkedin.com/in/theo-empeslidis-b726078'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: '',
          intrest_list: '',
          indutrial_sector: null,
          ap_budget: null,
          scope_for_resposibility: null,
          products_and_services: null
        }
      },
      notifications: [
        '6446b635c7e4ae3706e011e3',
        '643f7e25a9882d48e3971eb7',
        '643f7d77c46f6d9a30537b16',
        '643e6ab7c0d2109acd3e81fa'
      ],
      is_block: false,
      created_at: '2023-04-18T10:57:41.724Z',
      updated_at: '2023-07-06T09:51:28.812Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    event: '643e77ee5fd4bfd83302d8f8',
    created_at: '2023-05-11T20:47:23.789Z',
    updated_at: '2023-05-11T20:47:23.789Z',
    __v: 0
  },
  {
    _id: '645cb4861a5fe4547ba6f828',
    chat_id: '643e77a5c0d2109acd3e822e643f7d77c46f6d9a30537b16',
    sender: {
      _id: '643f7d77c46f6d9a30537b16',
      first_name: 'Milaana ',
      last_name: 'Jacob',
      email: 'm.jacob@neuronostics.com',
      password: 'Jacob421',
      gender: 'female',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681882481/hfteqn5ddbtnd0ftyua7.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'Neuronostics',
          position: 'Head of Clinical Operations',
          country: '',
          post_code: '',
          fax: '',
          phone: '+44 (0)117 457 2292',
          mobile: '',
          address: 'Engine Shed, Station Approach, Temple Meads, Bristol',
          linkedIn: 'linkedin.com/in/milaanajacob/',
          website: 'https://www.neuronostics.com/'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities:
            'Funding for clinical trials. Partnership with pharma/biotech companies',
          intrest_list: 'Medical Devices. Medtech. Pharma colloborations.',
          indutrial_sector: 'Biotechnology',
          ap_budget: null,
          scope_for_resposibility: 'International',
          products_and_services: null
        }
      },
      notifications: [],
      is_block: false,
      created_at: '2023-04-19T05:34:47.635Z',
      updated_at: '2023-05-18T13:48:05.754Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    receiver: {
      _id: '643e77a5c0d2109acd3e822e',
      first_name: 'Dr Theo',
      last_name: 'Empeslidis',
      email: 'theo.empeslidis@boehringer-ingelheim.com',
      password: 'Empeslidis1919',
      gender: 'male',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681815451/oxdgzzbxiplogcwiekoe.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'boehringer ingelheim',
          position: 'Senior Clinical Program Lead',
          country: '',
          post_code: '',
          fax: '',
          phone: '07792997234',
          mobile: '',
          address: '17 Audley Close Market Harborough ',
          website: 'https://www.boehringer-ingelheim.com/',
          linkedIn: 'https://www.linkedin.com/in/theo-empeslidis-b726078'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: '',
          intrest_list: '',
          indutrial_sector: null,
          ap_budget: null,
          scope_for_resposibility: null,
          products_and_services: null
        }
      },
      notifications: [
        '6446b635c7e4ae3706e011e3',
        '643f7e25a9882d48e3971eb7',
        '643f7d77c46f6d9a30537b16',
        '643e6ab7c0d2109acd3e81fa'
      ],
      is_block: false,
      created_at: '2023-04-18T10:57:41.724Z',
      updated_at: '2023-07-06T09:51:28.812Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    event: '643e77ee5fd4bfd83302d8f8',
    last_message:
      "Dear Dr Empeslidis\n\nI am the Head of Clinical Operations at Neuronostics. We've developed a digital biomarker of epilepsy and we are also working on developing biomarkers for other neurological conditions. I would be very interested in discussing with you how our biomarker may be used as an outcome measure for your products for clinical trials. \n\nKind regards\nMilaana \n",
    created_at: '2023-05-11T09:25:26.635Z',
    updated_at: '2023-05-11T09:25:26.635Z',
    __v: 0
  },
  {
    _id: '645a4cfb16a6557ceab73c3f',
    chat_id: '643e77a5c0d2109acd3e822e643f7e25a9882d48e3971eb7',
    sender: {
      _id: '643f7e25a9882d48e3971eb7',
      first_name: 'Yolanda ',
      last_name: 'Hill',
      email: 'y.hill@neuronostics.com',
      password: 'hill0900',
      gender: 'female',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681882651/xhhujmify0rjmc6gpywa.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'Neuronostics',
          position: 'Business Development Manager',
          country: '',
          post_code: '',
          fax: '',
          phone: '07908903889',
          mobile: '',
          address: '',
          linkedIn: 'https://www.linkedin.com/in/yolanda-hill-85440349/',
          website: 'https://www.neuronostics.com/'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: '',
          intrest_list: '',
          indutrial_sector: null,
          ap_budget: null,
          scope_for_resposibility: null,
          products_and_services: null
        }
      },
      notifications: [],
      is_block: false,
      created_at: '2023-04-19T05:37:41.261Z',
      updated_at: '2023-05-17T14:27:45.867Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    receiver: {
      _id: '643e77a5c0d2109acd3e822e',
      first_name: 'Dr Theo',
      last_name: 'Empeslidis',
      email: 'theo.empeslidis@boehringer-ingelheim.com',
      password: 'Empeslidis1919',
      gender: 'male',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681815451/oxdgzzbxiplogcwiekoe.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'boehringer ingelheim',
          position: 'Senior Clinical Program Lead',
          country: '',
          post_code: '',
          fax: '',
          phone: '07792997234',
          mobile: '',
          address: '17 Audley Close Market Harborough ',
          website: 'https://www.boehringer-ingelheim.com/',
          linkedIn: 'https://www.linkedin.com/in/theo-empeslidis-b726078'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: '',
          intrest_list: '',
          indutrial_sector: null,
          ap_budget: null,
          scope_for_resposibility: null,
          products_and_services: null
        }
      },
      notifications: [
        '6446b635c7e4ae3706e011e3',
        '643f7e25a9882d48e3971eb7',
        '643f7d77c46f6d9a30537b16',
        '643e6ab7c0d2109acd3e81fa'
      ],
      is_block: false,
      created_at: '2023-04-18T10:57:41.724Z',
      updated_at: '2023-07-06T09:51:28.812Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    event: '643e77ee5fd4bfd83302d8f8',
    last_message:
      "Dear Dr Empeslidis, I am from Neuronostics. We've developed a digital biomarker of epilepsyI would be very interested in discussing with you how our biomarket may be used as an outcome measure for pharma drug trials. We are also working towards biomarkers in other neurological conditions and I believe that you may have compounds in schizophrenia and for epilepsy in animals. All the best, Yolanda",
    created_at: '2023-05-09T13:39:07.232Z',
    updated_at: '2023-05-09T13:39:07.232Z',
    __v: 0
  },
  {
    _id: '645190567ef0db8b51a1fc0b',
    chat_id: '643e77a5c0d2109acd3e822e6446b635c7e4ae3706e011e3',
    sender: {
      _id: '6446b635c7e4ae3706e011e3',
      first_name: 'Salih',
      last_name: 'Siam',
      email: 'salih.siam@genproresearch.com',
      password: 'salih123',
      gender: 'male',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1682355695/gpcvwztv26neqqburqwa.jpg',
      user_type: 'sponsor',
      metadata: {
        delegate_details: {
          position: 'Senior Director, Sales & Business Development',
          company: 'Genpro Research',
          phone: '17815227410',
          website: 'https://genproresearch.com/',
          linkedIn: 'https://www.linkedin.com/in/salih-sal-siam-9070455/',
          address:
            'Genpro Research Inc. \n303 Wyman Street, Suite 300,\nWaltham, MA 02451, USA',
          personal_profile:
            "Lead commercial and customer service representative at Genpro Research. 20 years' commercial experience mostly in the pharmaceutical industry. Working closely with Genpro customers and Genpro teams to build strong and enjoyable long term partnerships, with optimal value to cost ratio.",
          company_profile:
            'Genpro provides innovative AI-based products and services aimed at increasing clinical development efficiency, impact, and return on investment. Expertise includes adaptive design, Bayesian, statistical analysis, RWE, data visualisation, data management, medical writing, and pharmacovigilance. Lead products increase productivity for medical writing,  RWE, and medical affairs groups working on literature reviews, publications, CSRs, and DSURs. Combination of global teams and technology ensures rapid delivery of high quality outsourced deliverables, at reduced cost.',
          country: 'Ireland',
          telephone: '',
          mobile: '+353 87 189 1579'
        },
        package_details: {
          sponsorship: 'Platinum Sponsorship'
        }
      },
      notifications: ['64382bc0973d5778f7976529'],
      is_block: false,
      created_at: '2023-04-24T17:02:45.434Z',
      updated_at: '2023-05-25T11:27:33.586Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    receiver: {
      _id: '643e77a5c0d2109acd3e822e',
      first_name: 'Dr Theo',
      last_name: 'Empeslidis',
      email: 'theo.empeslidis@boehringer-ingelheim.com',
      password: 'Empeslidis1919',
      gender: 'male',
      picture:
        'https://res.cloudinary.com/dxsowbvlv/image/upload/v1681815451/oxdgzzbxiplogcwiekoe.jpg',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: 'boehringer ingelheim',
          position: 'Senior Clinical Program Lead',
          country: '',
          post_code: '',
          fax: '',
          phone: '07792997234',
          mobile: '',
          address: '17 Audley Close Market Harborough ',
          website: 'https://www.boehringer-ingelheim.com/',
          linkedIn: 'https://www.linkedin.com/in/theo-empeslidis-b726078'
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: '',
          intrest_list: '',
          indutrial_sector: null,
          ap_budget: null,
          scope_for_resposibility: null,
          products_and_services: null
        }
      },
      notifications: [
        '6446b635c7e4ae3706e011e3',
        '643f7e25a9882d48e3971eb7',
        '643f7d77c46f6d9a30537b16',
        '643e6ab7c0d2109acd3e81fa'
      ],
      is_block: false,
      created_at: '2023-04-18T10:57:41.724Z',
      updated_at: '2023-07-06T09:51:28.812Z',
      __v: 0,
      event: '643e77ee5fd4bfd83302d8f8'
    },
    event: '643e77ee5fd4bfd83302d8f8',
    last_message:
      'Dear Theo,\n\nHope to meet at CTSS. Genpro provides Biostatistics, data management, visualisation, RWE, and medical writing services.\n\nServices are accelerated and costs reduced using global teams. Particularly medical writing which leverages AI-based tools, increasing automation.\n\nKind regards\nSal',
    created_at: '2023-05-02T22:36:06.867Z',
    updated_at: '2023-05-02T22:36:06.867Z',
    __v: 0
  }
]

const ProfileDetails = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const { user_type, user_id, event_id } = useParams()
  const [selectedKey, setSelectedKey] = useState(searchParams.get('tab') || '1')
  const [day, setDay] = useState('Day 1')
  const [selectedStatusKey, setSelectedStatusKey] = useState('1')
  const { data, isLoading } = useGetUsersQuery({ userId: user_id })
  const [userProfile, setUserProfile] = useState({})
  const [getAgenda, { isLoading: agendaLoading }] = useLazyGetAgendaQuery()
  const [agenda, setAgenda] = useState([])
  const [pendingData, setPendingData] = useState([])
  const [acceptedData, setAcceptedData] = useState([])
  const [declinedData, setDeclinedData] = useState([])
  const [status, setStatus] = useState('')
  const [table, setTable] = useState('')
  const [edit, setEdit] = useState(false)
  const [editID, setEditID] = useState('')
  const [editDetail, setEditDetail] = useState({})
  const {
    data: invitations,
    isLoading: invitationLoading,
    refetch: refetchInvitation
  } = useGetInvitationQuery({
    eventId: event_id,
    sender: user_id,
    receiver: user_id
  })

  const [
    putInvitation,
    { isLoading: updatingInvitation, error: updateInvitationError }
  ] = usePutInvitationMutation()

  const columns = [
    {
      title: 'Meeting',
      dataIndex: 'meeting',
      key: 'meeting',
      render: (text, data) => (
        <p className='text-center font-medium'>
          {data?.sender?.first_name} {data?.sender?.last_name} requested a
          meeting with {data?.receiver?.first_name} {data?.receiver?.last_name}{' '}
        </p>
      )
    },
    {
      title: 'Meeting Time',
      dataIndex: 'meetingtime',
      key: 'meetingtime',
      width: '25%',
      render: (text, data) => (
        <p className='text-center font-medium'>
          {data?.meeting?.start_time
            ? `${data?.meeting?.start_time} - ${data?.meeting?.end_time} (${data?.meeting?.day})`
            : '----'}
        </p>
      )
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      width: '5%',
      render: (text, data) => (
        <p className='text-center font-medium'>
          <NavLink
            to={`/chats?user=${
              data?.sender?._id === getUserInfo().id
                ? data?.receiver?._id
                : data?.sender?._id
            }`}
          >
            <MessageOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
          </NavLink>
        </p>
      )
    },
    {
      title: 'Table Number',
      dataIndex: 'table_number',
      key: 'table_number',
      width: '12%',
      render: (text, data) => (
        <p className='text-center font-medium'>
          {data?.receiver?.metadata?.atendee_details?.table_number ||
            data?.sender?.metadata?.atendee_details?.table_number}
        </p>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (text, data) => (
        <div className='flex justify-center flex-col md:flex-row'>
          <div
            className={`h-9 w-24 md:w-32 ${
              text === 'accepted'
                ? '!bg-themeGreen'
                : text === 'rejected'
                ? '!bg-themeRed'
                : text === 'invited' && 'bg-primary'
            } rounded-lg flex justify-center items-center`}
          >
            <p className='font-semibold text-white'>{text.toUpperCase()}</p>
          </div>
        </div>
      )
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      render: (text, data) => (
        <div className='text-center'>
          <EditOutlined
            onClick={() => {
              const tableNumber =
                data?.receiver?.metadata?.atendee_details?.table_number ||
                data?.sender?.metadata?.atendee_details?.table_number
              setEdit(true)
              setStatus(data.status)
              setTable(tableNumber)
              setEditID(data._id)
              setEditDetail(data)
              console.log('DATA', data)
            }}
            style={{ fontSize: '22px', cursor: 'pointer' }}
          />
        </div>
      )
    }
  ]

  const updateMeeting = async () => {
    console.log('editDetail', editDetail)
    const invite = await putInvitation({
      id: editID,
      body: {
        event: editDetail.event,
        status: status,
        table: table ? table : null,
        event: editDetail.event,
        sender: editDetail.sender._id,
        receiver: editDetail.receiver._id,
        isSenderSponser: editDetail.sender.user_type === 'sponsor',
        updateTable: status === 'accepted'
      }
    })
    setStatus('')
    setTable('')
    setEditID('')
    setEdit(false)
    refetchInvitation()
  }

  const getUserAgenda = async () => {
    const response = await getAgenda({
      event_id: event_id,
      user_id: user_id,
      day
    })
    const arr = response?.data?.agendas.data || []
    setAgenda(arr.map(v => v.schedule))
  }

  function sortObjectsByDayAndTime (objects) {
    // Define a custom sorting function
    function compareDayAndTime (a, b) {
      const dayA = a.meeting.day
      const dayB = b.meeting.day

      if (dayA === dayB) {
        const timeFormat = 'hh:mm A' // Specify the time format
        const timeObjA = dayjs(a.meeting.start_time, timeFormat)
        const timeObjB = dayjs(b.meeting.start_time, timeFormat)

        return timeObjA - timeObjB
      }

      return dayA.localeCompare(dayB)
    }

    // Sort the objects using the custom sorting function
    const sortedObjects = objects.slice().sort(compareDayAndTime)

    return sortedObjects
  }

  useEffect(() => {
    const invitationList = invitations?.invitations?.data || []
    const pending = []
    const accepted = []
    const declined = []
    for (var i = 0; i < invitationList.length; i++) {
      const status = invitationList[i].status
      if (status == 'invited') {
        pending.push(invitationList[i])
      } else if (status == 'accepted') {
        accepted.push(invitationList[i])
      } else {
        declined.push(invitationList[i])
      }
    }
    setPendingData(pending)
    setAcceptedData([
      ...sortObjectsByDayAndTime(accepted.filter(data => data.meeting)),
      ...accepted.filter(data => !data.meeting)
    ])
    setDeclinedData(declined)
  }, [invitations])

  useEffect(() => {
    getUserAgenda()
  }, [day])

  const navigate = useNavigate()
  useEffect(() => {
    setUserProfile(data?.users?.data[0] || {})
  }, [isLoading])
  const onChange = key => {
    setSearchParams({ tab: key })
    setSelectedKey(key)
  }
  const onChangeStatus = key => {
    setSelectedStatusKey(key)
  }
  const statusItems = [
    {
      key: '1',
      label: (
        <p className={`${selectedStatusKey === '1' && 'font-semibold'}`}>
          Accepted
        </p>
      ),
      children: <ThemeTable columns={columns} data={acceptedData} />
    },
    {
      key: '2',
      label: (
        <p className={`${selectedStatusKey === '2' && 'font-semibold'}`}>
          Pending
        </p>
      ),
      children: <ThemeTable columns={columns} data={pendingData} />
    },
    {
      key: '3',
      label: (
        <p className={`${selectedStatusKey === '3' && 'font-semibold'}`}>
          Rejected
        </p>
      ),
      children: <ThemeTable columns={columns} data={declinedData} />
    }
  ]
  const items = [
    {
      key: '1',
      label: (
        <p className={`${selectedKey === '1' && 'font-semibold'}`}>
          Event Agenda
        </p>
      ),
      children: (
        <>
          {' '}
          <div className='flex items-center justify-between mb-4'>
            <div>
              <ThemeDropdown
                setValue={e => setDay(e)}
                text={day}
                items={options.days}
              />
            </div>
          </div>
          {agenda.length ? (
            <EventAgenda
              schedules={agenda}
              eventId={event_id}
              day={day}
              isAdmin={true}
            />
          ) : (
            <div
              className='bg-white rounded-lg mt-4 flex justify-center items-center flex-col'
              style={{ height: 450 }}
            >
              <img src={no_agenda} alt='' width={450} />
              <p className='font-bold text-4xl mt-3'>
                Create Your <span className='text-primary'>Agenda</span>
              </p>
            </div>
          )}
        </>
      )
    },
    {
      key: '2',
      label: (
        <p className={`${selectedKey === '2' && 'font-semibold'}`}>
          Meeting Iternary
        </p>
      ),
      children: (
        <>
          <Row className='bg-white rounded-xl p-5'>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              className='flex flex-col items-center mt-3 lg:mt-0'
            >
              <p className='font-semibold text-base md:text-lg'>
                Total Invites
              </p>
              <div className='bg-primary w-12 h-8 flex justify-center items-center rounded-lg mt-1'>
                <p className='font-bold text-white text-base'>
                  {invitations?.invitations?.data?.length || 0}
                </p>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              className='flex flex-col items-center'
            >
              <p className='font-semibold text-base md:text-lg'>
                Pending Invites
              </p>
              <div className='bg-primary w-12 h-8 flex justify-center items-center rounded-lg mt-1'>
                <p className='font-bold text-white text-base'>
                  {pendingData.length}
                </p>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              className='flex flex-col items-center mt-3 md:mt-0'
            >
              <p className='font-semibold text-base md:text-lg'>
                Accepted Invites
              </p>
              <div className='bg-themeGreen w-12 h-8 flex justify-center items-center rounded-lg mt-1'>
                <p className='font-bold text-white text-base'>
                  {acceptedData.length}
                </p>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              className='flex flex-col items-center mt-3 lg:mt-0'
            >
              <p className='font-semibold text-base md:text-lg'>
                Rejected Invites
              </p>
              <div className='bg-themeRed w-12 h-8 flex justify-center items-center rounded-lg mt-1'>
                <p className='font-bold text-white text-base'>
                  {declinedData.length}
                </p>
              </div>
            </Col>
          </Row>
          <Tabs
            defaultActiveKey='1'
            items={statusItems}
            onChange={onChangeStatus}
            className='mt-5'
          />
        </>
      )
    },
    {
      key: '3',
      label: 'Chats',
      children: (
        <UserChats userID={user_id} eventID={event_id} chatList={chatList} />
      )
    }
  ]
  const menuSelected = {
    sponsors: '3',
    delegates: '4'
  }
  return (
    <Container
      selected={menuSelected[user_type]}
      header={
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <NavLink to={`/event/${event_id}`}>
              <img src={left_arrow_icon} width={18} height={18} alt='' />
            </NavLink>
            <p className='ml-3 font-semibold text-2xl'>Back</p>
          </div>
          <div className='flex'>
            {selectedKey === '2' && (
              <ThemeButton
                onClick={() => navigate('/chats')}
                content={<img alt='' src={comment_icon} width={20} />}
              />
            )}
            <ThemeButton content={'Download Agenda'} className='ml-3 hidden' />
          </div>
        </div>
      }
    >
      <p className='font-bold text-3xl'>
        {userProfile?.first_name} {userProfile?.last_name} (
        {userProfile.user_type})
      </p>
      <p className='text-sm font-semibold'>
        {userProfile?.metadata?.delegate_details?.position || '---'}
      </p>
      <p className='text-xs font-semibold'>{userProfile?.email}</p>
      <p className='text-xs font-semibold'>
        {userProfile?.metadata?.delegate_details?.phone}
      </p>
      <Tabs
        defaultActiveKey={searchParams.get('tab') || '1'}
        items={items}
        onChange={onChange}
        className='mt-5'
      />
      <Modal
        footer={false}
        onCancel={() => setEdit(false)}
        open={edit}
        width={500}
      >
        <div className='p-6'>
          <div className='w-100'>
            <ThemeDropdown
              label={'Meeting status'}
              styles={{ width: '100%', height: 40 }}
              setValue={e => setStatus(e)}
              text={status}
              labelPrimary
              items={[
                {
                  label: 'Invited',
                  key: 'invited',
                  value: 'invited'
                },
                {
                  label: 'Accepted',
                  key: 'accepted',
                  value: 'accepted'
                },
                {
                  label: 'Rejected',
                  key: 'rejected',
                  value: 'rejected'
                }
              ]}
            />
          </div>
          <div className='mt-4'>
            <ThemeInput
              label={'Table Number'}
              placeholder={'table number'}
              labelPrimary
              value={table}
              onChange={e => setTable(e.target.value)}
            />
          </div>
          <div className='w-100 mt-4'>
            <ThemeButton
              onClick={updateMeeting}
              content={'Update'}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </Modal>
    </Container>
  )
}

export default ProfileDetails
