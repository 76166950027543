import { NavLink } from 'react-router-dom'
import { left_arrow_icon } from '../../assets/icons/icons'
import {
  Container,
  ThemeDropdown,
  ThemeTable
} from '../../components/components'
import { useLazyGetLogQuery } from '../../services/log'
import { useState, useEffect } from 'react'
import { useLazyGetEventsQuery } from '../../services/events'
import dayjs from 'dayjs'
const users = [
  {
    label: 'All',
    key: 'delegate,sponsor'
  },
  {
    label: 'Delegate',
    key: 'delegate'
  },
  {
    label: 'Sponsor',
    key: 'sponsor'
  }
]
const logTypes = [
  {
    label: 'All',
    key: 'accounts,meetings'
  },
  {
    label: 'Account',
    key: 'accounts'
  },
  {
    label: 'Meetings',
    key: 'meetings'
  }
]

const statuses = [
  {
    label: 'All',
    key: 'pending,confirmed'
  },
  {
    label: 'Pending',
    key: 'pending'
  },
  {
    label: 'Confirmed',
    key: 'confirmed'
  }
]

const devices = [
  {
    label: 'All',
    key: 'web,mobile'
  },
  {
    label: 'Web',
    key: 'web'
  },
  {
    label: 'Mobile',
    key: 'mobile'
  }
]
const columns = [
  {
    title: 'Type',
    dataIndex: 'meta.log_type',
    key: 'meta.log_type',
    width: '10%',
    render: (text, data) => (
      <p className='text-center font-medium'>{data.meta.log_type || '---'}</p>
    )
  },
  {
    title: 'User',
    dataIndex: 'meta.user_type',
    key: 'meta.user_type',
    width: '10%',
    render: (text, data) => (
      <p className='text-center font-medium'>{data.meta.user_type}</p>
    )
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    width: '70%',
    render: text => <p className='text-center font-medium'>{text}</p>
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: '30%',
    render: text => (
      <p className='text-center font-medium'>{new Date(text).toDateString()}</p>
    )
  },
  {
    title: 'Time',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: '30%',
    render: text => (
      <p className='text-center font-medium'>
        {dayjs(text).format('hh:mm:ss A')}
      </p>
    )
  }
]

const Logs = () => {
  const [getLog, { isLoading }] = useLazyGetLogQuery({})
  const [logs, setLogs] = useState([])
  const [userType, setUserType] = useState('All')
  const [logType, setLogType] = useState('All')
  const [device, setDevice] = useState('All')
  const [status, setStatus] = useState('All')
  const [event, setEvent] = useState('All')
  const [eventIds, setEventIds] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [resultPerPage, setResultPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [getEvents] = useLazyGetEventsQuery()

  const fetchEvents = async () => {
    const response = await getEvents({})
    const events = response?.data?.events?.data || []
    setEventIds(
      events.map(v => ({
        label: v.name,
        key: v.name,
        value: v._id
      }))
    )
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  const getLogs = async () => {
    const eventIndex = eventIds.findIndex(v => v.key === event)
    const eventId = eventIds[eventIndex]?.value
    const logs = await getLog({
      type: userType === 'All' ? 'delegate,sponsor' : userType,
      log: logType === 'All' ? 'accounts,meetings' : logType,
      event: event === 'All' ? '' : eventId,
      status: status === 'All' ? '' : status,
      device: device === 'All' ? 'web,mobile' : device,
      currentPage,
      resultPerPage
    })
    console.log('logs====> ', logs?.data?.logs?.data)
    setLogs(logs?.data?.logs?.data || [])
    setTotal(logs?.data?.logs?.total || 0)
  }
  useEffect(() => {
    getLogs()
  }, [userType, logType, status, device, event, currentPage, resultPerPage])

  return (
    <Container
      selected={'1'}
      header={
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <NavLink to='/dashboard'>
              <img src={left_arrow_icon} width={18} height={18} alt='' />
            </NavLink>
            <p className='ml-3 font-semibold text-2xl'>Logs</p>
          </div>
          <div>
            <ThemeDropdown
              className='mr-2'
              setValue={e => setEvent(e)}
              text={event}
              items={eventIds}
            />
            <ThemeDropdown
              className='mr-2'
              setValue={e => setUserType(e)}
              text={userType}
              items={users}
            />
            <ThemeDropdown
              className='mr-2'
              setValue={e => setLogType(e)}
              text={logType}
              items={logTypes}
            />
            <ThemeDropdown
              className='mr-2'
              setValue={e => setDevice(e)}
              text={device}
              items={devices}
            />
            {logType === 'meetings' && (
              <ThemeDropdown
                setValue={e => setStatus(e)}
                text={status}
                items={statuses}
              />
            )}
          </div>
        </div>
      }
    >
      <ThemeTable
        pagination={{ total, setCurrentPage, setResultPerPage }}
        columns={columns}
        data={logs}
      />
    </Container>
  )
}

export default Logs
