import { Col, Drawer, Row, Spin, Input, Space, Select } from "antd";
import { left_arrow_icon, add_icon } from "../../assets/icons/icons";
import { uploadImage } from "../../helpers/helpers";
import {
  ThemeButton,
  ThemeInput,
  ThemeSelect,
  UploadUserProfile,
} from "../components";
import options from "../../lib/data/options.json";

const DelegateDrawer = ({
  open,
  btnTxt,
  addIcon,
  onSubmit,
  isLoading,
  formData,
  setFormData,
  image,
  setImage,
  onClose,
  mode,
}) => {
  const handleImage = async (e) => {
    const res = await uploadImage(e.target.files[0]);
    setImage(res);
    setFormData({
      ...formData,
      picture: res,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDelegateDetail = (e) => {
    setFormData({
      ...formData,
      metadata: {
        ...formData.metadata,
        delegate_details: {
          ...formData.metadata.delegate_details,
          [e.target.name]: e.target.value,
        },
      },
    });
  };
  const handleChangeAtendeeDetail = (e) => {
    setFormData({
      ...formData,
      metadata: {
        ...formData.metadata,
        atendee_details: {
          ...formData.metadata.atendee_details,
          [e.target.name]: e.target.value,
        },
      },
    });
  };
  const handleChangeExtraDetail = (e) => {
    setFormData({
      ...formData,
      metadata: {
        ...formData.metadata,
        extra_details: {
          ...formData.metadata.extra_details,
          [e.target.name]: e.target.value,
        },
      },
    });
  };
  console.log(
    "formData.metadata.extra_details.scope_for_resposibility",
    formData.metadata.extra_details.scope_for_resposibility
  );
  return (
    <Drawer
      width={600}
      title={btnTxt}
      placement="right"
      onClose={onClose}
      open={open}
      closeIcon={<img src={left_arrow_icon} alt="" width={18} height={18} />}
      extra={
        <ThemeButton
          onClick={onSubmit}
          className={"ml-4 w-50"}
          content={
            <>
              {isLoading ? (
                <Spin className="mt-1" />
              ) : (
                <div className="flex items-center">
                  {addIcon && (
                    <img
                      src={add_icon}
                      alt=""
                      width={18}
                      height={18}
                      className="mr-2"
                    />
                  )}
                  <p>{btnTxt}</p>
                </div>
              )}
            </>
          }
        />
      }
    >
      <div className="flex justify-center">
        <UploadUserProfile image={image} onChange={handleImage} />
      </div>
      <Row className="mt-6" gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"First Name"}
            placeholder={"first name"}
            name={"first_name"}
            value={formData.first_name}
            onChange={handleChange}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Last Name"}
            placeholder={"last name"}
            name={"last_name"}
            value={formData.last_name}
            onChange={handleChange}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Email"}
            placeholder={"email"}
            name={"email"}
            value={formData.email}
            onChange={handleChange}
            labelPrimary
            // disabled={mode === "edit"}
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Phone"}
            placeholder={"phone"}
            name={"phone"}
            value={formData?.metadata?.delegate_details?.phone || ""}
            onChange={handleChangeDelegateDetail}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Password"}
            placeholder={"password"}
            name={"password"}
            value={formData.password}
            onChange={handleChange}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <p className="text-primary text-semibold font-medium">Gender</p>
          <ThemeSelect
            placeholder={"Gender"}
            value={formData.gender}
            onChange={(value) => setFormData({ ...formData, gender: value })}
            options={options?.genders}
            className={"w-full"}
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Company"}
            placeholder={"company"}
            name={"company"}
            value={formData?.metadata?.delegate_details?.company || ""}
            onChange={handleChangeDelegateDetail}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Position"}
            placeholder={"position"}
            name={"position"}
            value={formData?.metadata?.delegate_details?.position || ""}
            onChange={handleChangeDelegateDetail}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Website"}
            placeholder={"website"}
            name={"website"}
            value={formData?.metadata?.delegate_details?.website}
            onChange={handleChangeDelegateDetail}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"LinkedIn"}
            placeholder={"linkedIn"}
            name={"linkedIn"}
            value={formData?.metadata?.delegate_details?.linkedIn}
            onChange={handleChangeDelegateDetail}
            labelPrimary
          />
        </Col>
        {/* <Col span={24} className="mb-4">
          <p className="font-semibold text-base">
            Who would attend as your replacement if you are unable to make to
            summit?
          </p>
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Name"}
            placeholder={"name"}
            name={"name"}
            value={formData?.metadata?.atendee_details?.name || ""}
            onChange={handleChangeAtendeeDetail}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Position"}
            placeholder={"position"}
            name={"position"}
            value={formData?.metadata?.atendee_details?.position || ""}
            onChange={handleChangeAtendeeDetail}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Email & Tel"}
            placeholder={"email"}
            name={"email_and_tel"}
            value={formData?.metadata?.atendee_details?.email_and_tel || ""}
            onChange={handleChangeAtendeeDetail}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Company"}
            placeholder={"company"}
            name={"company"}
            value={formData?.metadata?.atendee_details?.company || ""}
            onChange={handleChangeAtendeeDetail}
            labelPrimary
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold text-base">
            Who, besides yourself have control over the Drug Discovery related
            budget?
          </p>
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Name"}
            placeholder={"name"}
            name={"name"}
            value={formData?.metadata?.extra_details?.name || ""}
            onChange={handleChangeExtraDetail}
            labelPrimary
          />
        </Col>
        <Col span={12} className="mb-4">
          <ThemeInput
            label={"Position"}
            placeholder={"position"}
            name={"position"}
            value={formData?.metadata?.extra_details?.position || ""}
            onChange={handleChangeExtraDetail}
            labelPrimary
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold text-base">
            Please indicate your line manager's details. (optional)
          </p>
        </Col>
        <Col span={24} className="mb-4">
          <ThemeInput
            label={"Name"}
            placeholder={"name"}
            name={"line_manager"}
            value={formData?.metadata?.extra_details?.line_manager || ""}
            onChange={handleChangeExtraDetail}
            labelPrimary
          />
        </Col> */}
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1 text-primary">
            What are your key priorities for the next 6 to 18 months?
          </p>
          <Input.TextArea
            placeholder="write your next priorities"
            name={"priorities"}
            value={formData?.metadata?.extra_details?.priorities || ""}
            onChange={handleChangeExtraDetail}
            rows={5}
            className="font-medium pt-2"
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1 text-primary">
            Please list any subjects or areas of interest that you would like to
            see on the summit agenda:
          </p>
          <Input.TextArea
            placeholder="write what you want to see in the summit agenda"
            name={"intrest_list"}
            value={formData?.metadata?.extra_details?.intrest_list || ""}
            onChange={handleChangeExtraDetail}
            rows={5}
            className="font-medium pt-2"
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1 text-primary">
            Focused therapeutic area:
          </p>
          <Input.TextArea
            placeholder="write focused therapeutic area"
            name={"focused_therapeutic_area"}
            value={
              formData?.metadata?.extra_details?.focused_therapeutic_area || ""
            }
            onChange={(e) =>
              handleChangeExtraDetail({
                target: {
                  value: e.target.value,
                  name: "focused_therapeutic_area",
                },
              })
            }
            rows={5}
            className="font-medium pt-2"
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1 text-primary">Industrial Sector</p>
          <Select
            mode="multiple"
            showSearch
            optionFilterProp="label"
            placeholder={"Industrial Sector"}
            value={formData.metadata.extra_details.indutrial_sector}
            onChange={(value) =>
              handleChangeExtraDetail({
                target: { value, name: "indutrial_sector" },
              })
            }
            options={options?.industrialSectors}
            className={"w-full"}
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1 text-primary">
            Annual Budget
          </p>
          <ThemeSelect
            placeholder={"Personal Budget"}
            value={formData.metadata.extra_details.ap_budget}
            onChange={(value) =>
              handleChangeExtraDetail({
                target: { value, name: "ap_budget" },
              })
            }
            options={options?.annualPersonalBudgets}
            className={"w-full"}
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1 text-primary">
            Scope for responsibility
          </p>
          <Select
            mode="tags"
            style={{ width: "100%", minHeight: 32 }}
            placeholder={"Scope for responsibility"}
            value={
              formData?.metadata?.extra_details?.scope_for_resposibility || []
            }
            onChange={(value) => {
              handleChangeExtraDetail({
                target: {
                  value: value[value.length - 1],
                  name: "scope_for_resposibility",
                },
              });
            }}
            options={options?.scopeOfResponsibilites}
            className={"w-full"}
          />
        </Col>
        <Col span={24} className="mb-4">
          <p className="font-semibold mb-1 text-primary">
            Products & Services you want to purchase in 6-18 months
          </p>
          <Select
            mode="tags"
            allowClear
            style={{ width: "100%", minHeight: 32 }}
            placeholder={"What services you want"}
            value={
              formData?.metadata?.extra_details?.products_and_services || []
            }
            onChange={(value) => {
              handleChangeExtraDetail({
                target: { value, name: "products_and_services" },
              });
            }}
            options={options?.productsAndServices}
            className={"w-full"}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default DelegateDrawer;
