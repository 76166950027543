import { Modal, Input, Row, Col } from "antd";
import { ThemeButton } from "../components";
import { check_icon } from "../../assets/icons/icons";

const { Search } = Input;

const SendInviteModal = ({ isModalOpen, onClose }) => {
  const onSearch = (value) => console.log(value);
  return (
    <Modal
      title="Send Invite"
      footer={false}
      open={isModalOpen}
      onCancel={onClose}
    >
      <div className="mb-4 mt-3">
        <Search
          size="large"
          placeholder="search delegate"
          onSearch={onSearch}
          enterButton
        />
      </div>
      <div className="bg-themeGray-light p-3 rounded-lg">
        {[1, 2, 2, 2].map((e, i) => {
          return (
            <Row
              gutter={{ xs: 8, sm: 12, md: 12, lg: 12 }}
              className={`${i > 0 && "mt-3"}`}
            >
              <Col span={17}>
                <div className="bg-white px-3 py-2 rounded-lg">
                  <p className="font-semibold text-base">Hamza Delegation</p>
                  <p className="text-sm font-medium">delegation@gmail.com</p>
                </div>
              </Col>
              <Col span={7}>
                {e === 1 ? (
                  <div
                    className="flex justify-center items-center bg-white rounded-md"
                    style={{ height: 60 }}
                  >
                    <img src={check_icon} width={15} alt="" />
                    <p className="text-primary ml-1 font-medium">Invite Sent</p>
                  </div>
                ) : (
                  <ThemeButton
                    className={"!w-full"}
                    style={{ height: 60 }}
                    content={"Send Invite"}
                  />
                )}
              </Col>
            </Row>
          );
        })}
      </div>
    </Modal>
  );
};

export default SendInviteModal;
