import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants/constants'
import { getValueFromStorage } from '../helpers/helpers'

export const AttendeesApi = createApi({
  reducerPath: 'attendees',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      headers.set('Content-type', 'application/json')
      headers.set('authorization', `Bearer ${getValueFromStorage('token')}`)
      return headers
    }
  }),
  endpoints: builder => ({
    getAttendees: builder.query({
      query: ({
        currentPage = 1,
        resultPerPage = 10000,
        eventID,
        userType,
        userID,
        search
      }) => ({
        url: 'api/attendee',
        method: 'GET',
        params: {
          current_page: currentPage || '',
          result_per_page: resultPerPage || '',
          event_id: eventID || '',
          user_type: userType || '',
          user_id: userID || '',
          search: search || ''
        }
      })
    }),
    addAtendees: builder.mutation({
      query: body => ({
        url: `api/attendee`,
        method: 'POST',
        body: body
      })
    }),
    deleteAtendees: builder.mutation({
      query: id => ({
        url: `api/attendee/${id}`,
        method: 'DELETE'
      })
    }),
    addAttendance: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `api/attendee/attendance/${id}`,
          method: 'PUT',
          body: body
        }
      }
    }),
    removeAttendance: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `api/attendee/removeAttendance/${id}`,
          method: 'PUT',
          body: body
        }
      }
    }),
    getAttendance: builder.query({
      query: ({
        currentPage = 1,
        resultPerPage = 10000,
        eventID,
        userType,
        attendance
      }) => {
        return {
          url: `api/attendee/attendance`,
          method: 'GET',
          params: {
            current_page: currentPage || '',
            result_per_page: resultPerPage || '',
            event_id: eventID || '',
            user_type: userType || '',
            attendance: attendance || ''
          }
        }
      }
    })
  })
})

export const {
  useGetAttendeesQuery,
  useLazyGetAttendeesQuery,
  useAddAtendeesMutation,
  useDeleteAtendeesMutation,
  useAddAttendanceMutation,
  useLazyGetAttendanceQuery,
  useRemoveAttendanceMutation
} = AttendeesApi
