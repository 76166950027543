import { useEffect, useState } from 'react'
import { left_arrow_icon } from '../../../assets/icons/icons'
import {
  SecondContainer,
  ThemeTable,
  PersonalDetailsModal,
  ThemeButton,
  SupportTicket
} from '../../../components/components'
import { NavLink, useParams, useSearchParams } from 'react-router-dom'
import { Tabs, Row, Col, message, Alert, Badge, Tooltip } from 'antd'
import EventInfo from './eventInfo/eventInfo'
import EventAgenda from '../../eventDetails/eventAgenda/eventAgenda'
import { useGetEventByIDQuery } from '../../../services/events'
import {
  useGetInvitationQuery,
  usePutInvitationMutation,
  useDeleteInvitationMutation
} from '../../../services/invitation'
import { getUserInfo } from '../../../helpers/helpers'
import PersonalAgenda from './personalAgenda/personalAgenda'
import Attendees from './attendees/attendees'
import {
  MessageOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  UndoOutlined
} from '@ant-design/icons'
import { useLazyGetAttendeesQuery } from '../../../services/attendees'
import dayjs from 'dayjs'

const Details = () => {
  const { id } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()
  const [selectedKey, setSelectedKey] = useState(searchParams.get('tab') || '1')
  const [selectedStatusKey, setSelectedStatusKey] = useState('1')
  const [detailsModal, setDetailsModal] = useState(false)
  const { data: event, isLoading: eventLoading } = useGetEventByIDQuery(id)
  const {
    data: invitations,
    isLoading: invitationLoading,
    refetch: refetchInvitation
  } = useGetInvitationQuery({
    eventId: id,
    sender: getUserInfo().id,
    receiver: getUserInfo().id
  })
  const [
    putInvitation,
    { isLoading: updatingInvitation, error: updateInvitationError }
  ] = usePutInvitationMutation()
  const [upcomingEvent, setUpcomingEvent] = useState({})
  const [pendingData, setPendingData] = useState([])
  const [acceptedData, setAcceptedData] = useState([])
  const [declinedData, setDeclinedData] = useState([])
  const [meetingNotifications, setMeetingNotifications] = useState([])
  const [userData, setUserData] = useState({})
  const [getAttendees, { isLoading: fetchAtendee }] = useLazyGetAttendeesQuery()
  const [currentAttendee, setCurrentAttendee] = useState({})
  const [
    deletInvitation,
    { isLoading: deleteInvitationLoading, error: deleteInvitationLoadingError }
  ] = useDeleteInvitationMutation()

  const getUser = async () => {
    const response = await getAttendees({
      eventID: id,
      userID: getUserInfo().id,
      currentPage: 1,
      resultPerPage: 1
    })
    setCurrentAttendee(response?.data?.attendees?.data[0])
  }

  function sortObjectsByTime (objects) {
    // Define a custom sorting function
    function compareTime (a, b) {
      const timeFormat = 'hh:mm A' // Specify the time format
      const timeA = dayjs(a.meeting?.start_time, timeFormat)
      const timeB = dayjs(b.meeting?.start_time, timeFormat)

      if (timeA.isBefore(timeB)) {
        return -1
      } else if (timeA.isAfter(timeB)) {
        return 1
      } else {
        return 0
      }
    }

    // Sort the objects using the custom sorting function
    const sortedObjects = objects.slice().sort(compareTime)

    return sortedObjects
  }

  console.log('invitaions data length', invitations?.invitations?.data)

  useEffect(() => {
    getUser()
    setUpcomingEvent(event?.events?.data[0])
    const invitationList = invitations?.invitations?.data || []
    console.log('invitationList---->', invitationList)
    const pending = []
    const accepted = []
    const declined = []
    const meetingNotification = []
    for (var i = 0; i < invitationList.length; i++) {
      const status = invitationList[i].status
      if (status == 'invited') {
        if (
          invitationList[i].sender &&
          invitationList[i].sender._id !== getUserInfo().id
        ) {
          meetingNotification.push(invitationList[i])
        }
        pending.push(invitationList[i])
      } else if (status == 'accepted') {
        accepted.push(invitationList[i])
      } else {
        declined.push(invitationList[i])
      }
    }
    setMeetingNotifications(meetingNotification)
    setPendingData(pending)

    const acceptedSorted = [
      ...sortObjectsByTime(accepted).filter(
        data => data?.meeting?.day === 'Day 1' && data?.meeting?.start_time
      ),
      ...sortObjectsByTime(accepted).filter(
        data => data?.meeting?.day === 'Day 2' && data?.meeting?.start_time
      ),
      ...sortObjectsByTime(accepted).filter(data => !data?.meeting)
    ]
    setAcceptedData(acceptedSorted)
    setDeclinedData(declined)
  }, [eventLoading, invitationLoading, invitations])

  const onChange = key => {
    setSearchParams({ tab: key })
    setSelectedKey(key)
  }
  const onChangeStatus = key => {
    setSelectedStatusKey(key)
  }

  const undoMeeting = async data => {
    const date = dayjs(dayjs().format())
    const diff = date.diff(data.created_at, 'minute')
    if (diff < 2) {
      const deleted = await deletInvitation({ id: data._id })
      refetchInvitation()
    } else {
      message.error('You cannot undo this meeting.')
    }
  }

  const updateInvite = async (data, status) => {
    try {
      const inviteData = {
        status,
        receiver: data?.receiver?._id,
        event: data.event,
        sender: data.sender._id,
        priority: data.priority,
        userType: getUserInfo().type,
        user: getUserInfo()
      }
      const invite = await putInvitation({ id: data._id, body: inviteData })
      refetchInvitation()
      if (invite?.error) {
        message.error(invite?.error?.data?.message)
        return
      }
      message.success(`Invite ${status} successfully`)
    } catch (err) {
      console.log('err', err)
    }
  }

  const columns = [
    {
      title: 'Meeting',
      dataIndex: 'meeting',
      key: 'meeting',
      render: (text, data) =>
        data?.receiver?._id == getUserInfo().id ? (
          <p className='text-center font-medium'>
            <NavLink
              onClick={() => {
                setDetailsModal(true)
                setUserData(data?.sender)
              }}
              className='font-bold underline hover:!text-primary'
            >
              {data?.sender?.first_name} {data?.sender?.last_name}{' '}
            </NavLink>
            is requesting a meeting with you
          </p>
        ) : (
          <p className='text-center font-medium'>
            You requested a meeting with{' '}
            <NavLink
              onClick={() => {
                setDetailsModal(true)
                setUserData(data?.receiver)
              }}
              className='font-bold underline hover:!text-primary'
            >
              {data?.receiver?.first_name} {data?.receiver?.last_name}{' '}
            </NavLink>
          </p>
        )
    },
    {
      title: 'Meeting Time',
      dataIndex: 'meetingtime',
      key: 'meetingtime',
      width: '20%',
      render: (text, data) => (
        <p className='text-center font-medium'>
          {data?.meeting?.start_time
            ? `${data?.meeting?.start_time} - ${data?.meeting?.end_time} (${data?.meeting?.day})`
            : '----'}
        </p>
      )
    },

    {
      title: 'Table Number',
      dataIndex: 'table_number',
      key: 'table_number',
      width: '15%',
      render: (text, data) => {
        return (
          <p className='text-center font-medium'>
            {data?.meeting?.start_time
              ? data?.sender?.metadata?.atendee_details?.table_number
              : '-'}
          </p>
        )
      }
    },
    // ...(getUserInfo().type === 'sponsor'
    //   ? [
    //       {
    //         title: 'Priority',
    //         dataIndex: 'priority',
    //         key: 'priority',
    //         width: '5%',
    //         render: (text, data) => (
    //           <p className='text-center font-medium'>
    //             {text}
    //             {text == 1 && (
    //               <CaretUpOutlined
    //                 style={{ fontSize: 14, color: 'green' }}
    //                 className='ml-2'
    //               />
    //             )}
    //             {text == 2 && (
    //               <CaretDownOutlined
    //                 style={{ fontSize: 14, color: '#e2b025' }}
    //                 className='ml-2'
    //               />
    //             )}
    //             {text == 3 && (
    //               <CaretDownOutlined
    //                 style={{ fontSize: 14, color: 'red' }}
    //                 className='ml-2'
    //               />
    //             )}
    //           </p>
    //         )
    //       }
    //     ]
    //   : []),
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      width: '5%',
      render: (text, data) => (
        <p className='text-center font-medium'>
          <NavLink
            to={`/chats?user=${
              data?.sender?._id === getUserInfo().id
                ? data?.receiver?._id
                : data?.sender?._id
            }`}
          >
            <Tooltip title={data.note} color={'#e2b025'}>
              <MessageOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </Tooltip>
          </NavLink>
        </p>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '25%',
      render: (text, data) => (
        <div className='flex justify-center flex-col md:flex-row'>
          {data?.receiver?._id == getUserInfo().id && text === 'invited' ? (
            <div className='flex justify-center flex-col md:flex-row'>
              <ThemeButton
                disabled={updatingInvitation}
                onClick={() => updateInvite(data, 'accepted')}
                className={'ml-4 w-35 !bg-themeGreen'}
                content='Accept'
              />
              <ThemeButton
                disabled={updatingInvitation}
                onClick={() => updateInvite(data, 'rejected')}
                className={'ml-4 w-35 !bg-themeRed'}
                content='Reject'
              />
            </div>
          ) : (
            <div
              className={`h-9 w-24 md:w-32 ${
                text === 'accepted'
                  ? '!bg-themeGreen'
                  : text === 'rejected'
                  ? '!bg-themeRed'
                  : text === 'invited' && 'bg-primary'
              } rounded-lg flex justify-center items-center`}
            >
              <p className='font-semibold text-white'>{text.toUpperCase()}</p>
            </div>
          )}
        </div>
      )
    },
    ...(selectedStatusKey !== '2'
      ? [
          {
            title: 'Undo',
            dataIndex: 'undo',
            key: 'undo',
            width: '5%',
            render: (text, data) => (
              <div className='text-center'>
                <UndoOutlined
                  onClick={() => undoMeeting(data)}
                  style={{ fontSize: '22px', cursor: 'pointer' }}
                />
              </div>
            )
          }
        ]
      : [])
  ]

  const statusItems = [
    {
      key: '1',
      label: (
        <p className={`${selectedStatusKey === '1' && 'font-semibold'}`}>
          Pending
        </p>
      ),
      children: <ThemeTable columns={columns} data={pendingData} />
    },
    {
      key: '2',
      label: (
        <p className={`${selectedStatusKey === '2' && 'font-semibold'}`}>
          Accepted
        </p>
      ),
      children: <ThemeTable columns={columns} data={acceptedData} />
    },
    {
      key: '3',
      label: (
        <p className={`${selectedStatusKey === '3' && 'font-semibold'}`}>
          Rejected
        </p>
      ),
      children: <ThemeTable columns={columns} data={declinedData} />
    }
  ]

  const items = [
    {
      key: '1',
      label: (
        <p className={`${selectedKey === '1' && 'font-semibold'}`}>
          Event Info
        </p>
      ),
      children: <EventInfo event={upcomingEvent} />
    },
    {
      key: '2',
      label: (
        <p className={`${selectedKey === '2' && 'font-semibold'}`}>
          Event Agenda
        </p>
      ),
      children: (
        <EventAgenda
          isAdmin={false}
          eventID={id}
          eventName={upcomingEvent?.name}
        />
      )
    },
    {
      key: '3',
      label: (
        <p className={`${selectedKey === '3' && 'font-semibold'}`}>
          List of Attendees
        </p>
      ),
      children: (
        <Attendees
          invitations={invitations}
          refetchInvitation={refetchInvitation}
          currentUser={getUserInfo()}
          attendees={upcomingEvent?.attendees}
        />
      )
    },
    {
      key: '4',
      label: (
        <p className={`${selectedKey === '4' && 'font-semibold'}`}>
          Meeting Itinerary <Badge count={meetingNotifications.length} />
        </p>
      ),
      children: (
        <>
          <div>
            {getUserInfo().type === 'sponsor' &&
            currentAttendee &&
            Object.keys(currentAttendee).length ? (
              <Row className='py-4'>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Alert
                    message='Important Note'
                    description={
                      <>
                        <span>
                          I. The time of your meetings will be sorted in
                          chronological order in your personalized agenda
                        </span>
                        <br />
                        <span>
                          II. A maximum {currentAttendee?.allowed_meetings}{' '}
                          meetings can be allocated to specific time in the
                          networking breaks. For the rest of the accepted
                          meetings, please ask the onsite staff to arrange it in
                          cocktail reception or over lunch reception
                        </span>
                      </>
                    }
                    type='warning'
                  />
                </Col>
              </Row>
            ) : null}
            <Row className='bg-white rounded-xl p-5'>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                className='flex flex-col items-center mt-3 lg:mt-0'
              >
                <p className='font-semibold text-base md:text-lg'>
                  Total Invites
                </p>
                <div className='bg-primary w-12 h-8 flex justify-center items-center rounded-lg mt-1'>
                  <p className='font-bold text-white text-base'>
                    {pendingData.length +
                      acceptedData.length +
                      declinedData?.length || 0}
                  </p>
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                className='flex flex-col items-center'
              >
                <p className='font-semibold text-base md:text-lg'>
                  Pending Invites
                </p>
                <div className='bg-primary w-12 h-8 flex justify-center items-center rounded-lg mt-1'>
                  <p className='font-bold text-white text-base'>
                    {pendingData.length}
                  </p>
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                className='flex flex-col items-center mt-3 md:mt-0'
              >
                <p className='font-semibold text-base md:text-lg'>
                  Accepted Invites
                </p>
                <div className='bg-themeGreen w-12 h-8 flex justify-center items-center rounded-lg mt-1'>
                  <p className='font-bold text-white text-base'>
                    {acceptedData.length}
                  </p>
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                className='flex flex-col items-center mt-3 lg:mt-0'
              >
                <p className='font-semibold text-base md:text-lg'>
                  Rejected Invites
                </p>
                <div className='bg-themeRed w-12 h-8 flex justify-center items-center rounded-lg mt-1'>
                  <p className='font-bold text-white text-base'>
                    {declinedData.length}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <Tabs
            defaultActiveKey='1'
            items={statusItems}
            onChange={onChangeStatus}
            className='mt-2'
          />
        </>
      )
    },
    {
      key: '5',
      label: (
        <p className={`${selectedKey === '5' && 'font-semibold'}`}>
          Personalized Agenda
        </p>
      ),
      children: (
        <PersonalAgenda
          eventName={upcomingEvent?.name}
          eventId={upcomingEvent?._id}
          schedules={upcomingEvent?.schedules || []}
        />
      )
    }
    // {
    //   key: "6",
    //   label: (
    //     <p className={`${selectedKey === "6" && "font-semibold"}`}>
    //       Support Ticket
    //     </p>
    //   ),
    //   children: <SupportTicket eventId={upcomingEvent?._id} />,
    // },
  ]

  return (
    <SecondContainer>
      <PersonalDetailsModal
        userData={userData}
        isModalOpen={detailsModal}
        onClose={() => setDetailsModal(false)}
      />
      <div className='flex items-center'>
        <NavLink to='/upcoming-events'>
          <img src={left_arrow_icon} width={18} height={18} alt='' />
        </NavLink>
        <p className='ml-3 font-semibold text-lg md:text-2xl'>
          {upcomingEvent?.name}
        </p>
      </div>
      <Tabs
        defaultActiveKey={searchParams.get('tab') || '1'}
        items={items}
        onChange={onChange}
        className='mt-2'
      />
    </SecondContainer>
  )
}

export default Details
