import { Modal } from "antd";
import { user } from "../../assets/images/images";
import { Row, Col } from "antd";
import { GlobalOutlined, LinkedinOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
const PersonalDetailsModal = ({ isModalOpen, onClose, userData }) => {
  const userType = userData?.user_type;
  return (
    <Modal footer={false} open={isModalOpen} onCancel={onClose} width={600}>
      <div className="mb-4 mt-3">
        <div className="flex items-center">
          <div>
            <img
              src={userData?.picture || user}
              alt=""
              width="80"
              height="80"
              className="w-[80px] h-[80px] rounded-full object-cover"
            />
            <p className="font-bold text-lg mt-2">
              {userData?.first_name} {userData?.last_name}
            </p>
            <p className="font-medium text-xs">
              {`${
                userData?.metadata?.delegate_details?.position || "----"
              } At ${userData?.metadata?.delegate_details?.company || "---"}`}
            </p>
            <div className="items-center justify-between mt-1">
              <p className="text-xs text-primary">
                <span>
                  {userData?.metadata?.delegate_details?.phone ||
                    userData?.metadata?.delegate_details?.mobile ||
                    "---"}
                </span>
              </p>
              <p className="text-xs text-primary">
                <span>{userData?.email || "----"}</span>
              </p>
            </div>
            <p>{userData?.metadata?.delegate_details?.address || "----"}</p>
            <div className="mt-2">
              {userData?.metadata?.delegate_details?.website && (
                <NavLink
                  target="_blank"
                  to={userData?.metadata?.delegate_details?.website}
                >
                  <GlobalOutlined
                    className="mr-2"
                    style={{
                      fontSize: 20,
                      color: "#e2b025",
                      cursor: "pointer",
                    }}
                  />
                </NavLink>
              )}
              {userData?.metadata?.delegate_details?.linkedIn && (
                <NavLink
                  target="_blank"
                  to={userData?.metadata?.delegate_details?.linkedIn}
                >
                  <LinkedinOutlined
                    style={{
                      fontSize: 20,
                      color: "#e2b025",
                      cursor: "pointer",
                    }}
                  />
                </NavLink>
              )}
            </div>
            {userType === "sponsor" && (
              <div className="my-2">
                <Row>
                  <Col lg={24} xs={24}>
                    <p className="text-primary font-semibold text-base">
                      Company Profile:
                    </p>
                    <p className="font-semibold text-sm">
                      {userData?.metadata?.delegate_details?.company_profile ||
                        "---"}
                    </p>
                  </Col>
                  <Col lg={24} xs={24} className="mt-3">
                    <p className="text-primary font-semibold text-base">
                      Personal Profile:
                    </p>
                    <p className="font-semibold text-sm">
                      {userData?.metadata?.delegate_details?.personal_profile ||
                        "---"}
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>

        {userType === "delegate" && (
          <div className="my-4">
            <Row>
              <Col lg={24} className="mt-3">
                <p className="text-primary font-semibold text-base">
                  Key priorities for the next 6 to 18 months:
                </p>
                <p className="font-semibold text-sm">
                  {userData?.metadata?.extra_details?.priorities || "---"}
                </p>
              </Col>
              <Col lg={24} xs={24} className="mt-3">
                <p className="text-primary font-semibold text-base">
                  list any subjects or areas of interest that you would like to
                  see on the summit agenda:
                </p>
                <p className="font-semibold text-sm">
                  {userData?.metadata?.extra_details?.intrest_list || "---"}
                </p>
              </Col>
              <Col lg={12} xs={24} className="mt-3">
                <p className="text-primary font-semibold text-base">
                  Industrial Sector:
                </p>
                <p className="font-semibold text-sm">
                  {userData?.metadata?.extra_details?.indutrial_sector || "---"}
                </p>
              </Col>
              <Col lg={12} xs={24} className="mt-3">
                <p className="text-primary font-semibold text-base">
                  Annual Budget:
                </p>
                <p className="font-semibold text-sm">
                  {userData?.metadata?.extra_details?.ap_budget || "---"}
                </p>
              </Col>
              <Col lg={12} xs={24} className="mt-3">
                <p className="text-primary font-semibold text-base">
                  Scope for responsibility:
                </p>
                <p className="font-semibold text-sm">
                  {userData?.metadata?.extra_details?.scope_for_resposibility ||
                    "---"}
                </p>
              </Col>
              <Col lg={12} xs={24} className="mt-3">
                <p className="text-primary font-semibold text-base">
                  Products & Services you want to purchase in 6-18 months:
                </p>
                <p className="font-semibold text-sm">
                  {userData?.metadata?.extra_details?.products_and_services ||
                    "---"}
                </p>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PersonalDetailsModal;
