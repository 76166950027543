import React, { useEffect, useState } from "react";
import { ThemeSelect, ThemeButton } from "../components";
import { add_icon, left_arrow_icon } from "../../assets/icons/icons";
import { Drawer, Row, Col, Spin } from "antd";

const WishlistDrawer = ({
  open,
  onClose,
  btnTxt,
  onSubmit,
  isLoading,
  addIcon,
  formData,
  setFormData,
  delegates,
  sponsors,
}) => {
  const [delegateList, setDelegateList] = useState([]);
  const [sponsorList, setSponsorList] = useState([]);

  useEffect(() => {
    let list = [];
    delegates.map((delegate) => {
      list.push({
        value: delegate?.user?._id,
        label: delegate?.user?.first_name + " " + delegate?.user?.last_name,
      });
    });
    setDelegateList(list || []);
  }, [delegates]);

  useEffect(() => {
    let list = [];
    sponsors.map((delegate) => {
      list.push({
        value: delegate?.user?._id,
        label: delegate?.user?.first_name + " " + delegate?.user?.last_name,
      });
    });
    setSponsorList(list || []);
  }, [sponsors]);

  return (
    <Drawer
      width={600}
      title={btnTxt}
      placement="right"
      onClose={onClose}
      open={open}
      closeIcon={<img src={left_arrow_icon} alt="" width={18} height={18} />}
      extra={
        <ThemeButton
          onClick={onSubmit}
          className={"ml-4 w-40"}
          content={
            <>
              {isLoading ? (
                <Spin className="mt-1" />
              ) : (
                <div className="flex items-center">
                  {addIcon && (
                    <img
                      src={add_icon}
                      alt=""
                      width={18}
                      height={18}
                      className="mr-2"
                    />
                  )}
                  <p>{btnTxt}</p>
                </div>
              )}
            </>
          }
        />
      }
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col span={24} className="mb-3">
          <p className="font-semibold mb-1 text-primary">Delegate</p>
          <ThemeSelect
            placeholder={"delegate"}
            value={formData?.receiver}
            onChange={(value) => setFormData({ ...formData, receiver: value })}
            options={delegateList}
            className={"w-full"}
          />
        </Col>

        <Col span={24} className="mb-4 mt-4">
          <p className="font-semibold mb-1 text-primary">Sponsor</p>
          <ThemeSelect
            placeholder={"sponsor"}
            value={formData?.sender}
            onChange={(value) => setFormData({ ...formData, sender: value })}
            options={sponsorList}
            className={"w-full"}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default WishlistDrawer;
