import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants/constants'
import { getValueFromStorage } from '../helpers/helpers'

export const BookApi = createApi({
  reducerPath: 'book',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      headers.set('Content-type', 'application/json')
      headers.set('authorization', `Bearer ${getValueFromStorage('token')}`)
      return headers
    }
  }),
  endpoints: builder => ({
    getBook: builder.query({
      query: ({ event_id = '' }) => ({
        url: `api/book/${event_id}`,
        method: 'GET'
      })
    }),
    updateBook: builder.mutation({
      query: ({ event_id = '', body }) => ({
        url: `api/book/${event_id}`,
        method: 'PUT',
        body: body
      })
    }),
    addBook: builder.mutation({
      query: body => ({
        url: `api/book`,
        method: 'POST',
        body: body
      })
    })
  })
})

export const {
  useUpdateBookMutation,
  useLazyGetBookQuery,
  useAddBookMutation,
  useGetBookQuery
} = BookApi
