import { useState } from "react";
import { DelegateParticipantsCard } from "../../../../components/components";
import { Row, Col, Alert, Pagination } from "antd";

const Attendees = ({
  attendees,
  currentUser,
  refetchInvitation,
  invitations,
}) => {
  const currentUserAttendee =
    attendees && attendees.length
      ? attendees.filter((v) => v.user?._id == currentUser?.id)
      : [];
  const invites = invitations?.invitations?.data;
  console.log("currentUser->", currentUser);
  console.log("attendees->", attendees);
  console.log("currentUserAttendee->", currentUserAttendee);
  console.log("invites->", invites);
  const [currentPage, setCurrentPage] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20);
  const setPagination = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
    setCurrentPage(page);
  };
  return (
    <div>
      {/* {currentUser.type === "sponsor" && (
        <Row className="py-4">
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Alert
              message="Important Note"
              description={
                <>
                  <span>
                    Send meetings after carefully selecting the priority. We
                    advise you to transmit at least 1.5x of your promised
                    meeting number from AFSG in priority one, 1.5x in priority
                    two, and 1x in priority three in order to achieve the best
                    and most beneficial results
                  </span>
                </>
              }
              type="warning"
            />
          </Col>
        </Row>
      )} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {attendees &&
          attendees.slice(minValue, maxValue).map((v, i) =>
            currentUser.id !== v?.user?._id ? (
              <Col
                className="mb-4"
                key={i}
                xl={6}
                lg={6}
                md={8}
                sm={12}
                xs={24}
              >
                <DelegateParticipantsCard
                  attendee={v}
                  invites={invites}
                  currentUserType={currentUser.type}
                  currentUserId={currentUser.id}
                  currentUserAttendee={currentUserAttendee[0]}
                  refetchInvitation={refetchInvitation}
                />
              </Col>
            ) : null
          )}
      </Row>
      <div className="flex justify-center">
        <Pagination
          defaultCurrent={1}
          total={attendees?.length}
          current={currentPage}
          defaultPageSize={20}
          pageSizeOptions={[20, 40, 60, 80, 100]}
          onChange={(page, pageSize) => setPagination(page, pageSize)}
        />
      </div>
    </div>
  );
};

export default Attendees;
