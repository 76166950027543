import { View, Text, StyleSheet } from "@react-pdf/renderer";

const SponsorSlotCardPDF = ({ sponsorDetail }) => {
  const styles = StyleSheet.create({
    cardHeader: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      backgroundColor: "#E2B024",
      padding: 10,
      height: 36,
    },
    cardHeaderTextContainer: {
      display: "flex",
      flexDirection: "row",
      color: "#fff",
      textAlign: "center",
    },
    headerTitle: {
      fontSize: 12,
      fontWeight: "bold",
      color: "#fff",
    },
    headerTime: {
      color: "#fff",
      fontWeight: "semibold",
      marginTop: 3,
      fontSize: 12,
    },
    cardBody: {
      marginTop: 8,
      minHeight: 220,
      overflow: "scroll",
      backgroundColor: "#fff",
      padding: 10,
      marginBottom: 10,
    },
    cardTitle: {
      color: "#E2B024",
      fontSize: 12,
      fontWeight: "semibold",
      paddingBottom: 4,
    },
    descriptionContainer: {
      display: "flex",
      marginTop: 4,
    },
    pointContaienr: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 6,
    },
    point: {
      height: 6,
      width: 6,
      borderRadius: 3,
      backgroundColor: "#E2B024",
      marginTop: 4,
    },
    descriptionText: {
      fontSize: 10,
      marginLeft: 4,
    },
  });
  return (
    <View>
      <View style={styles.cardHeader}>
        <View style={styles.cardHeaderTextContainer}>
          <Text
            style={styles.headerTitle}
            className="bg-[#fff] text-[12px] text-[#e2b025] rounded-full  py-1 px-4 font-bold mr-10"
          >
            {sponsorDetail?.short_title}
          </Text>
          <Text
            style={styles.headerTime}
            className="text-[14px] font-semibold mt-1"
          >
            {sponsorDetail?.start_time} - {sponsorDetail?.end_time}
          </Text>
        </View>
      </View>
      <View style={styles.cardBody}>
        <Text style={styles.cardTitle}>{sponsorDetail?.title}</Text>
        {sponsorDetail?.description.map((e, i) => {
          return (
            <View style={styles.pointContaienr}>
              <View style={styles.point}></View>
              <View>
                <Text style={styles.descriptionText}>{e}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default SponsorSlotCardPDF;
