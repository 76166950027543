import { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  Dashboard,
  Logs,
  Event,
  EventDetails,
  ProfileDetails,
  Sponsors,
  SponsorsDetails,
  Delegates,
  Tickets,
  Login,
  UpcomingEvents,
  Details,
  Chats,
  UpdateProfile,
  SupportStaff,
  Announcements,
} from "../../pages/pages";
import { getUserInfo } from "../../helpers/helpers";
import { Result } from "antd";
function ScrollToTop() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function AppRouter() {
  const [user, setUser] = useState("");
  const [type, setType] = useState("");
  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/" />;
    }
    return children;
  };

  useEffect(() => {
    setAuthUser();
  }, []);

  const setAuthUser = (auth, type) => {
    const id = getUserInfo()?.id;
    const userType = getUserInfo()?.type;
    setUser(auth || id);
    setType(type || userType);
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Login setAuthUser={setAuthUser} />} />
        {type === "admin" && (
          <>
            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute user={user}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/logs"
              element={
                <ProtectedRoute user={user}>
                  <Logs />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/event"
              element={
                <ProtectedRoute user={user}>
                  <Event />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/event/:id"
              element={
                <ProtectedRoute user={user}>
                  <EventDetails />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/:user_type/profile/:user_id/:event_id"
              element={
                <ProtectedRoute user={user}>
                  <ProfileDetails />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/sponsors"
              element={
                <ProtectedRoute user={user}>
                  <Sponsors />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/:category/details"
              element={
                <ProtectedRoute user={user}>
                  <SponsorsDetails />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/delegates"
              element={
                <ProtectedRoute user={user}>
                  <Delegates />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/tickets"
              element={
                <ProtectedRoute user={user}>
                  <Tickets />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/support-staff"
              element={
                <ProtectedRoute user={user}>
                  <SupportStaff />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/announcements"
              element={
                <ProtectedRoute user={user}>
                  <Announcements />
                </ProtectedRoute>
              }
            />
          </>
        )}
        {(type === "delegate" || type === "sponsor") && (
          <>
            <Route
              exact
              path="/update-profile"
              element={
                <ProtectedRoute user={user}>
                  <UpdateProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/upcoming-events"
              element={
                <ProtectedRoute user={user}>
                  <UpcomingEvents />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/upcoming-events/details/:id"
              element={
                <ProtectedRoute user={user}>
                  <Details />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/chats"
              element={
                <ProtectedRoute user={user}>
                  <Chats />
                </ProtectedRoute>
              }
            />
          </>
        )}
        <Route
          exact
          path="*"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
