import React, { useEffect, useState } from "react";
import { Row, Col, Input, Skeleton, message, Spin } from "antd";
import {
  SecondContainer,
  UploadUserProfile,
  ThemeInput,
  ThemeSelect,
  ThemeButton,
} from "../../components/components";
import options from "../../lib/data/options.json";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserInfo, uploadImage } from "../../helpers/helpers";
import { left_arrow_icon } from "../../assets/icons/icons";
import {
  useLazyGetUsersQuery,
  useUpdateUserMutation,
} from "../../services/auth";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState();
  const [image, setImage] = useState("");
  const [getUsers, { isLoading: userLoading }] = useLazyGetUsersQuery();
  const [updateUser, { isLoading: updating }] = useUpdateUserMutation();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const response = await getUsers({
      userId: getUserInfo().id,
      currentPage: 1,
      resultPerPage: 1,
    });
    setImage(response?.data?.users?.data[0]?.picture);
    setFormData(response?.data?.users?.data[0]);
  };

  const handleImage = async (e) => {
    const res = await uploadImage(e.target.files[0]);
    setImage(res);
    setFormData({
      ...formData,
      picture: res,
    });
  };

  const onSubmit = async () => {
    const response = await updateUser({ id: getUserInfo().id, body: formData });
    if (response?.data?.status == 200) {
      message.success("Update profile successfully.");
    } else {
      message.error("Something went wrong.");
    }
  };

  return (
    <SecondContainer>
      {userLoading ? (
        <div className="bg-white rounded-xl p-5 mb-8">
          <Skeleton />
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <NavLink onClick={() => navigate(-1)} className="cursor-pointer">
              <img src={left_arrow_icon} alt="" width={22} height={22} />
            </NavLink>
            <ThemeButton
              onClick={() => onSubmit()}
              className={"ml-3 w-36"}
              content={
                <>
                  {updating ? <Spin className="mt-1" /> : <p>Update Profile</p>}
                </>
              }
            />
          </div>
          <div className="flex justify-center">
            <UploadUserProfile image={image} onChange={handleImage} />
          </div>
          <Row className="mt-6" gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"First Name"}
                placeholder={"first name"}
                name={"first_name"}
                value={formData?.first_name}
                onChange={(e) =>
                  setFormData({ ...formData, first_name: e.target.value })
                }
                labelPrimary
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Last Name"}
                placeholder={"last name"}
                name={"last_name"}
                value={formData?.last_name}
                onChange={(e) =>
                  setFormData({ ...formData, last_name: e.target.value })
                }
                labelPrimary
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Email"}
                placeholder={"email"}
                name={"email"}
                value={formData?.email}
                labelPrimary
                disabled
              />
            </Col>
            {/* <Col span={6} className="mb-4">
              <ThemeInput
                label={"Password"}
                placeholder={"password"}
                name={"password"}
                value={formData?.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                labelPrimary
              />
            </Col> */}
            <Col span={6} className="mb-4">
              <p className="text-base text-primary font-medium">Gender</p>
              <ThemeSelect
                placeholder={"Gender"}
                value={formData?.gender}
                onChange={(value) =>
                  setFormData({ ...formData, gender: value })
                }
                options={options?.genders}
                className={"w-full"}
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Company"}
                placeholder={"company"}
                name={"company"}
                value={formData?.metadata?.delegate_details?.company || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData?.metadata,
                      delegate_details: {
                        ...formData?.metadata?.delegate_details,
                        company: e.target.value,
                      },
                    },
                  })
                }
                labelPrimary
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Position"}
                placeholder={"position"}
                name={"position"}
                value={formData?.metadata?.delegate_details?.position || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData?.metadata,
                      delegate_details: {
                        ...formData?.metadata?.delegate_details,
                        position: e.target.value,
                      },
                    },
                  })
                }
                labelPrimary
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Country"}
                placeholder={"country"}
                name={"country"}
                value={formData?.metadata?.delegate_details?.country || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData?.metadata,
                      delegate_details: {
                        ...formData?.metadata?.delegate_details,
                        country: e.target.value,
                      },
                    },
                  })
                }
                labelPrimary
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Post Code"}
                placeholder={"post code"}
                name={"post_code"}
                value={formData?.metadata?.delegate_details?.post_code || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData?.metadata,
                      delegate_details: {
                        ...formData?.metadata?.delegate_details,
                        post_code: e.target.value,
                      },
                    },
                  })
                }
                labelPrimary
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Fax"}
                placeholder={"fax"}
                name={"fax"}
                value={formData?.metadata?.delegate_details?.fax || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData?.metadata,
                      delegate_details: {
                        ...formData?.metadata?.delegate_details,
                        fax: e.target.value,
                      },
                    },
                  })
                }
                labelPrimary
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Telephone"}
                placeholder={"telephone"}
                name={"telephone"}
                value={formData?.metadata?.delegate_details?.telephone || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData?.metadata,
                      delegate_details: {
                        ...formData?.metadata?.delegate_details,
                        telephone: e.target.value,
                      },
                    },
                  })
                }
                labelPrimary
              />
            </Col>
            <Col span={6} className="mb-4">
              <ThemeInput
                label={"Mobile"}
                placeholder={"mobile"}
                name={"mobile"}
                value={formData?.metadata?.delegate_details?.mobile || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData?.metadata,
                      delegate_details: {
                        ...formData?.metadata?.delegate_details,
                        mobile: e.target.value,
                      },
                    },
                  })
                }
                labelPrimary
              />
            </Col>
            <Col span={24} className="mb-4">
              <p className="font-semibold mb-1 text-primary">Address</p>
              <Input.TextArea
                placeholder="write your address here"
                name={"address"}
                value={formData?.metadata?.delegate_details?.address || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData?.metadata,
                      delegate_details: {
                        ...formData?.metadata?.delegate_details,
                        address: e.target.value,
                      },
                    },
                  })
                }
                rows={5}
                className="font-medium pt-2"
              />
            </Col>
            {getUserInfo().type === "sponsor" && (
              <Col span={24} className="mb-4">
                <p className="font-semibold mb-1 text-primary">
                  Personal Profile
                </p>
                <Input.TextArea
                  placeholder="Personal Profile"
                  name={"personal_profile"}
                  value={
                    formData?.metadata?.delegate_details?.personal_profile || ""
                  }
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      metadata: {
                        ...formData?.metadata,
                        delegate_details: {
                          ...formData?.metadata?.delegate_details,
                          personal_profile: e.target.value,
                        },
                      },
                    })
                  }
                  rows={4}
                  className="font-medium pt-2"
                />
              </Col>
            )}
            {getUserInfo().type === "sponsor" && (
              <Col span={24} className="mb-4">
                <p className="font-semibold mb-1 text-primary">
                  Company Profile
                </p>
                <Input.TextArea
                  placeholder="Company Profile"
                  name={"company_profile"}
                  value={
                    formData?.metadata?.delegate_details?.company_profile || ""
                  }
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      metadata: {
                        ...formData?.metadata,
                        delegate_details: {
                          ...formData?.metadata?.delegate_details,
                          company_profile: e.target.value,
                        },
                      },
                    })
                  }
                  rows={4}
                  className="font-medium pt-2"
                />
              </Col>
            )}
          </Row>
        </div>
      )}
    </SecondContainer>
  );
};

export default UpdateProfile;
