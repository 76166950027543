import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { no_agenda } from "../../assets/images/images";
import KeyNoteCardPDF from "./keyNoteCardPDF";
import BreakCardPDF from "./breakCardPDF";
import NetworkingSessionCardPDF from "./NetworkSessionCardPDF";
import SponsorsPresentationCardPDF from "./sponsorsPresentationCardPDF";
import SponsorSlotCardPDF from "./sponsorSlotCardPDF";
import PanelDiscutionCardPDF from "./panelDiscussionCardPDF";
import options from "../../lib/data/options.json";
import { getUserInfo } from "../../helpers/helpers";

const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 45,
    paddingHorizontal: 20,
  },
  dayContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 8,
    backgroundColor: "#E2B024",
  },
  dayText: {
    textAlign: "center",
    fontSize: 36,
    color: "#fff",
    fontWeight: "extrabold",
  },
  emptyContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    height: 600,
    marginTop: 8,
    borderRadius: 12,
  },
  noAgendaImage: {
    height: 450,
    width: 450,
  },
  noAgendaTextContainer: {
    marginTop: 6,
  },
  noAgendaText: {
    fontWeight: "bold",
    fontSize: 26,
  },
  noAgendaPrimaryText: {
    marginLeft: 2,
    color: "#E2B024",
  },

  agendaContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    justifyContent: "space-between",
  },
  cardContaienr: {
    display: "flex",
    marginBottom: 16,
    width: "100%",
  },
});

const PersonalAgendaPDF = ({ agenda, schedules, invitations }) => {
  const [agendas, setAgendas] = useState([]);
  useEffect(() => {
    let list = [];
    const data = schedules;
    for (var i = 0; i < data?.length; i++) {
      if (data[i]?.type === "networking") {
        let meetingsData = data[i];
        meetingsData?.meetings?.map((meeting, index) => {
          let isInviteAvailable = invitations?.find((invitation) => {
            if (
              meeting?.day == invitation?.meeting?.day &&
              meeting?.start_time == invitation?.meeting?.start_time &&
              meeting?.end_time == invitation?.meeting?.end_time
            ) {
              return invitation;
            }
          });
          if (isInviteAvailable) {
            let meetingsList = [...meetingsData.meetings];

            isInviteAvailable?.receiver?._id !== getUserInfo().id
              ? (meetingsList[index] = {
                  ...meetingsList[index],
                  meeting_user: {
                    picture: isInviteAvailable?.receiver?.picture,
                    full_name:
                      isInviteAvailable?.receiver?.first_name +
                      " " +
                      isInviteAvailable?.receiver?.last_name,
                  },
                })
              : (meetingsList[index] = {
                  ...meetingsList[index],
                  meeting_user: {
                    picture: isInviteAvailable?.sender?.picture,
                    full_name:
                      isInviteAvailable?.sender?.first_name +
                      " " +
                      isInviteAvailable?.sender?.last_name,
                  },
                });
            meetingsData = {
              ...meetingsData,
              meetings: meetingsList,
            };
          }
        });
        list.push(meetingsData);
      } else {
        list.push(data[i]);
      }
    }
    setAgendas(list);
  }, [schedules]);

  const Agenda = ({ list, index }) => {
    return (
      <View style={styles.agendaContainer} key={index}>
        {list &&
          list.length &&
          list.map((v, i) => {
            if (v.type == "keynote") {
              if (agenda.findIndex((item) => v._id === item?._id) !== -1) {
                return (
                  <View key={i} style={styles.cardContaienr}>
                    <KeyNoteCardPDF keynote={v} />
                  </View>
                );
              }
            } else if (v.type == "panel-discussion") {
              return (
                <View key={i} style={styles.cardContaienr}>
                  <PanelDiscutionCardPDF panelDetails={v} />
                </View>
              );
            } else if (
              v.type == "coffe-break" ||
              v.type == "lunch-break" ||
              v.type == "cocktail-break" ||
              v.type == "breakfast" ||
              v.type == "registration"
            ) {
              return (
                <View key={i} style={styles.cardContaienr}>
                  <BreakCardPDF breakDetail={v} />
                </View>
              );
            } else if (v.type == "networking") {
              return (
                <View key={i} style={styles.cardContaienr}>
                  <NetworkingSessionCardPDF networkingDetail={v} />
                </View>
              );
            } else if (v.type == "sponsor-presentation") {
              if (agenda.findIndex((item) => v._id === item?._id) !== -1) {
                return (
                  <View key={i} style={styles.cardContaienr}>
                    <SponsorsPresentationCardPDF sponsorDetail={v} />
                  </View>
                );
              }
            } else if (v.type == "sponsor-slot") {
              if (agenda.findIndex((item) => v._id === item?._id) !== -1) {
                return (
                  <View key={i} style={styles.cardContaienr}>
                    <SponsorSlotCardPDF sponsorDetail={v} />
                  </View>
                );
              }
            }
          })}
      </View>
    );
  };
  return (
    <Document>
      <Page style={styles.page} wrap={false}>
        <View>
          {agendas.length ? (
            <>
              {options?.days?.map((agendaDay, index) => {
                return (
                  <View key={index}>
                    <View style={styles.dayContainer}>
                      <Text style={styles.dayText}>{agendaDay?.value}</Text>
                    </View>
                    <View>
                      <Agenda
                        list={agendas.filter(
                          (item) => item.day == agendaDay?.value
                        )}
                        index={index}
                      />
                    </View>
                  </View>
                );
              })}
            </>
          ) : (
            <View style={styles.emptyContainer}>
              <Image source={no_agenda} style={styles.noAgendaImage} />
              <View style={styles.noAgendaTextContainer}>
                <Text style={styles.noAgendaText}>
                  Create Your
                  <Text style={styles.noAgendaPrimaryText}>Agenda</Text>
                </Text>
              </View>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PersonalAgendaPDF;
