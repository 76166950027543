import { Button, Empty, Modal, message } from 'antd'
import {
  SecondContainer,
  ThemeButton,
  SponsorCard
} from '../../components/components'
import { useNavigate } from 'react-router-dom'
import { useGetEventsQuery } from '../../services/events'
import { useGetInvitationQuery } from '../../services/invitation'
import {
  addKeyToStorage,
  getUserInfo,
  getValueFromStorage
} from '../../helpers/helpers'
import { useEffect, useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useGetUsersQuery } from '../../services/auth'
import { useGetBookQuery, useLazyGetBookQuery } from '../../services/book'

const UpcomingEvents = () => {
  const navigate = useNavigate()
  const [isMessageShow, setIsMessageShow] = useState(false)
  const [isOpenSponsorCard, setIsOpenSponsorCard] = useState(false)
  const { data: events, isLoading: eventLoading } = useGetEventsQuery({
    attendeeId: getUserInfo().id,
    currentPage: 1,
    resultPerPage: 1,
    upcoming: true
  })

  const { data: user, isLoading: fetchUser } = useGetUsersQuery({
    userId: getUserInfo().id
  })

  const [getBook, { data: book, isLoading: fetchBook }] = useLazyGetBookQuery()

  const {
    data: invitations,
    isLoading: invitationLoading,
    refetch: refetchInvitation
  } = useGetInvitationQuery({
    eventId: events?.events?.data[0]?._id,
    sender: getUserInfo().id,
    receiver: getUserInfo().id
  })

  console.log('invitations', invitations)

  const {
    data: onGoingEvent,
    isLoading: eventOnGoingLoading
  } = useGetEventsQuery({
    attendeeId: getUserInfo().id,
    currentPage: 1,
    resultPerPage: 1,
    ongoing: true
  })

  const [upcomingEvent, setUpcomingEvent] = useState({})

  useEffect(() => {
    if (events?.events?.data.length > 0) {
      setUpcomingEvent(events?.events?.data[0])
    } else {
      setUpcomingEvent({ ...onGoingEvent?.events?.data[0] })
    }
    getBook({
      event_id:
        events?.events?.data[0]?._id || onGoingEvent?.events?.data[0]?._id
    })
  }, [eventOnGoingLoading, eventLoading])

  useEffect(() => {
    const message = getValueFromStorage('message')
    const invitationList = invitations?.invitations?.data || []
    const meetingNotification = []
    for (var i = 0; i < invitationList.length; i++) {
      const status = invitationList[i].status
      if (
        invitationList[i].sender &&
        invitationList[i].sender._id !== getUserInfo().id
      ) {
        meetingNotification.push(invitationList[i])
      }
    }
    if (!message) {
      const timer = setTimeout(() => {
        meetingNotification?.length > 0 && setIsMessageShow(true)
        addKeyToStorage('message', true)
      }, 6000)

      return () => clearTimeout(timer)
    }
  }, [eventLoading, invitationLoading, invitations])

  return (
    <SecondContainer>
      <SponsorCard
        open={isOpenSponsorCard}
        onClose={() => setIsOpenSponsorCard(false)}
        data={{ user: user?.users?.data[0] }}
        eventData={upcomingEvent}
      />
      <Modal centered width={400} open={isMessageShow} footer={false}>
        <p className='text-lg font-semibold text-black'>
          <InfoCircleOutlined className={'text-blue-500'} /> Pending Invites
        </p>
        <p className='text-base my-2'>
          Please accept the invites sent by the Sponsors
        </p>
        <div className='flex justify-end'>
          <Button onClick={() => setIsMessageShow(false)} className='mx-2 h-9'>
            Cancel
          </Button>
          <ThemeButton
            content={'Go'}
            onClick={() => {
              setIsMessageShow(false)
              navigate(`/upcoming-events/details/${upcomingEvent._id}?tab=4`)
            }}
          />
        </div>
      </Modal>
      <div className='bg-white rounded-xl p-5 mb-8'>
        <div className='flex justify-between items-center'>
          <p className='font-semibold text-3xl'>Upcoming Event</p>
          <div className='flex  items-center'>
            <ThemeButton
              content={'Download Card'}
              className={'ml-6 md:mt-0 md:ml-14'}
              onClick={() => setIsOpenSponsorCard(true)}
            />
            {book?.book?.pdf ? (
              <ThemeButton
                content={'Download Book'}
                className={'ml-6 md:mt-0 md:ml-6'}
                onClick={() => window.open(book?.book?.pdf, '_blank')}
              />
            ) : null}
          </div>
        </div>
        {events?.events?.data?.length || onGoingEvent?.events?.data[0] ? (
          <div
            onClick={() => navigate(`details/${upcomingEvent._id}`)}
            className='cursor-pointer'
          >
            <div className='mt-4 relative'>
              <img
                src={upcomingEvent?.banner}
                alt=''
                className='w-full h-[300px] object-cover rounded-xl'
              />
              <div className='md:block hidden w-full absolute bg-[rgba(0,0,0,0.8)] h-[300px] top-0 rounded-xl' />
              <div className='mt-3 md:mt-0 static md:absolute top-0 md:right-24 md:px-0 flex flex-col w-full md:w-auto md:items-center justify-between md:justify-center h-full'>
                <div>
                  <p className='md:text-white w-[100%] font-bold text-lg md:text-4xl'>
                    {upcomingEvent?.name}
                  </p>
                  <div className='flex justify-end md:mt-3'>
                    <p className='md:text-white mr-5 font-medium'>
                      {new Date(upcomingEvent?.start_date).toDateString()}
                    </p>
                    <p className='md:text-white font-medium'>
                      {new Date(upcomingEvent?.end_date).toDateString()}
                    </p>
                  </div>
                  <div className='flex justify-end md:mt-3'>
                    <p className='md:text-white  font-medium'>
                      {upcomingEvent?.location}
                    </p>
                  </div>
                </div>
                <div className='flex md:w-full justify-end mt-3 md:ml-0 md:mt-10'>
                  <ThemeButton
                    content={'See Details'}
                    className={
                      'md:!h-10 md:w-[50%] w-full ml-6 md:mt-0 md:ml-14'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='p-20'>
            <Empty />
          </div>
        )}
      </div>
    </SecondContainer>
  )
}

export default UpcomingEvents
