import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { break_image, task_done } from "../../assets/images/images";

const BreakCardPDF = ({ breakDetail }) => {
  const styles = StyleSheet.create({
    cardContainer: {
      backgroundColor: "#fff",
      border: 0.5,
      borderColor: "#ccc",
    },
    cardHeader: {
      backgroundColor: "#E2B024",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 4,
    },
    headerText: {
      display: "flex",
      color: "#fff",
      flexDirection: "column",
      alignItems: "center",
    },
    headerTitle: {
      fontSize: 12,
      fontWeight: "bold",
      color: "#fff",
      textAlign: "center",
    },
    headerTimeStamp: {
      color: "#fff",
      textAlign: "center",
      fontWeight: "semibold",
      marginTop: 2,
      fontSize: 12,
    },
    cardBody: {
      height: 220,
      backgroundColor: "#fff",
      padding: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    breakImage: {
      width: 140,
      height: 140,
    },
  });
  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardHeader}>
        <View style={styles.headerText}>
          <Text style={styles.headerTitle}>{breakDetail?.title}</Text>
          <Text style={styles.headerTimeStamp}>
            {breakDetail?.start_time} - {breakDetail?.end_time}
          </Text>
        </View>
      </View>
      <View
        style={styles.cardBody}
        className="bg-white p-4 shadow-md shadow-gray-300 flex items-center justify-center"
      >
        {breakDetail.type == "registration" ? (
          <Image source={task_done} alt="" style={styles.breakImage} />
        ) : (
          <Image source={break_image} alt="" style={styles.breakImage} />
        )}
      </View>
    </View>
  );
};

export default BreakCardPDF;
