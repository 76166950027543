import { useState, useEffect } from 'react'
import {
  delegates_white_icon,
  add_icon,
  edit_icon,
  right_arrow_icon,
  delete_icon,
  eye_regular
} from '../../assets/icons/icons'
import { message } from 'antd'
import {
  Container,
  ThemeTable,
  ThemeButton,
  DelegateDrawer,
  ThemeInput
} from '../../components/components'
import { user, userFemale } from '../../assets/images/images'
import { NavLink } from 'react-router-dom'
import {
  useGetUsersQuery,
  useRegisterUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useLazyGetUserStatsQuery
} from '../../services/auth'
import ShowStatsModal from '../../components/showStatsModal/showStatsModal'

const Delegates = () => {
  const [open, setOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [resultPerPage, setResultPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [mode, setMode] = useState('create')
  const [editID, setEditID] = useState('')
  const [search, setSearch] = useState('')
  const [deleteUser, { isLoading: deleting }] = useDeleteUserMutation()
  const { data, error, isLoading, refetch } = useGetUsersQuery({
    currentPage,
    resultPerPage,
    userType: 'delegate',
    search
  })
  const [registerUser, { isLoading: regestiring }] = useRegisterUserMutation()
  const [updateUser, { isLoading: updating }] = useUpdateUserMutation()
  const [getUserStats] = useLazyGetUserStatsQuery()

  const [delegateStats, setDelegateStats] = useState(null)
  const [showDelegateStatsModal, setShowDelegateStatsModal] = useState(false)
  const [userInfo, setUserInfo] = useState(null)

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    picture: '',
    user_type: 'delegate',
    metadata: {
      delegate_details: {
        company: '',
        position: '',
        country: '',
        post_code: '',
        fax: '',
        phone: '',
        mobile: '',
      },
      atendee_details: {
        name: '',
        position: '',
        email_and_tel: '',
        company: ''
      },
      extra_details: {
        name: '',
        position: '',
        line_manager: '',
        priorities: '',
        intrest_list: '',
        focused_therapeutic_area: "",
        indutrial_sector: [],
        ap_budget: null,
        scope_for_resposibility: null,
        products_and_services: null
      }
    }
  })
  const [image, setImage] = useState('')

  useEffect(() => {
    setTotal(data?.users?.total || 0)
  }, [isLoading])

  const onClose = () => {
    setOpen(false)
    setFormData({
      first_name: '',
      last_name: '',
      email: '',
      picture: '',
      user_type: 'delegate',
      metadata: {
        delegate_details: {
          company: '',
          position: '',
          country: '',
          post_code: '',
          fax: '',
          phone: '',
          mobile: '',
        },
        atendee_details: {
          name: '',
          position: '',
          email_and_tel: '',
          company: ''
        },
        extra_details: {
          name: '',
          position: '',
          line_manager: '',
          priorities: '',
          intrest_list: '',
          focused_therapeutic_area: "",
          indutrial_sector: [],
          ap_budget: null,
          scope_for_resposibility: null,
          products_and_services: null
        }
      }
    })
    setImage('')
    setMode('create')
  }

  const onSubmit = async () => {
    let response
    if (mode === 'create') {
      response = await registerUser(formData)
    } else {
      response = await updateUser({ id: editID, body: formData })
    }
    if (response?.data?.status == 200) {
      message.success(
        `Delegate ${mode === 'create' ? 'registered' : 'updated'} successfully.`
      )
      onClose()
      refetch()
      return
    }
    message.error(response?.error?.data?.message || 'Something went wrong')
  }

  const onEdit = data => {
    setEditID(data?._id)
    setFormData(data)
    setImage(data?.picture)
    setMode('edit')
    setOpen(true)
  }

  const seeDetail = async data => {
    const stats = await getUserStats({ id: data._id })
    setDelegateStats(stats?.data)
    console.log(stats?.data)
    setUserInfo(data)
    setShowDelegateStatsModal(true)
  }

  const onDelete = async data => {
    try {
      const res = await deleteUser({ id: data._id })
      if (res.data.status === 200) {
        message.success(res.data.message)
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data, _, index) => (
        <p className='text-center font-medium'>{index + 1}</p>
      )
    },
    {
      title: 'Picture',
      dataIndex: 'picture',
      key: 'picture',
      render: (picture, data) => (
        <div className='flex justify-center'>
          <img
            className='rounded-full object-cover'
            src={picture || (data.gender === 'male' ? user : userFemale)}
            alt=''
            style={{ height: '45px', width: '45px' }}
          />
        </div>
      )
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (_, data) => (
        <p className='text-center font-medium'>{`${data.first_name} ${data.last_name}`}</p>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: email => <p className='text-center font-medium'>{email}</p>
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      render: password => <p className='text-center font-medium'>{password}</p>
    },
    {
      title: 'In Action',
      dataIndex: '_id',
      key: '_id',
      width: '10%',
      render: (text, data) => (
        <span className='flex justify-center items-center'>
          <span className='mx-2 cursor-pointer' onClick={() => seeDetail(data)}>
            <img src={eye_regular} alt='' width={15} />
          </span>
          <span className='mx-2 cursor-pointer' onClick={() => onEdit(data)}>
            <img src={edit_icon} alt='' width={15} />
          </span>
          <span className='mx-2 cursor-pointer' onClick={() => onDelete(data)}>
            <img src={delete_icon} alt='' width={15} />
          </span>
        </span>
      )
    }
  ]

  return (
    <Container
      selected={'4'}
      header={
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <div className='bg-primary h-9 w-9 rounded-full flex justify-center items-center'>
              <img src={delegates_white_icon} width={17} height={17} alt='' />
            </div>
            <p className='ml-2 font-semibold text-2xl'>Delegates</p>
          </div>
          <div className='flex items-center'>
            <ThemeInput
              onKeyDown={e => e.keyCode === 13 && setSearch(e.target.value)}
              className='mb-1'
              placeholder='Search delegates'
            />
            <ThemeButton
              className={'ml-3'}
              content={
                <div className='flex items-center'>
                  <img src={add_icon} alt='' width={18} height={18} />
                  <p className='ml-2'>Add Delegates</p>
                </div>
              }
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
      }
    >
      <DelegateDrawer
        open={open}
        onClose={onClose}
        btnTxt={mode === 'create' ? 'Add Delegate' : 'Update Delegate'}
        addIcon={mode === 'create' ? add_icon : null}
        onSubmit={onSubmit}
        isLoading={mode === 'create' ? regestiring : updating}
        formData={formData}
        setFormData={e => setFormData(e)}
        image={image}
        setImage={e => setImage(e)}
        mode={mode}
      />
      <ThemeTable
        pagination={{ total, setCurrentPage, setResultPerPage }}
        columns={columns}
        data={data?.users?.data || []}
      />

      <ShowStatsModal
        isModalOpen={showDelegateStatsModal}
        stats={delegateStats}
        user={userInfo}
        onClose={() => setShowDelegateStatsModal(false)}
      />
    </Container>
  )
}

export default Delegates
