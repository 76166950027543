import React from "react";
import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import { user } from "../../assets/images/images";
const NetworkingSessionCardPDF = ({ networkingDetail }) => {
  const styles = StyleSheet.create({
    cardContainer: {
      border: 0.5,
      borderColor: "#ccc",
    },
    cardHeader: {
      backgroundColor: "#E2B024",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 4,
    },
    headerTextContaienr: {
      display: "flex",
      color: "#fff",
      flexDirection: "column",
      alignItems: "center",
    },
    headerTitle: {
      fontSize: 12,
      fontWeight: "bold",
      color: "#fff",
    },
    headerTimeStamp: {
      color: "#fff",
      fontWeight: "semibold",
      marginTop: 3,
      fontSize: 12,
    },
    headerStaticText: {
      color: "#fff",
      fontWeight: "semibold",
      marginTop: 2,
      fontSize: 10,
    },
    cardBody: {
      backgroundColor: "#Fff",
      padding: 8,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      minHeight: 220,
    },
    cardBodyHeading: {
      color: "#E2B024",
      fontSize: 12,
      fontWeight: "bold",
    },
    meetingSlot: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    slotTitleContaienr: {
      width: "30%",
    },
    slotTitle: {
      fontSize: 12,
      fontWeight: "semibold",
    },
    slotSubTitle: {
      fontSize: 10,
    },
    spaceContainer: {
      width: "37.5%",
    },
    space: {
      border: 1,
      borderStyle: "dashed",
      borderColor: "#000000cc",
    },
    slotTimeContainer: {
      display: "flex",
      flexDirection: "columns",
      alignItems: "center",
      width: "33.3%",
    },
    meetingUserContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 6,
    },
    meetingUserImage: {
      width: 24,
      height: 24,
      borderRadius: 15,
    },
    meetingUserLabel: {
      fontSize: 8,
      fontWeight: "medium",
      marginLeft: 4,
    },
    slotTime: {
      fontSize: 8,
      textAlign: "right",
      fontWeight: "medium",
    },
  });
  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardHeader}>
        <View style={styles.headerTextContaienr}>
          <Text style={styles.headerTitle}>{networkingDetail?.title}</Text>
          <Text style={styles.headerTimeStamp}>
            {networkingDetail?.start_time} - {networkingDetail?.end_time}
          </Text>
          <Text style={styles.headerStaticText}>(Exibition Hall)</Text>
        </View>
      </View>
      <View style={styles.cardBody}>
        <Text style={styles.cardBodyHeading}>Networking Sessions</Text>
        {networkingDetail.meetings.map((v, i) => {
          return (
            <View style={styles.meetingSlot} key={i}>
              <View style={styles.slotTitleContaienr}>
                <Text style={styles.slotTitle}>{v.title}</Text>
                <Text style={styles.slotSubTitle}>
                  (Pre Arrange Meeting Area)
                </Text>
              </View>
              <View style={styles.spaceContainer}>
                <View style={styles.space}></View>
              </View>
              <View style={styles.slotTimeContainer}>
                {v?.meeting_user && (
                  <View style={styles.meetingUserContainer}>
                    <Image
                      src={v?.meeting_user?.picture || user}
                      alt="user"
                      style={styles.meetingUserImage}
                    />
                    <Text style={styles.meetingUserLabel}>
                      {v?.meeting_user?.full_name}
                    </Text>
                  </View>
                )}
                <Text style={styles.slotTime}>
                  {v.start_time} | {v.end_time}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default NetworkingSessionCardPDF;
