import React from "react";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { user } from "../../assets/images/images";

const KeyNoteCardPDF = ({ keynote }) => {
  const styles = StyleSheet.create({
    cardContainer: {
      border: 0.5,
      borderColor: "#ccc",
    },
    cardHeader: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      backgroundColor: "#E2B024",
      padding: 10,
      height: 36,
    },
    cardHeaderTextContainer: {
      display: "flex",
      flexDirection: "row",
      color: "#fff",
      textAlign: "center",
    },
    cardHeaderTitle: {
      backgroundColor: "#fff",
      fontSize: 10,
      color: "#e2b025",
      fontWeight: "800",
      paddingHorizontal: 10,
      paddingVertical: 4,
      marginRight: 16,
      borderRadius: 12,
      lineHeight: 1.5,
    },
    cardHeaderTime: {
      fontSize: 12,
      fontWeight: "semibold",
      marginTop: 2,
    },
    cardBody: {
      marginTop: 8,
      minHeight: 220,
      overflow: "scroll",
      backgroundColor: "#fff",
      padding: 10,
      marginBottom: 10,
    },
    cardTitle: {
      color: "#E2B024",
      fontSize: 12,
      fontWeight: "semibold",
      paddingBottom: 4,
    },
    descriptionContainer: {
      display: "flex",
      marginTop: 4,
    },
    pointContaienr: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 6,
    },
    point: {
      height: 6,
      width: 6,
      borderRadius: 3,
      backgroundColor: "#E2B024",
      marginTop: 4,
    },
    descriptionText: {
      fontSize: 10,
      marginLeft: 4,
    },
    speakerContainer: {
      marginTop: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    speakerImage: {
      width: 30,
      height: 30,
      borderRadius: 15,
    },
    speakerTextContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 6,
    },
    speakerName: {
      fontSize: 10,
      fontWeight: "extrabold",
      Color: "#E2B024",
      marginBottom: 2,
    },
    speakerPosition: {
      fontSize: 8,
      marginBottom: 2,
    },
    speakerCompany: {
      fontSize: 8,
      fontWeight: "extrabold",
      Color: "#E2B024",
    },
  });
  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardHeader}>
        <View style={styles.cardHeaderTextContainer}>
          <Text style={styles.cardHeaderTitle}>{keynote?.short_title}</Text>

          <Text
            style={styles.cardHeaderTime}
          >{`${keynote.start_time} - ${keynote.end_time}`}</Text>
        </View>
      </View>
      <View style={styles.cardBody}>
        <Text style={styles.cardTitle}>{keynote?.title}</Text>
        <View
          style={styles.descriptionContainer}
          className="flex items-start mt-2"
        >
          {keynote?.description.length > 0 &&
            keynote?.description?.map((data, index) => {
              return (
                <View style={styles.pointContaienr}>
                  <View style={styles.point}></View>
                  <View span={23} className="mb-3">
                    <Text style={styles.descriptionText}>{data}</Text>
                  </View>
                </View>
              );
            })}
        </View>
        <View
          style={styles.speakerContainer}
          className="mt-5 flex items-center"
        >
          <Image
            source={keynote?.speaker?.picture || user}
            style={styles.speakerImage}
            alt=""
          />
          <View style={styles.speakerTextContainer}>
            <Text style={styles.speakerName}>
              {keynote?.speaker?.first_name} {keynote?.speaker?.last_name}
            </Text>
            <Text style={styles.speakerPosition}>
              {keynote?.speaker?.metadata?.delegate_details.position || "----"}
            </Text>
            <Text style={styles.speakerCompany}>
              {keynote?.speaker?.metadata?.delegate_details?.company || "----"}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default KeyNoteCardPDF;
