import { Col, Row } from "antd";
import { user } from "../../assets/images/images";
import { edit_white_icon, delete_white_icon } from "../../assets/icons/icons";
import { showDeletePopup } from "../../helpers/helpers";

const PanelDiscutionCard = ({
  panelDetails,
  onEdit,
  onDelete,
  isAdmin,
  isUser,
  hall,
}) => {
  return (
    <div className="relative">
      <div className="bg-primary flex items-center justify-between py-4 px-4 static">
        <div className="flex text-white items-center">
          <p className="bg-[#fff] text-[12px] text-[#e2b025] rounded-full  py-1 px-4 font-bold mr-6">
            {panelDetails?.short_title}
          </p>

          <p className="text-[14px] font-semibold mt-1">{`${panelDetails.start_time} - ${panelDetails.end_time}`}</p>
        </div>
        {isAdmin && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer w-8"
              onClick={() => onEdit(panelDetails)}
            >
              <img src={edit_white_icon} alt="" width={18} height={18} />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => showDeletePopup(() => onDelete(panelDetails))}
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
        {/* {isUser && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer"
              onClick={() =>
                showDeletePopup(() => onDelete(panelDetails.agenda_id))
              }
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )} */}
      </div>
      <div
        className="bg-white p-4 shadow-md shadow-gray-300"
        style={{ minHeight: 234 }}
      >
        <p className="text-primary text-[16px] font-semibold pb-1">
          {panelDetails?.title}
        </p>
        <div className="mt-5 flex items-center">
          <img
            width="50"
            height="50"
            src={panelDetails?.moderator?.picture || user}
            alt=""
            className="w-[50px] h-[50px] rounded-full object-cover"
          />
          <div className="ml-3">
            <p className="text-primary font-extrabold text-base md:text-lg">
              {panelDetails?.moderator?.first_name}{" "}
              {panelDetails?.moderator?.last_name} (Moderator)
            </p>
            <p className="text-xs">
              {panelDetails?.moderator?.metadata?.delegate_details?.position ||
                "----"}
            </p>
            <p className="text-primary text-xs font-semibold">
              {panelDetails?.moderator?.metadata?.delegate_details?.company ||
                "----"}
            </p>
          </div>
        </div>
        <br />
        <hr />
        {panelDetails.panellist.map((v, i) => {
          return (
            <div key={i} className="mt-5 flex items-center">
              <img
                width="50"
                height="50"
                src={v.picture || user}
                alt=""
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <div className="ml-3">
                <p className="text-primary font-extrabold text-base md:text-lg">
                  {v.first_name} {v.last_name} (Panellist)
                </p>
                <p className="text-xs">
                  {v.metadata?.delegate_details?.position || "----"}
                </p>
                <p className="text-primary text-xs font-semibold">
                  {v.metadata?.delegate_details?.company || "----"}
                </p>
              </div>
            </div>
          );
        })}
        {hall === "Hall B" && (
          <div className="absolute bottom-0 left-0 right-0 bg-gray-50 font-bold text-center text-[#e2b025] mt-4">
            <hr className="mb-2" />
            All Panel Discussions will be in Hall A
            <hr className="mb-1" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelDiscutionCard;
