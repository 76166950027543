import { camera_icon } from "../../assets/icons/icons";

const UploadUserProfile = ({ image, onChange }) => {
  return (
    <>
      {image ? (
        <span className="relative">
          <img
            style={{ width: 120, height: 120, objectFit: "cover" }}
            src={image}
            alt={"user image"}
            className="w-32 h-32 rounded-full"
          />
          <div className="bg-themeGray-light h-9 w-9 rounded-full flex justify-center items-center absolute bottom-0 right-0 cursor-pointer">
            <label htmlFor="uplode">
              <input
                type="file"
                onChange={onChange}
                // onDrag={onChange}
                className="hidden"
                name="uplode"
                id="uplode"
                multiple={false}
              />
              <img src={camera_icon} alt="" width={18} />
            </label>
          </div>
        </span>
      ) : (
        <div className="bg-primary h-32 w-32 rounded-full relative">
          <div className="bg-themeGray-light h-9 w-9 rounded-full flex justify-center items-center absolute bottom-0 right-0 cursor-pointer">
            <label htmlFor="uplode">
              <input
                type="file"
                onChange={onChange}
                // onDrag={onChange}
                className="hidden"
                name="uplode"
                id="uplode"
                multiple={false}
              />
              <img src={camera_icon} alt="" width={18} />
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadUserProfile;
