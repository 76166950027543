import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const ChatApi = createApi({
  reducerPath: "chat",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getChat: builder.query({
      query: ({ user = "", event = "" }) => ({
        url: "api/chat",
        method: "GET",
        params: {
          user,
          event,
          result_per_page: 100,
        },
      }),
    }),
    addChat: builder.mutation({
      query: (body) => ({
        url: `api/chat`,
        method: "POST",
        body: body,
      }),
    }),
    deleteChat: builder.mutation({
      query: (id) => ({
        url: `api/chat/${id}`,
        method: "DELETE",
      }),
    }),
    updateChat: builder.mutation({
      query: (id) => ({
        url: `api/chat/${id}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetChatQuery,
  useAddChatMutation,
  useDeleteChatMutation,
  useUpdateChatMutation,
} = ChatApi;
