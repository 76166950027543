import { useEffect, useState } from 'react'
import { Col, Drawer, Row, Spin, Input } from 'antd'
import { left_arrow_icon, add_icon } from '../../assets/icons/icons'
import { ThemeButton, ThemeInput, ThemeSelect } from '../components'
import { useLazyGetEventsQuery } from '../../services/events'

const AnnouncementDrawer = ({
  open,
  btnTxt,
  addIcon,
  onSubmit,
  isLoading,
  formData,
  setFormData,
  onClose
}) => {
  const [getEvents, { isLoading: fetchingEvents }] = useLazyGetEventsQuery()
  const [eventsList, setEventsList] = useState([])

  useEffect(() => {
    fetchEvents()
  }, [])

  const fetchEvents = async () => {
    let list = []
    const res = await getEvents({
      currentPage: 1,
      resultPerPage: 10000,
      ongoing: true
    })

    res?.data?.events?.data?.map(event => {
      list.push({ label: event?.name, value: event?._id })
    })
    setEventsList(list || [])
  }

  return (
    <Drawer
      width={600}
      title={btnTxt}
      placement='right'
      onClose={onClose}
      open={open}
      closeIcon={<img src={left_arrow_icon} alt='' width={18} height={18} />}
      extra={
        <ThemeButton
          onClick={onSubmit}
          className={'ml-4 w-52'}
          content={
            <>
              {isLoading ? (
                <Spin className='mt-1' />
              ) : (
                <div className='flex items-center'>
                  {addIcon && (
                    <img
                      src={add_icon}
                      alt=''
                      width={18}
                      height={18}
                      className='mr-2'
                    />
                  )}
                  <p>{btnTxt}</p>
                </div>
              )}
            </>
          }
        />
      }
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col span={24} className='mb-3'>
          <p className='font-semibold mb-1 text-primary'>Event</p>
          <ThemeSelect
            placeholder={'event'}
            value={formData?.event}
            onChange={value => setFormData({ ...formData, event: value })}
            options={eventsList}
            className={'w-full'}
          />
        </Col>

        <Col span={24} className='mb-4 mt-4'>
          <ThemeInput
            label={'Title'}
            placeholder={'title'}
            name={'title'}
            value={formData.title}
            onChange={e => setFormData({ ...formData, title: e.target.value })}
            labelPrimary
          />
        </Col>

        <Col span={24} className='mb-4'>
          <p className='font-semibold mb-1 text-primary'>Description</p>
          <Input.TextArea
            placeholder='witer here your annoucement'
            name={'description'}
            value={formData?.description}
            onChange={e =>
              setFormData({ ...formData, description: e.target.value })
            }
            rows={5}
            className='font-medium pt-2'
          />
        </Col>
      </Row>
    </Drawer>
  )
}

export default AnnouncementDrawer
