import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Space } from "antd";

const ThemeDropdown = ({
  text,
  items,
  className,
  large,
  setValue,
  styles,
  labelPrimary,
  textMd,
  label,
}) => {
  const handleMenuClick = (e) => {
    setValue(e.key);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1`}
      >
        {label}
      </p>
      <Dropdown menu={menuProps} className={className}>
        <Button style={styles} className={large && "h-10"}>
          <Space className="flex items-center justify-between">
            {text}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default ThemeDropdown;
