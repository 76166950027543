import { Col, Row } from "antd";
import { edit_white_icon, delete_white_icon } from "../../assets/icons/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { useState } from "react";
import { ThemeButton } from "../components";

const SponsorslotCard = ({
  sponsorDetail,
  onEdit,
  onDelete,
  isAdmin,
  isUser,
  isAdded,
  onAddAgenda,
}) => {
  const [count, setCount] = useState(2);
  return (
    <div className="relative">
      <div className="bg-primary flex items-center justify-between py-4 px-4 static">
        <div className="flex text-white items-center">
          <p className="bg-[#fff] text-[12px] text-[#e2b025] rounded-full  py-1 px-4 font-bold mr-10">
            {sponsorDetail?.short_title}
          </p>
          <p className="text-[14px] font-semibold mt-1">
            {sponsorDetail?.start_time} - {sponsorDetail?.end_time}
          </p>
        </div>
        {isAdmin && !isAdded && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer w-8"
              onClick={() => onEdit(sponsorDetail)}
            >
              <img src={edit_white_icon} alt="" width={18} height={18} />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => showDeletePopup(() => onDelete(sponsorDetail))}
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
        {!isAdmin && isAdded !== -1 && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer"
              onClick={() =>
                showDeletePopup(() => onDelete(sponsorDetail.agenda_id))
              }
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )}
      </div>
      {(isAdmin || isAdded !== -1) && (
        <div
          className="bg-white rounded-bl-lg rounded-br-lg p-4 shadow-md shadow-gray-300"
          style={{ minHeight: 200 }}
        >
          <p className="text-primary font-semibold pb-1">
            {sponsorDetail?.title}
          </p>
          {sponsorDetail?.description.slice(0, count).map((e, i) => {
            return (
              <Row className="flex items-start mt-2">
                <Col span={1}>
                  <div className="bg-primary mt-2 rounded-full h-2 w-2"></div>
                </Col>
                <Col span={23}>
                  <p className="ml-2 font-medium">{e}</p>
                </Col>
              </Row>
            );
          })}
          {sponsorDetail?.description?.length > 2 && (
            <Row className="flex">
              <Col
                onClick={() => {
                  if (sponsorDetail?.description?.length === count) {
                    setCount(2);
                  } else {
                    setCount(sponsorDetail?.description.length);
                  }
                }}
                span={24}
              >
                <div className="font-bold	text-primary cursor-pointer text-right">
                  {sponsorDetail?.description?.length === count
                    ? "Show less"
                    : "Show more"}
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
      {isUser && isAdded === -1 && (
        <div
          className="bg-white rounded-bl-lg rounded-br-lg p-4 shadow-md shadow-gray-300 flex justify-center items-center"
          style={{ minHeight: 200 }}
        >
          <ThemeButton
            onClick={() => onAddAgenda(sponsorDetail)}
            content={
              <div>
                <PlusCircleOutlined className="mr-4" />
                <span>Add agenda</span>
              </div>
            }
            className="w-46 text-lg !h-12 mt-4"
          />
        </div>
      )}
    </div>
  );
};

export default SponsorslotCard;
