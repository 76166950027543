import { useEffect, useState, useCallback } from 'react'

import {
  useLazyGetEmptySlotsQuery,
  useLazyGetInvitationQuery,
  usePutInvitationMutation
} from '../../../services/invitation'
import { Collapse } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import AddSlotModal from '../../../components/addSlotModal'
import { useGetMeetingsQuery } from '../../../services/meetings'
import dayjs from 'dayjs'
import AddAttendeeModal from '../../../components/addAttendeeModal/addAttendeeModal'
import { Card } from 'antd'

const { Panel } = Collapse

const SponsorAdditionalMeetings = ({ eventID, attendance }) => {
  const [sponsors, setSponsors] = useState({})
  const [timeWiseMeetings, setTimeWiseMeetings] = useState({})
  const [showAddSlotModal, setShowAddSlotModal] = useState(false)
  const [inviteId, setInviteId] = useState(null)
  const [meetingId, setMeetingId] = useState(null)
  const [extraMeetings, setExtraMeetings] = useState([])
  const [showExtraMeetingModal, setShowExtraMeetingModal] = useState(false)
  const [isLoadingNeed, setIsLoading] = useState(false)

  const [getInvitations, { data: allInvitations, isLoading }] =
    useLazyGetInvitationQuery()

  const { data: meetings } = useGetMeetingsQuery({ eventID })

  const [
    putInvitation,
    { isLoading: updatingInvitation, error: updateInvitationError }
  ] = usePutInvitationMutation()

  const [getSlots, { data, isLoading: slotsLoading }] =
    useLazyGetEmptySlotsQuery()

  useEffect(() => {
    getAllInvitations()
  }, [isLoading, isLoadingNeed])

  const getAllInvitations = async () => {
    const getInv = await getInvitations({
      eventId: eventID,
      status: 'accepted'
    })

    if (allInvitations) {
      const acceptedInvitations = allInvitations?.invitations?.data
      let sponsors = {}
      let extraMeetings = []

      if (acceptedInvitations?.length) {
        acceptedInvitations.map(data => {
          if (!data.meeting) {
            extraMeetings.push(data)
          }

          let sponsor =
            data?.sender?.user_type === 'sponsor' ? data?.sender : data?.sender
          if (sponsors[sponsor._id]) {
            sponsors[sponsor._id]?.meetings?.push(data)
          } else {
            if (sponsor?.user_type === 'sponsor') {
              sponsors[sponsor._id] = {
                info: sponsor,
                meetings: [data]
              }
            }
          }
          console.log(
            'user type-->',
            sponsor?.user_type,
            sponsor?.first_name + ' ' + sponsor?.last_name
          )
        })

        setSponsors({ ...sponsors })
        setExtraMeetings([...extraMeetings])
      }
    }
  }

  const handleOnClickSlot = async slot => {
    const inviteData = {
      status: 'accepted',
      meetingId: slot._id
    }
    const invite = await putInvitation({ id: inviteId, body: inviteData })
    if (!invite.error) {
      setShowAddSlotModal(false)
      setIsLoading(!isLoadingNeed)
      getAllInvitations()
      setInviteId(null)
    }
  }

  const handleOnClickExtraMeeting = async extMtg => {
    const inviteData = {
      status: 'accepted',
      meetingId: meetingId,
      receiver: extMtg.receiver?._id,
      sender: extMtg.sender?._id,
      extraMeeting: true
    }
    console.log({ id: extMtg._id, body: inviteData })
    const invite = await putInvitation({ id: inviteId, body: inviteData })
    if (!invite.error) {
      setShowExtraMeetingModal(false)
      setMeetingId(null)
      setInviteId(null)
      getAllInvitations()
      // window.location.reload(false)
    }
  }

  console.log('sponsors-->', sponsors)
  return (
    <div>
      <AddSlotModal
        slots={data?.emptySlots}
        isModalOpen={showAddSlotModal}
        onClickSlot={slot => handleOnClickSlot(slot)}
        onClose={() => setExtraMeetings(false)}
      />

      <AddAttendeeModal
        extraMeetings={extraMeetings}
        isModalOpen={showExtraMeetingModal}
        onClickSlot={mt => handleOnClickExtraMeeting(mt)}
        onClose={() => setShowExtraMeetingModal(false)}
      />

      <div className='flex flex-wrap'>
        {Object.keys(sponsors)?.map((key, spoInd) => {
          return (
            <Card
              className='m-4'
              title={
                sponsors[key].info?.first_name +
                ' ' +
                sponsors[key].info?.last_name
              }
              bordered={false}
              style={{
                width: 520
              }}
            >
              <div className='flex justify-between mb-4'>
                <span className='w-1/12 font-normal text-left'></span>
                <span className='w-4/12 font-semibold text-left'>Sender</span>

                <span className='w-4/12 font-semibold text-center'>
                  Receiver
                </span>

                {/* <span className='font-semibold text-right w-2/12'>
                  Priority
                </span> */}
                <span className='font-semibold text-right w-3/12'>
                  T.Number
                </span>
                <span className='w-1/12 font-normal text-center'></span>
                <span className='w-1/12 font-normal text-center'></span>
              </div>
              {sponsors[key].meetings?.map((slot, index) => {
                const receiver = slot.receiver
                const sender = slot.sender
                return !slot.meeting ? (
                  <div
                    key={index}
                    className={`bg-white flex border-b min-h-9 justify-between my-1 py-1 px-1`}
                  >
                    <span className='w-1/12 font-normal text-left'>
                      {index + 1}
                    </span>
                    <span className='w-3/12 font-normal text-left'>
                      {sender.first_name + ' ' + sender.last_name}
                    </span>
                    <span className='w-4/12 font-normal text-center'>
                      {receiver.first_name + ' ' + receiver.last_name}
                    </span>
                    <span className='font-normal text-center  w-2/12'>
                      {receiver?.metadata?.atendee_details?.table_number ||
                        sender?.metadata?.atendee_details?.table_number}
                    </span>
                    <span className='w-1/12 font-normal text-center'>
                      {/* <EditOutlined
                            onClick={() =>
                              handleOnClickEdit(
                                receiver?._id,
                                sender?._id,
                                slot?._id
                              )
                            }
                          /> */}
                    </span>
                    <span className='w-1/12 font-normal text-center'>
                      {/* <DeleteOutlined
                            onClick={() =>
                              showDeletePopup(() =>
                                handleOnClickDelete(slot?._id)
                              )
                            }
                          /> */}
                    </span>
                  </div>
                ) : null
              })}
            </Card>
            // <tr key={spoInd} className='p-3 '>
            //   <th className='p-2 block w-[181px]'>
            //     <h2 style={{ width: 150 }}>
            //       {`${
            //         sponsors[key].info?.first_name +
            //         ' ' +
            //         sponsors[key].info?.last_name
            //       } ${
            //         sponsors[key]?.meetings[0]?.attendee[0]?.allowed_meetings
            //           ? `(${sponsors[key]?.meetings[0]?.attendee[0]?.allowed_meetings})`
            //           : ''
            //       }`}
            //     </h2>
            //   </th>
            // </tr>
          )
        })}
      </div>
    </div>
  )
}

export default SponsorAdditionalMeetings
