import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "antd";
import { user } from "../../assets/images/images";
import StarRatings from "react-star-ratings";

const EventFeedbackModal = ({ open, onClose, data }) => {
  console.log("data", data);
  return (
    <Modal footer={false} open={open} onCancel={onClose} width={700}>
      <div className="mb-4 mt-3">
        <div className="flex items-center">
          <img
            src={data?.user?.picture || user}
            alt=""
            width="80"
            height="80"
            className="w-[80px] h-[80px] rounded-full object-cover"
          />
          <div className="flex-col mx-2">
            <p className="font-bold text-base">
              {data?.user?.first_name} {data?.user?.last_name}
            </p>
            <p className="font-bold text-base text-primary">
              {data?.user?.email}
            </p>
          </div>
        </div>
        <div className="my-2">
          <Row>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Overall Satisfaction:
              </p>
              <StarRatings
                rating={data?.ratings?.overAll}
                name="overAll"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Quality of the Presentations:
              </p>
              <StarRatings
                rating={data?.ratings?.presentation}
                name="presentation"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Relevance of the Topics Discussed:
              </p>
              <StarRatings
                rating={data?.ratings?.relevance}
                name="relevance"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Quality of the Speakers:
              </p>
              <StarRatings
                rating={data?.ratings?.speakerQuality}
                name="speakerQuality"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Quality of the Audience:
              </p>
              <StarRatings
                rating={data?.ratings?.audienceQuality}
                name="audienceQuality"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Quality of the Venue:
              </p>
              <StarRatings
                rating={data?.ratings?.venueQuality}
                name="venueQuality"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Quality of the food served/ Dietary requirements met:
              </p>
              <StarRatings
                rating={data?.ratings?.foodQuality}
                name="foodQuality"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Networking Opportunities:
              </p>
              <StarRatings
                rating={data?.ratings?.networking}
                name="foodQuality"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24} className="flex mb-2 justify-between">
              <p className="text-primary font-semibold text-base mr-4">
                Standard of the Meetings:
              </p>
              <StarRatings
                rating={data?.ratings?.meetingStatndar}
                name="foodQuality"
                starDimension="20px"
                starRatedColor="#E2B024"
              />
            </Col>
            <Col lg={24} xs={24}>
              <p className="text-primary font-semibold text-base">
                Intrested In:
              </p>
              <p className="font-semibold text-sm">
                {data?.instrestedIn?.length
                  ? data?.instrestedIn?.map((intrested, index) => {
                      return (
                        <span key={index}>
                          {intrested}
                          {data?.instrestedIn.length - 1 !== index && ", "}
                        </span>
                      );
                    })
                  : "---"}
              </p>
            </Col>
            <Col lg={24} xs={24} className="mt-3">
              <p className="text-primary font-semibold text-base">
                Other Intrested:
              </p>
              <p className="font-semibold text-sm">
                {data?.otherIntrested || "---"}
              </p>
            </Col>
            <Col lg={24} xs={24} className="mt-3">
              <p className="text-primary font-semibold text-base">
                Would you attend the Clinical Trials Strategic Summit again?
              </p>
              <p className="font-semibold text-sm">{data?.attennd || "---"}</p>
            </Col>
            <Col lg={24} xs={24} className="mt-3">
              <p className="text-primary font-semibold text-base">
                Would you recommend the Clinical Trials Strategic Summit to
                colleagues and peers?
              </p>
              <p className="font-semibold text-sm">
                {data?.recommend || "---"}
              </p>
            </Col>
            <Col lg={24} xs={24} className="mt-3">
              <p className="text-primary font-semibold text-base">
                Additional Information:
              </p>
              <p className="font-semibold text-sm">
                {data?.additionalComments || "---"}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default EventFeedbackModal;
