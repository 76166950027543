import { useState, useEffect } from "react";
import { add_icon, delegates_white_icon } from "../../assets/icons/icons";
import { message } from "antd";
import {
  Container,
  ThemeTable,
  ThemeButton,
  AnnouncementDrawer,
} from "../../components/components";
import {
  useGetAnnouncementsQuery,
  useAddAnnouncementMutation,
} from "../../services/announcements";
import { NotificationOutlined } from "@ant-design/icons";

const Announcements = () => {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [event, setEvent] = useState("");
  const { data, error, isLoading, refetch } = useGetAnnouncementsQuery({
    currentPage,
    resultPerPage,
    event_id: event,
  });
  const [addAnnouncement, { isLoading: submitting }] =
    useAddAnnouncementMutation();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setTotal(data?.announcements?.total || 0);
  }, [isLoading]);

  const onClose = () => {
    setOpen(false);
    setFormData({});
  };

  const onSubmit = async () => {
    const response = await addAnnouncement(formData);
    if (response?.data?.status == 200) {
      message.success("Announcement added successfully.");
      onClose();
      refetch();
    }
    message.error(response?.error?.data?.message || "Something went wrong");
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },

    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      render: (_, data) => (
        <p className="text-center font-medium">{data?.event?.name}</p>
      ),
    },
    {
      title: "Ttile",
      dataIndex: "title",
      key: "title",
      render: (title) => <p className="text-center font-medium">{title}</p>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => (
        <p className="text-center font-medium">{description}</p>
      ),
    },
  ];

  return (
    <Container
      selected={"5"}
      header={
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="bg-primary h-9 w-9 rounded-full flex justify-center items-center">
              <NotificationOutlined className="text-white" />
            </div>
            <p className="ml-2 font-semibold text-2xl">Announcements</p>
          </div>
          <div className="flex items-center">
            <ThemeButton
              className={"ml-3"}
              content={
                <div className="flex items-center">
                  <img src={add_icon} alt="" width={18} height={18} />
                  <p className="ml-2">Add Announcements</p>
                </div>
              }
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
      }
    >
      <AnnouncementDrawer
        open={open}
        onClose={onClose}
        btnTxt={"Add Announcement"}
        addIcon={add_icon}
        onSubmit={onSubmit}
        isLoading={submitting}
        formData={formData}
        setFormData={(e) => setFormData(e)}
      />
      <ThemeTable
        pagination={{ total, setCurrentPage, setResultPerPage }}
        columns={columns}
        data={data?.announcements?.data || []}
      />
    </Container>
  );
};

export default Announcements;
